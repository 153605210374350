import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { DEFAULT_TABLE_PER_PAGE } from '@/config.ts';

interface PaginationState {
  page: number;
  limit: number;
  total: number;
}

export const usePermissionsPagination = defineStore('permissionsPagePagination', () => {
  const state = ref<PaginationState>({
    limit: DEFAULT_TABLE_PER_PAGE,
    total: 0,
    page: 1,
  });

  const setState = ({ page, limit, total }: { page?: number; limit?: number; total?: number }) => {
    state.value = {
      page: page || state.value.page,
      limit: limit || state.value.limit,
      total: total >= 0 ? total : state.value.total,
    };
  };

  const changePage = (page: number) => {
    state.value = {
      ...state.value,
      page,
    };
  };

  const changeLimit = (limit: number) => {
    state.value = {
      ...state.value,
      limit,
      page: 1,
    };
  };

  return {
    state,
    setState,

    changePage,
    changeLimit,
  };
});
