<template>
  <h4
    v-if="props.title"
    class="mb-2 input-meta-text-sm"
  >
    {{ props.title }}
  </h4>

  <div
    v-if="!text"
    class="text-center p-6"
  >
    Add title to get suggestions
  </div>

  <div
    v-else
    class="flex justify-center items-center min-h-[56px]"
  >
    <div v-if="props.isLoading">
      <Spinner variant="ring" />
      <span class="sr-only">
        {{ t('ai-cover-search.search-wait') }}
      </span>
    </div>
    <div
      v-else
      class="relative grid grid-cols-4 gap-0.5"
    >
      <Tooltip
        v-for="({ image: imageUrl }, index) in props.images"
        :key="`${id}-${index}`"
        width="unset"
        name="slug-info"
        placement="auto"
        :tooltip-visible="props.disabled"
        button-class="w-full text-left"
      >
        <template #content>
          <button
            type="button"
            class="rounded"
            :disabled="imageLoadingState === ImageLoadingState.LOADING || props.disabled"
            @mouseenter="props.disabled ? null : (currentImage = `${id}-${index}`)"
            @mouseleave="currentImage = null"
            @click="uploadImageAction({ url: imageUrl })"
          >
            <transition name="fade">
              <div
                v-if="currentImage === `${id}-${index}`"
                class="preview-container"
                :style="{ backgroundImage: `url('${imageUrl}')` }"
              >
                <span class="relative mb-2 text-white text-center text-xs w-full">
                  {{ t('ai-cover-search.click-to-select') }}
                </span>
              </div>
            </transition>
            <img
              :alt="props.text || `${id}-${index}`"
              :src="imageUrl"
              class="w-full h-auto"
            />
          </button>
        </template>

        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { uniqueId } from 'lodash';
import Spinner from '@/components/Spinner.vue';
import { useSimpleAction, useToast } from '@/composables';
import { ImageLoadingState } from '@/features/UploadImage/types';
import { UploadImageService } from '@/features/UploadImage/service/uploadImage';
import Tooltip from '@/components/Tooltip.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const id = uniqueId();

const currentImage = ref<string | null>(null);

const props = withDefaults(
  defineProps<{
    isLoading?: boolean;
    title?: string;
    images: any[];
    disabled?: boolean;
    text?: string;
  }>(),
  {
    isLoading: false,
    disabled: false,
    title: 'AI Suggestions',
    text: '',
  },
);

const emits = defineEmits<{
  (event: 'select-image', id: number): void;
}>();

const toast = useToast();

const imageLoadingState = ref<ImageLoadingState>(ImageLoadingState.CHOOSE);

const { action: uploadImageAction } = useSimpleAction(async ({ url, file }: { url?: string; file?: File }) => {
  try {
    imageLoadingState.value = ImageLoadingState.LOADING;
    const postData: { url?: string; file?: File } = {};
    if (url) postData.url = url;
    if (file) postData.file = file;

    const result = await UploadImageService.imageGalleryUpload(postData);

    emits('select-image', result.id);

    imageLoadingState.value = ImageLoadingState.SET_ATTRIBUTES;
  } catch (err: any) {
    toast.errorTemporary({
      id: 'ERROR_MEDIA_URL',
      message: err.data?.errorMessage || 'Something went wrong. Please try later',
    });
    imageLoadingState.value = ImageLoadingState.CHOOSE;
    throw err;
  }
});
</script>

<style lang="scss" scoped>
.preview-container {
  @apply flex items-end absolute -left-[328px] top-1/2;
  @apply flex items-end absolute -left-[328px] top-1/2 transform -translate-y-1/2 bg-cover bg-center w-[320px] h-[213px] z-10 rounded;

  /* Creates clickable area between preview image and sidebar, on the right side */
  &:after {
    @apply content-[''] absolute -right-2 w-2 h-full;
  }

  &:before {
    @apply content-[''] absolute w-full bottom-0 h-[150px];
    @apply bg-gradient-to-b from-transparent via-transparent to-black;
  }
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity ease-in-out duration-300;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
