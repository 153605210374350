<script setup lang="ts">
import EditorsChoiceDraggableSection from '@/features/EditorsChoice/components/EditorsChoiceDraggableSection.vue';
import { BUTTON_COLOR, BUTTON_TYPE, SIZES } from '@/types';
import Button from '@/components/Button.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import { computed, onBeforeUnmount, onMounted } from 'vue';
import { MAXIMUM_EDITORS_CHOICE_POSTS } from '@/features/EditorsChoice/constants';
import { useEditorsChoiceStore } from '@/features/EditorsChoice/stores/editorsChoice.store.ts';
import { useUserPermissions } from '@/stores/user.store.ts';
import { useRouter } from 'vue-router';

const editorsChoiceStore = useEditorsChoiceStore();
const router = useRouter();

const { editorsChoiceListView, editorsChoiceListEdit } = useUserPermissions();

const updateDisabled = computed(() => editorsChoiceStore.state.posts.length < 5);

onMounted(() => {
  editorsChoiceStore.fetchEditorsChoice();
});
onBeforeUnmount(() => {
  editorsChoiceStore.clearState();
});
</script>

<template>
  <div class="bg-white border rounded-xl p-4 gap-4 flex flex-col h-fit">
    <div class="flex justify-between">
      <h2 class="text-lg font-semibold">Editor’s Top Selections 🎯</h2>
      <div class="flex gap-2">
        <Button
          :color="BUTTON_COLOR.DEFAULT"
          :size="SIZES.MEDIUM"
          data-testid="cancel-editors-choice"
          :visual-type="BUTTON_TYPE.TERTIARY"
          @click="editorsChoiceStore.fetchEditorsChoice()"
        >
          Cancel
        </Button>
        <Button
          v-if="editorsChoiceListView"
          :size="SIZES.MEDIUM"
          data-testid="cancel-editors-choice"
          :is-disabled="updateDisabled || !editorsChoiceListEdit"
          @click="editorsChoiceStore.updateEditorsChoice()"
        >
          Update
        </Button>
      </div>
    </div>
    <ProgressBar
      v-if="editorsChoiceStore.state.posts"
      :max="MAXIMUM_EDITORS_CHOICE_POSTS"
      :value="editorsChoiceStore.state.posts.length"
      label="Articles selected"
    />
    <EditorsChoiceDraggableSection
      v-if="editorsChoiceStore.state.posts"
      :posts="editorsChoiceStore.state.posts"
      :updated-at="editorsChoiceStore.state.updatedAt"
    />
  </div>
</template>
