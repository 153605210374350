<script setup lang="ts">
import { VueDraggable } from 'vue-draggable-plus';
import DraggingIcon from '@/assets/icons/dragging-dots.svg?component';
import { BUTTON_COLOR, BUTTON_TYPE, SIZES } from '@/types';
import Button from '@/components/Button.vue';
import PostCard from '@/features/EditorsChoice/components/PostCard.vue';
import TrashIcon from '@/assets/icons/trash.svg?component';
import type { EditorsChoicePost } from '@/features/EditorsChoice/types.ts';
import { ref, watchEffect } from 'vue';
import { getFormattedDate } from '@/utils';
import { useEditorsChoiceStore } from '@/features/EditorsChoice/stores/editorsChoice.store.ts';
import type { Post } from '@/features/Posts/types';

const props = defineProps<{
  posts: EditorsChoicePost[] | Post[];
  updatedAt: string;
}>();

const editorsChoiceStore = useEditorsChoiceStore();

const articles = ref<EditorsChoicePost[] | Post[]>([]);

const updateECOrder = () => {
  editorsChoiceStore.updateECPostsOrder(articles);
};
watchEffect(() => {
  if (props.posts) {
    articles.value = props.posts;
  }
});
</script>

<template>
  <div class="flex flex-col gap-4">
    <VueDraggable
      v-model="articles"
      :animation="150"
      handle=".handle"
      class="flex flex-col gap-4"
      @update="updateECOrder"
    >
      <PostCard
        v-for="post in articles"
        :post="post"
        :key="post.id"
      >
        <template #iconLeft>
          <DraggingIcon class="handle cursor-move min-h-5 min-w-5 h-5 w-5" />
        </template>
        <template #iconRight>
          <Button
            :color="BUTTON_COLOR.DEFAULT"
            :size="SIZES.MEDIUM"
            data-testid="restore-modal-close-button"
            :visual-type="BUTTON_TYPE.TERTIARY"
            class="w-7 h-6 p-0 ml-auto"
            @click="editorsChoiceStore.removePostFromEditorsChoice(post.id)"
          >
            <TrashIcon class="h-4 w-4 text-imperium-ds-red-strong" />
          </Button>
        </template>
      </PostCard>
    </VueDraggable>
    <p
      v-if="props.updatedAt"
      class="ml-auto text-imperium-fg-muted text-xs"
    >
      Last updated on {{ getFormattedDate(props.updatedAt) }}
    </p>
  </div>
</template>
