<script setup lang="ts">
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import { computed } from 'vue';

const articleStore = useArticleStore();
const approvedBy = computed(() =>
  articleStore.state.approvedBy && articleStore.state.approvedBy.name
    ? articleStore.state.approvedBy.name
    : articleStore.state.approvedBy,
);
const coverSetBy = computed(() =>
  articleStore.state.coverSetBy && articleStore.state.coverSetBy.name
    ? articleStore.state.coverSetBy.name
    : articleStore.state.coverSetBy,
);
</script>

<template>
  <div class="flex flex-col gap-2 mb-4">
    <div class="flex gap-2">
      <article class="flex flex-col gap-1 border input-rounded-default w-1/2 py-2 px-2">
        <h3 class="text-imperium-fg-base text-xs font-semibold">Words</h3>

        <span class="text-imperium-fg-strong">{{ articleStore.state.words }}</span>
      </article>

      <article class="flex flex-col gap-1 border input-rounded-default w-1/2 py-2 px-2">
        <h3 class="text-imperium-fg-base text-xs font-semibold">Characters</h3>

        <span class="text-imperium-fg-strong">{{ articleStore.state.characters }}</span>
      </article>
    </div>

    <div class="flex gap-2">
      <article class="flex flex-col gap-1 border input-rounded-default w-full py-2 px-2">
        <h3 class="text-imperium-fg-base text-xs font-semibold">Approved by</h3>

        <span class="text-imperium-fg-strong">{{ approvedBy }}</span>
      </article>

      <article class="flex flex-col gap-1 border input-rounded-default w-full py-2 px-2">
        <h3 class="text-imperium-fg-base text-xs font-semibold">Cover set by</h3>

        <span class="text-imperium-fg-strong">{{ coverSetBy }}</span>
      </article>
    </div>
  </div>
</template>
