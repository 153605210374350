<template>
  <div
    v-if="modelValue"
    class="inline-block max-w-[240px]"
  >
    <div class="flex justify-between items-center mb-2">
      <p class="font-semibold text-sm">
        {{ title }}
      </p>
      <Button
        :visual-type="BUTTON_TYPE.GHOST"
        :color="BUTTON_COLOR.RED"
        @click="
          emits('update:modelValue', '');
          emits('delete');
        "
      >
        Delete image
      </Button>
    </div>

    <img
      :src="avatarSrc"
      :alt="title"
    />
  </div>
  <Button
    v-else
    :size="SIZES.MEDIUM"
    :visual-type="BUTTON_TYPE.TERTIARY"
    :is-disabled="props.isLoading || props.isDisabled"
    @click="
      emits('click');
      reveal();
    "
  >
    <template #leftIcon>
      <PlusIcon class="w-4 h-4 text-[#101010]" />
    </template>

    {{ title }}
  </Button>
  <UploadImageModal
    :is-visible="isRevealed"
    is-attributes-hidden
    skip-upload
    @close="cancel"
    @update-post-data="onImageUpdate"
  />
</template>

<script setup lang="ts">
import { computed, watch, onUnmounted } from 'vue';
import { SIZES, BUTTON_TYPE, BUTTON_COLOR } from '@/types';
import Button from '@/components/Button.vue';
import PlusIcon from '@/assets/icons/plus.svg?component';
import UploadImageModal from '@/features/UploadImage/modals/UploadImageModal.vue';
import { type PostData } from '@/features/UploadImage/types';
import { useModal } from '@/composables/useModal';

const { reveal, cancel, isRevealed } = useModal();

const emits = defineEmits<{
  (event: 'click'): void;
  (event: 'delete'): void;
  (event: 'update:modelValue', value: string | File): void;
}>();

const props = withDefaults(
  defineProps<{
    modelValue: string | File;
    title: string;
    isLoading?: boolean;
    isDisabled?: boolean;
  }>(),
  {
    isLoading: false,
  },
);

const onImageUpdate = (newValue: PostData) => {
  emits('update:modelValue', newValue.url || newValue.file || '');
  cancel();
};

const avatarSrc = computed(() => {
  if (typeof props.modelValue === 'string') {
    return props.modelValue;
  } else if (props.modelValue instanceof File) {
    return URL.createObjectURL(props.modelValue);
  }

  return '';
});

watch(
  () => props.modelValue,
  (_newAvatar, oldAvatar) => {
    if (oldAvatar instanceof File) {
      URL.revokeObjectURL(URL.createObjectURL(oldAvatar));
    }
  },
);

onUnmounted(() => {
  if (props.modelValue instanceof File) {
    URL.revokeObjectURL(URL.createObjectURL(props.modelValue));
  }
});
</script>
