import { toTypedSchema } from '@vee-validate/zod';
import zod from 'zod';
import {
  AUTHOR_FIELD_NAME,
  AUTHOR_FIELD_ID,
  AUTHOR_FIELD_DESCRIPTION,
  AUTHOR_FIELD_SLUG,
  AUTHOR_FIELD_AVATAR,
  AUTHOR_FIELD_TWITTER,
  AUTHOR_FIELD_FACEBOOK,
  AUTHOR_FIELD_EMAIL,
  AUTHOR_FIELD_LINKEDIN,
  AUTHOR_FIELD_SEO_TITLE,
  AUTHOR_FIELD_SEO_DESCRIPTION,
  AUTHOR_FIELD_ENABLED,
  AUTHOR_FIELD_LANGUAGE_ID,
} from '@/features/Authors/constants';

const authorFormSchema = zod.object({
  [AUTHOR_FIELD_ID]: zod.number().nullable().optional(),
  [AUTHOR_FIELD_NAME]: zod.string().min(1, 'This is a mandatory field.'),
  [AUTHOR_FIELD_SLUG]: zod.string().min(1, 'This is a mandatory field.'),
  [AUTHOR_FIELD_AVATAR]: zod.union([zod.string().nullable().optional(), zod.instanceof(File).optional()]),
  [AUTHOR_FIELD_DESCRIPTION]: zod.string().optional(),
  [AUTHOR_FIELD_TWITTER]: zod.string().optional(),
  [AUTHOR_FIELD_FACEBOOK]: zod.string().optional(),
  [AUTHOR_FIELD_EMAIL]: zod
    .string()
    .optional()
    .refine(
      (value) => !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), // Validate only if value is not empty
      'Invalid email format',
    ),
  [AUTHOR_FIELD_LINKEDIN]: zod.string().optional(),
  [AUTHOR_FIELD_SEO_TITLE]: zod.string().optional(),
  [AUTHOR_FIELD_SEO_DESCRIPTION]: zod.string().optional(),
  [AUTHOR_FIELD_ENABLED]: zod.boolean().optional(),
  [AUTHOR_FIELD_LANGUAGE_ID]: zod.number(),
});

export const authorsFormSchema: unknown = toTypedSchema(authorFormSchema);
