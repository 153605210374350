import { defineStore } from 'pinia';
import { ref } from 'vue';

interface ErrorStore {
  errorCode: number | null;
  errorMessage: string | null;
}
export const useErrorStore = defineStore('error', () => {
  const state = ref<ErrorStore>({
    errorCode: null,
    errorMessage: null,
  });

  const setError = (code: number, message: string) => {
    state.value.errorCode = code;
    state.value.errorMessage = message;
  };

  const clearError = () => {
    state.value.errorCode = null;
    state.value.errorMessage = '';
  };

  return {
    state,
    setError,
    clearError,
  };
});
