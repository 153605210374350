import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { authors } from '@/features/Authors/api';
import type { ComputedRef } from 'vue';
import type { AuthorsFetchRequest, AuthorsFetchResponse } from '@/features/Authors/types';

import { AUTHORS_RESOURCE } from '@/features/Authors/constants';

export const useFetchAuthors = (
  params: ComputedRef<AuthorsFetchRequest>,
  onSuccess: ((result: AuthorsFetchResponse) => void) | null = null,
) => {
  const { data, isLoading, isFetched, refetch, isFetching } = useQuery({
    queryKey: [AUTHORS_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: AuthorsFetchRequest = queryKey[1] as AuthorsFetchRequest;

      const result = await authors(payload);

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    },

    staleTime: 0,
    gcTime: 0,
    refetchInterval: false,
    refetchOnMount: 'always',
    placeholderData: keepPreviousData,
  });

  return {
    data,
    isLoading,
    isFetched,
    refetch,
    isFetching,
  };
};
