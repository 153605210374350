<template>
  <div class="flex flex-col-reverse md:flex-row mb-1 w-full gap-3 flex-nowrap items-center justify-between">
    <Search
      class="md:max-w-[300px]"
      placeholder="Search for converter"
      is-suffix-shown
      :value="searchQuery"
      @update:modelValue="(value: string) => updateSearchQuery(value)"
    />
    <div class="flex max-h-10 flex-nowrap w-full md:w-fit">
      <ConvertersAddButton :is-full-width="isMobile" />
    </div>
  </div>
</template>
<script setup lang="ts">
import Search from '@/components/Table/Filters/Search.vue';
import { computed, inject } from 'vue';
import ConvertersAddButton from '@/features/Converter/components/ConverterTable/ConvertersAddButton.vue';
import { useConvertersFilters } from '@/features/Converter/stores/convertersFilter.store';

const filtersStore = useConvertersFilters();
const isMobile = inject('isMobile');

const searchQuery = computed(() => filtersStore.state?.searchQuery || '');

const updateSearchQuery = (searchQuery: string) => {
  filtersStore.setSearchQuery(searchQuery);
};
</script>
