import Sponsored from '@/assets/icons/editor/disclaimer/sponsored.svg?raw';
import Interview from '@/assets/icons/editor/disclaimer/interview.svg?raw';
import Contribution from '@/assets/icons/editor/disclaimer/contribution.svg?raw';
import Update from '@/assets/icons/editor/disclaimer/update.svg?raw';
import Developing from '@/assets/icons/editor/disclaimer/developing.svg?raw';
import Consulting from '@/assets/icons/editor/disclaimer/consulting.svg?raw';
import Views from '@/assets/icons/editor/disclaimer/views.svg?raw';
import Investment from '@/assets/icons/editor/disclaimer/investment.svg?raw';
import PressReleases from '@/assets/icons/editor/disclaimer/press-release.svg?raw';
import MarketReleases from '@/assets/icons/editor/disclaimer/market-release.svg?raw';

import type { NodeType } from 'prosemirror-model';
import type { BlocksResult, InstallableBlock } from '@/features/CollaborativeEditor/types';

import { wrapItem } from '@/features/CollaborativeEditor/plugins/menu';
import { checkIfNodeIn, disableMenuItem } from '@/features/CollaborativeEditor/utils/state';
import { useToast } from '@/composables';

const getIconFrom = (rawSvg: string): Element => {
  return rawSvg;
};

const FORMS = [
  {
    type: 'sponsored',
    key: 'Sponsored',
    label: 'Sponsored',
    description:
      'Disclaimer. Cointelegraph does not endorse any content or product on this page. While we aim at providing you with all important information that we could obtain in this sponsored article, readers should do their own research before taking any actions related to the company and carry full responsibility for their decisions, nor can this article be considered as investment advice.',
    icon: getIconFrom(Sponsored),
  },
  {
    type: 'interview',
    key: 'Interview',
    label: 'Interview',
    description: 'This interview has been edited and condensed.',
    icon: getIconFrom(Interview),
  },
  {
    type: 'contribution',
    key: 'Contribution',
    label: 'Contribution',
    description: 'Additional reporting by [Name].',
    icon: getIconFrom(Contribution),
  },
  {
    type: 'update',
    key: 'Update',
    label: 'Update',
    description:
      'Update (date and time in UTC): This article has been updated to [insert the new info being presented.]',
    icon: getIconFrom(Update),
  },
  {
    type: 'developing',
    key: 'Developing',
    label: 'Developing',
    description: 'This is a developing story, and further information will be added as it becomes available.',
    icon: getIconFrom(Developing),
  },
  {
    type: 'consulting',
    key: 'Consulting',
    label: 'Consulting',
    description:
      'The opinions expressed in this article are for general informational purposes only and are not intended to provide specific advice or recommendations for any individual or on any specific security or investment product.',
    icon: getIconFrom(Consulting),
  },
  {
    type: 'vc_consulting',
    key: 'VCConsulting',
    label: 'VC Consulting',
    description:
      'This article pulls from Cointelegraph Research Terminal’s expansive Venture Capital Database. This article is for information purposes only and represents neither investment advice, investment analysis, nor an invitation to buy or sell financial instruments. Specifically, it is not a substitute for individual investment or other advice.',
    icon: getIconFrom(Consulting),
  },
  {
    type: 'views',
    key: 'Views',
    label: 'Views, thoughts, opinions',
    description:
      'This article is for general information purposes and is not intended to be and should not be taken as legal or investment advice. The views, thoughts, and opinions expressed here are the author’s alone and do not necessarily reflect or represent the views and opinions of Cointelegraph.',
    icon: getIconFrom(Views),
  },
  {
    type: 'investment',
    key: 'Investment',
    label: 'Investment advice',
    description:
      'This article does not contain investment advice or recommendations. Every investment and trading move involves risk, and readers should conduct their own research when making a decision.',
    icon: getIconFrom(Investment),
  },
  {
    type: 'press_releases',
    key: 'PressReleases',
    label: 'Press Releases',
    description:
      'Disclaimer. This publication is provided by the client. Cointelegraph does not endorse and is not responsible for or liable for any content, accuracy, quality, advertising, products, or other materials on this page. Readers should do their own research before taking any actions related to the company. Cointelegraph is not responsible, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, goods, or services mentioned in the press release.',
    icon: getIconFrom(PressReleases),
  },
  {
    type: 'market_releases',
    key: 'MarketReleases',
    label: 'Market Releases',
    description:
      'Disclaimer. This publication is sponsored. Cointelegraph does not endorse or assume responsibility for the content, accuracy, quality, advertising, products, or other materials on this web page. Readers are advised to conduct their own research before engaging with any company mentioned. Please note that the featured information is not intended as, and shall not be understood or construed as legal, tax, investment, financial, or other advice. Nothing contained on this web page constitutes a solicitation, recommendation, endorsement, or offer by Cointelegraph or any third party service provider to buy or sell any cryptoassets or other financial instruments. Crypto assets are a high-risk investment. You should consider whether you understand the possibility of losing money due to leverage. None of the material should be considered as investment advice. Cointelegraph shall not be held liable, directly or indirectly, for any damages or losses arising from the use or reliance on any content, goods, or services featured on this web page.',
    icon: getIconFrom(MarketReleases),
  },
];

const render = (icon: Element, label: string, description: string): HTMLElement => {
  const template = `<div>
    <div class="disclaimer-form-item">
      <div class="disclaimer-form-item__icon">
        ${icon}
      </div>

      <div>
        <div>
          ${label}
        </div>

        <div class="disclaimer-description">${description}</div>
      </div>
    </div>
  </div>`;

  return new DOMParser().parseFromString(template, 'text/html').body.firstElementChild as HTMLElement;
};

const toast = useToast();
export const buildDisclaimer = (node: NodeType): InstallableBlock[] => {
  return FORMS.map((formData) => ({
    key: `disclaimer${formData.key}` as keyof BlocksResult,
    item: wrapItem(node, {
      run(state, _, view) {
        const { dispatch } = view;
        const { schema, selection } = state;
        const { from } = selection;

        const disclaimerNode = schema.nodes.disclaimer.create(null, schema.text(formData.description));

        const transaction = state.tr.insert(from, disclaimerNode);

        // Prevent from pasting disclaimer into img figcaption
        if (!disableMenuItem(state.selection.$head!.path, state.selection?.node?.type?.name)) {
          toast.errorTemporary({
            id: 'ERROR_DUPLICATED_WIDGET',
            message: '"Disclaimer" cannot be inserted in this spot. Move your cursor to an empty space.',
          });
          return;
        }
        dispatch(transaction);
      },
      render: () => {
        return render(formData.icon, formData.label, formData.description);
      },
    }),
  }));
};
