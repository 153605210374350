import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import type { FiltersState, FiltersSort } from '@/features/Authors/types';
import { SortStatus } from '@/types';
import { useAuthorsPagination } from '@/features/Authors/stores/pagination.store';

export type State = FiltersState;

const DEFAULT_SORT: FiltersSort = { id: SortStatus.DESC };

export const useAuthorsFilters = defineStore(
  'useAuthorsFilters',
  () => {
    const router = useRouter();
    const route = useRoute();

    const state = ref<State>({
      sort: DEFAULT_SORT,
      searchQuery: '',
    });

    const parseQuery = (query: Record<string, string>) => {
      const { sort, searchQuery } = query;

      const parsedSort = sort ? JSON.parse(sort) : DEFAULT_SORT;

      return {
        sort: {
          id: parsedSort.id ?? DEFAULT_SORT.id,
          name: parsedSort.name ?? DEFAULT_SORT.name,
        },
        searchQuery: searchQuery || '',
      };
    };

    const initFromQuery = () => {
      const query = route.query as Record<string, string>;
      const { sort, searchQuery } = parseQuery(query);

      state.value = {
        sort,
        searchQuery,
      };
    };

    const updateQuery = () => {
      const currentQuery = { ...route.query };

      const isSortDefault = state.value.sort.id === SortStatus.DISABLE && state.value.sort.name === SortStatus.DISABLE;

      if (!isSortDefault) {
        currentQuery.sort = JSON.stringify(state.value.sort);
      } else {
        delete currentQuery.sort;
      }

      if (state.value.searchQuery) {
        currentQuery.searchQuery = state.value.searchQuery;
      } else {
        delete currentQuery.searchQuery;
      }

      router.push({ query: currentQuery });
    };

    const setSort = (field: 'id' | 'name', status: SortStatus) => {
      state.value.sort = {
        id: field === 'id' ? status : SortStatus.DISABLE,
        name: field === 'name' ? status : SortStatus.DISABLE,
      };

      updateQuery();
    };

    const setSearchQuery = (searchQuery: string) => {
      const paginationStore = useAuthorsPagination();

      state.value = { ...state.value, searchQuery };

      if (paginationStore.state.page !== 1) {
        paginationStore.setState({ page: 1 });
      }

      updateQuery();
    };

    initFromQuery();

    return {
      state,
      setSort,
      setSearchQuery,
      initFromQuery,
    };
  },
  {
    persist: true,
  },
);
