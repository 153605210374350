<template>
  <div class="flex flex-col">
    <div class="flex mb-4 mx-2 justify-end full-width">
      <div>
        <Button
          :size="SIZES.MEDIUM"
          data-testid="create-new-permission-modal-show"
          class="ml-auto"
          @click="onCreatePermissionButtonClick"
        >
          <template #leftIcon>
            <PlusIcon class="w-5 h-5" />
          </template>

          New permission
        </Button>
      </div>
    </div>

    <PermissionsTable
      :refetch="needToRefetch"
      @redirect="openPermissionModal"
      @update-refetch="(value) => (needToRefetch = value)"
    />

    <Teleport to="#modals">
      <CreateEditPermissionModal
        :id="permissionId"
        :is-visible="isRevealed"
        :slug="permissionSlug"
        :name="permissionName"
        @close="onModalClose"
        @confirm="onPermissionCreated"
        @reopen="onCreatePermissionButtonClick"
      />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { SIZES } from '@/types';
import Button from '@/components/Button.vue';
import PlusIcon from '@/assets/icons/plus.svg?component';
import PermissionsTable from '@/features/Permissions/components/PermissionsTable.vue';
import CreateEditPermissionModal from '@/features/Permissions/components/CreateEditPermissionModal.vue';
import { useModal } from '@/composables/useModal';

const needToRefetch = ref<boolean>(false);

const permissionSlug = ref<string>('');
const permissionId = ref<number>(0);
const permissionName = ref<string>('');

const { reveal, cancel, confirm, isRevealed } = useModal();

const onCreatePermissionButtonClick = () => reveal();
const onPermissionCreated = () => {
  confirm();
  needToRefetch.value = true;
  clearPermission();
};
const openPermissionModal = ({ slug, id, name }: { id: number; slug: string; name: string }) => {
  permissionSlug.value = slug;
  permissionId.value = id;
  permissionName.value = name;

  reveal();
};

const clearPermission = () => {
  permissionSlug.value = '';
  permissionId.value = 0;
  permissionName.value = '';
};
const onModalClose = () => {
  cancel();
  clearPermission();
};
</script>
