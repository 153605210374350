import type { PostsFetchRequest } from '@/features/Posts/types';
import { useQuery } from '@tanstack/vue-query';
import { fetchPosts } from '../api';
import { POSTS } from '../constants';

export const useFetchPosts = (params: PostsFetchRequest) => {
  const { data, isLoading } = useQuery({
    queryKey: [POSTS, params],
    queryFn: ({ queryKey }) => {
      const payload: PostsFetchRequest = queryKey[1] as PostsFetchRequest;
      if (!payload.languageId) return;

      return fetchPosts(payload);
    },

    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: 'always',
  });

  return {
    data,
    isLoading,
  };
};
