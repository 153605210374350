import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import { DEFAULT_TABLE_PER_PAGE } from '@/config.ts';

interface PaginationState {
  page: number;
  limit: number;
  total: number;
}

const DEFAULT_PAGE = 1;

export const useAuthorsPagination = defineStore('authorsPagePagination', () => {
  const router = useRouter();
  const route = useRoute();

  const state = ref<PaginationState>({
    page: DEFAULT_PAGE,
    limit: DEFAULT_TABLE_PER_PAGE,
    total: 0,
  });

  const initFromQuery = () => {
    const query = route.query;
    state.value = {
      page: query.page ? parseInt(query.page as string, 10) : DEFAULT_PAGE,
      limit: query.limit ? parseInt(query.limit as string, 10) : DEFAULT_TABLE_PER_PAGE,
      total: state.value.total, // Keep the total as is
    };
  };

  // Update the query string when the store changes
  const updateQuery = () => {
    const currentQuery = { ...route.query };

    // Update only the relevant query parameters
    if (state.value.page !== DEFAULT_PAGE) {
      currentQuery.page = state.value.page.toString();
    } else {
      delete currentQuery.page; // Remove page if it's the default value
    }

    if (state.value.limit !== DEFAULT_TABLE_PER_PAGE) {
      currentQuery.limit = state.value.limit.toString();
    } else {
      delete currentQuery.limit; // Remove limit if it's the default value
    }

    router.push({ query: currentQuery });
  };

  const setState = ({ page, limit, total = 0 }: { page?: number; limit?: number; total?: number }) => {
    state.value = {
      page: page || state.value.page,
      limit: limit || state.value.limit,
      total: total >= 0 ? total : state.value.total,
    };
    updateQuery();
  };

  const changePage = (page: number) => {
    state.value = {
      ...state.value,
      page,
    };
    updateQuery();
  };

  const changeLimit = (limit: number) => {
    state.value = {
      ...state.value,
      limit,
      page: DEFAULT_PAGE, // Reset to default page when limit changes
    };
    updateQuery();
  };

  initFromQuery();

  return {
    state,
    setState,
    changePage,
    changeLimit,
    initFromQuery,
  };
});
