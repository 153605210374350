<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  max: number;
  value: number;
  label: string;
}>();

const progress = computed(() => {
  return Math.min((props.value / props.max) * 100, 100).toFixed(2);
});
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="flex justify-between text-imperium-fg-muted text-xs">
      <p>{{ props.label }}</p>
      <p>
        <span class="text-base text-imperium-fg-default">{{ props.value }} </span>
        /{{ props.max }}
      </p>
    </div>
    <div class="w-full h-1.5 bg-imperium-bg-4 rounded-full">
      <div
        class="bg-imperium-ds-primary-strong rounded-full h-full"
        :style="{ width: progress + '%' }"
      ></div>
    </div>
  </div>
</template>
