import { extractData, http } from '@/http';
import type {
  AuthorsFetchRequest,
  AuthorsFetchResponse,
  AuthorByIdFetchRequest,
  Author,
} from '@/features/Authors/types';

// TODO: Replace article related author methods with methods that belongs to Authors feature
export const authors = (params: AuthorsFetchRequest): Promise<AuthorsFetchResponse> => {
  return http.get<AuthorsFetchResponse>('/authors', { params, useAuth: true }).then(extractData);
};

export const authorFetchById = (params: AuthorByIdFetchRequest): Promise<Author> => {
  const { id, languageId } = params;
  return http.get<Author>(`/authors/${id}/language/${languageId}`, { useAuth: true }).then(extractData);
};

export const authorCreate = (postBody: Author): Promise<Author> => {
  return http.post<Author>(`/authors`, postBody, { useAuth: true }).then(extractData);
};

export const authorEdit = (id: number, languageId: number, patchBody: Author): Promise<Author> =>
  http.patch<Author>(`/authors/${id}/language/${languageId}`, patchBody, { useAuth: true }).then(extractData);
