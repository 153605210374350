export const AUTHORS_RESOURCE = 'authors-resource';
export const AUTHOR_RESOURCE = 'author-resource';
export const AUTHOR_CREATE_RESOURCE = 'author-create-resource';

export const AUTHOR_FIELD_NAME = 'name';
export const AUTHOR_FIELD_ID = 'id';
export const AUTHOR_FIELD_DESCRIPTION = 'description';
export const AUTHOR_FIELD_SLUG = 'slug';
export const AUTHOR_FIELD_AVATAR = 'avatar';
export const AUTHOR_FIELD_TWITTER = 'twitter';
export const AUTHOR_FIELD_FACEBOOK = 'facebook';
export const AUTHOR_FIELD_EMAIL = 'email';
export const AUTHOR_FIELD_LINKEDIN = 'linkedIn';
export const AUTHOR_FIELD_SEO_TITLE = 'seoTitle';
export const AUTHOR_FIELD_SEO_DESCRIPTION = 'seoDescription';
export const AUTHOR_FIELD_ENABLED = 'enabled';
export const AUTHOR_FIELD_LANGUAGE_ID = 'languageId';
export const AUTHOR_FIELD_UPDATED_AT = 'updatedAt';
export const AUTHOR_FIELD_CREATED_AT = 'createdAt';
