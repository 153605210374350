import { ref, computed } from 'vue';
import { type SelectItem } from '@/components/FormSelect.vue';
import type { ArticleCategoriesFetchRequest } from '../types';

import { useFetchCategories } from '@/features/ArticleLayout/queries';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import { useArticleCurrentLanguageStore } from '@/stores/article-current-language.ts';

export const useCategories = (languageIdArg?: number) => {
  const currentLanguageStore = useArticleCurrentLanguageStore();
  const languageId = computed(() => currentLanguageStore.state.id);

  const categoriesPayload = ref<ArticleCategoriesFetchRequest>({
    languageId: languageIdArg ?? languageId.value,
    'filter[relevant]': true,
  });
  const { data: categoryTranslates, refetch } = useFetchCategories(categoriesPayload);

  const categories = computed<SelectItem[]>(() => {
    if (!categoryTranslates.value?.items?.length) {
      return [];
    }

    return categoryTranslates.value.items.map((categoryTranslate) => ({
      id: categoryTranslate.id,
      label: categoryTranslate.title,
    }));
  });

  return {
    categories,
    refetch,
  };
};
