<template>
  <section>
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Team</h3>

    <Tooltip
      :key="`tooltip-writer`"
      width="unset"
      name="writer-info"
      placement="auto"
      :tooltip-visible="!documentMetadataWriterSelectEdit && !documentMetadataWriterSelectCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormSelect
          v-if="documentMetadataWriterSelectView"
          name="writer"
          class="mb-2"
          :is-disabled="
            (!documentMetadataWriterSelectEdit && !documentMetadataWriterSelectCondition) ||
            !userStore.hasAccessToCurrentLanguage()
          "
          placeholder="Unassigned"
          :model-value="articleStore.state.writer"
          :attrs="props.writerAttrs"
          :values="writers"
          :size="SIZES.SMALL"
          :variant="VARIANTS.MUTED"
          can-be-cleared
          is-async
          :is-loading="isWritersLoading"
          @update:model-value="(value) => emits('update:writer', value)"
          @search="debouncedOnWriterSearch"
        >
          <template #label>
            <span class="text-xs">Writer</span>
          </template>
        </FormSelect>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-editor`"
      width="unset"
      name="editor-info"
      placement="auto"
      :tooltip-visible="!documentMetadataEditorSelectEdit && !documentMetadataEditorSelectCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormSelect
          v-if="documentMetadataEditorSelectView"
          name="editor"
          class="mb-2"
          :is-disabled="
            (!documentMetadataEditorSelectEdit && !documentMetadataEditorSelectCondition) ||
            !userStore.hasAccessToCurrentLanguage()
          "
          placeholder="Unassigned"
          :model-value="articleStore.state.editor"
          :attrs="props.editorAttrs"
          :size="SIZES.SMALL"
          :values="editors"
          :variant="VARIANTS.MUTED"
          :is-loading="isEditorsLoading"
          is-async
          can-be-cleared
          @update:model-value="(value) => updateField('editor', value)"
          @search="debouncedOnEditorsSearch"
        >
          <template #label>
            <span class="text-xs">Editor</span>
          </template>
        </FormSelect>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-copy-editor`"
      width="unset"
      name="copy-editor-info"
      placement="auto"
      :tooltip-visible="!documentMetadataCopyEditorSelectEdit && !documentMetadataCopyEditorSelectCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormSelect
          v-if="documentMetadataCopyEditorSelectView"
          name="copyEditor"
          class="mb-2"
          :is-disabled="
            (!documentMetadataCopyEditorSelectEdit && !documentMetadataCopyEditorSelectCondition) ||
            !userStore.hasAccessToCurrentLanguage()
          "
          placeholder="Unassigned"
          :model-value="articleStore.state.copyEditor"
          :attrs="props.copyEditorAttrs"
          :values="copyeditors"
          :size="SIZES.SMALL"
          :variant="VARIANTS.MUTED"
          :is-loading="isCopyEditorsLoading"
          can-be-cleared
          @update:model-value="(value) => updateField('copyEditor', value)"
          @search="debouncedOnCopyEditorsSearch"
        >
          <template #label>
            <span class="text-xs">Copy Editor</span>
          </template>
        </FormSelect>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>
  </section>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { debounce } from 'lodash';
import FormSelect from '@/components/FormSelect.vue';
import { SIZES, VARIANTS } from '@/types';
import { useFetchUsers } from '@/features/Users/queries';
import { type UsersFetchPayload } from '@/features/Users/types';
import { mapUsersToSelectItemByRole } from '@/features/Users/utils';
import { type SelectItem } from '@/components/FormSelect.vue';
import { ROLES } from '@/types';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import { useRolesStore } from '@/features/Roles/stores/roles.store';
import { useUserConditions } from '@/composables/useUserConditions.ts';
import Tooltip from '@/components/Tooltip.vue';
import { useArticleCurrentLanguageStore } from '@/stores/article-current-language.ts';

const props = defineProps<{
  writerAttrs: Record<string, unknown>;
  editorAttrs: Record<string, unknown>;
  copyEditorAttrs: Record<string, unknown>;
}>();

const emits = defineEmits<{
  (event: 'update:writer', value: number): void;
  (event: 'update:editor', value: number): void;
  (event: 'update:copyEditor', value: number): void;
}>();

const rolesStore = useRolesStore();
const roles = computed(() => rolesStore.state);
const userStore = useUserStore();

const currentLanguageStore = useArticleCurrentLanguageStore();
const languageId = computed(() => currentLanguageStore.state.id);

const articleStore = useArticleStore();

const getWriterRoleId = computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_WRITER)?.id) ?? null;
const getSeniorWriterRoleId =
  computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_SENIOR_WRITER)?.id) ?? null;
const getEditorRoleId = computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_EDITOR)?.id) ?? null;
const getCopyEditorRoleId =
  computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_COPY_EDITOR)?.id) ?? null;
const getEditorInChiefRoleId =
  computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_EDITOR_IN_CHIEF)?.id) ?? null;

const {
  documentMetadataEditorSelectView,
  documentMetadataEditorSelectEdit,
  documentMetadataWriterSelectView,
  documentMetadataWriterSelectEdit,
  documentMetadataCopyEditorSelectView,
  documentMetadataCopyEditorSelectEdit,
} = useUserPermissions();

const writerSearch = ref<string>('');
const editorSearch = ref<string>('');
const copyEditorSearch = ref<string>('');
const {
  documentMetadataWriterSelectCondition,
  documentMetadataEditorSelectCondition,
  documentMetadataCopyEditorSelectCondition,
} = useUserConditions();

const fetchWriterParams = computed<UsersFetchPayload>(() => ({
  page: 1,
  itemsPerPage: 15,
  filters: {
    search: writerSearch.value,
    languages: [languageId.value],
    roles: [
      getCopyEditorRoleId.value,
      getWriterRoleId.value,
      getEditorRoleId.value,
      getSeniorWriterRoleId.value,
      getEditorInChiefRoleId.value,
    ],
  },
}));
const fetchEditorParams = computed<UsersFetchPayload>(() => ({
  page: 1,
  itemsPerPage: 15,
  filters: {
    search: editorSearch.value,
    languages: [languageId.value],
    roles: [getEditorRoleId.value, getEditorInChiefRoleId.value],
  },
}));
const fetchCopyEditorParams = computed<UsersFetchPayload>(() => ({
  page: 1,
  itemsPerPage: 15,
  filters: {
    search: copyEditorSearch.value,
    languages: [languageId.value],
    roles: [getCopyEditorRoleId.value],
  },
}));
const { data: writersResponse, isLoading: isWritersLoading } = useFetchUsers(fetchWriterParams);
const debouncedOnWriterSearch = debounce((search: string) => {
  writerSearch.value = search;
}, 300);
const { data: editorsResponse, isLoading: isEditorsLoading } = useFetchUsers(fetchEditorParams);
const debouncedOnEditorsSearch = debounce((search: string) => {
  editorSearch.value = search;
}, 300);
const { data: copyEditorsResponse, isLoading: isCopyEditorsLoading } = useFetchUsers(fetchCopyEditorParams);
const debouncedOnCopyEditorsSearch = debounce((search: string) => {
  copyEditorSearch.value = search;
}, 300);

const writers = computed<SelectItem[]>(() => {
  if (!roles.value?.length || !writersResponse.value?.items?.length) {
    return [];
  }

  return [
    ...mapUsersToSelectItemByRole(writersResponse.value.items, ROLES.ROLE_WRITER),
    ...mapUsersToSelectItemByRole(writersResponse.value.items, ROLES.ROLE_SENIOR_WRITER),
    ...mapUsersToSelectItemByRole(writersResponse.value.items, ROLES.ROLE_EDITOR),
    ...mapUsersToSelectItemByRole(writersResponse.value.items, ROLES.ROLE_EDITOR_IN_CHIEF),
  ];
});

const editors = computed<SelectItem[]>(() => {
  if (!roles.value?.length || !editorsResponse.value?.items?.length) {
    return [];
  }

  return [
    ...mapUsersToSelectItemByRole(editorsResponse.value.items, ROLES.ROLE_EDITOR),
    ...mapUsersToSelectItemByRole(editorsResponse.value.items, ROLES.ROLE_EDITOR_IN_CHIEF),
  ];
});

const copyeditors = computed<SelectItem[]>(() => {
  if (!roles.value?.length || !copyEditorsResponse.value?.items?.length) {
    return [];
  }

  return mapUsersToSelectItemByRole(copyEditorsResponse.value.items, ROLES.ROLE_COPY_EDITOR);
});

const updateField = (field: string, value: number) => {
  articleStore.updateField(`${field}Id`, value);
  emits(`update:${field}`, value);
};
</script>
