import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { authorFetchById } from '@/features/Authors/api';
import type { ComputedRef } from 'vue';
import type { AuthorByIdFetchRequest, Author } from '@/features/Authors/types';

import { AUTHOR_RESOURCE } from '@/features/Authors/constants';

export const useFetchAuthorById = (
  params: ComputedRef<AuthorByIdFetchRequest | null>,
  onSuccess: ((result: Author) => void) | null = null,
) => {
  const enabled = computed(() => params.value !== null && params.value.id !== undefined);

  const { data, isLoading, isFetched } = useQuery({
    queryKey: [AUTHOR_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: AuthorByIdFetchRequest = queryKey[1] as AuthorByIdFetchRequest;

      const result: Author = await authorFetchById(payload);

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    },

    // Set it to Infinity cause it was causing avatar draft after upload to disappear,
    // when performs refetch when switching tabs
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isLoading,
    isFetched,
  };
};
