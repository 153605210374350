<template>
  <PermissionTooltip
    :key="`tooltip-spike-button`"
    :tooltip-visible="!documentStatusToSpikedEdit && !documentStatusToSpikedCondition"
  >
    <Button
      class="w-full flex justify-center"
      :class="props.class || null"
      :rounded="props.rounded"
      :visual-type="BUTTON_TYPE.GHOST"
      :color="BUTTON_COLOR.RED"
      :is-loading="props.isLoading"
      :is-disabled="
        (!documentStatusToSpikedEdit && !documentStatusToSpikedCondition) ||
        !userStore.hasAccessToCurrentLanguage() ||
        props.isDisabled
      "
      @click="emits('click')"
    >
      Spike article
    </Button>
  </PermissionTooltip>
</template>

<script lang="ts" setup>
// TODO: Consider creating similar meta button components for other status transition buttons
import Button from '@/components/Button.vue';
import PermissionTooltip from '@/features/ArticleLayout/components/statusButtons/PermissionTooltip.vue';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions';
import { BUTTON_COLOR, BUTTON_TYPE, BUTTON_ROUNDED } from '@/types';

const userStore = useUserStore();

const { documentStatusToSpikedEdit } = useUserPermissions();

const { documentStatusToSpikedCondition } = useUserConditions();

const props = withDefaults(
  defineProps<{
    isLoading: boolean;
    isDisabled?: boolean;
    class?: string;
    rounded?: BUTTON_ROUNDED;
  }>(),
  {
    isLoading: false,
    isDisabled: false,
    class: '',
    rounded: BUTTON_ROUNDED.LARGE,
  },
);

const emits = defineEmits<{
  (event: 'click'): void;
}>();
</script>
