<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { useFormData, useSimpleAction, useToast } from '@/composables';
import { getFormattedDate, getFormattedTime } from '@/utils';
import CloudIcon from '@/assets/icons/cloud.svg?component';
import CheckMarkIcon from '@/assets/icons/check-mark.svg?component';
import DeniedIcon from '@/assets/icons/denied-circle.svg?component';
import Button from '@/components/Button.vue';
import Tooltip from '@/components/Tooltip.vue';
import Badge from '@/components/Badge.vue';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import FormInput from '@/components/FormInput.vue';
import FormUrlInput from '@/components/FormUrlInput.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import { BUTTON_TYPE, SIZES, BADGE_THEMES } from '@/types';
import {
  AUTHOR_FIELD_NAME,
  AUTHOR_FIELD_ID,
  AUTHOR_FIELD_DESCRIPTION,
  AUTHOR_FIELD_SLUG,
  AUTHOR_FIELD_AVATAR,
  AUTHOR_FIELD_TWITTER,
  AUTHOR_FIELD_FACEBOOK,
  AUTHOR_FIELD_EMAIL,
  AUTHOR_FIELD_LINKEDIN,
  AUTHOR_FIELD_SEO_TITLE,
  AUTHOR_FIELD_SEO_DESCRIPTION,
  AUTHOR_FIELD_ENABLED,
  AUTHOR_FIELD_LANGUAGE_ID,
  AUTHOR_FIELD_CREATED_AT,
  AUTHOR_FIELD_UPDATED_AT,
} from '@/features/Authors/constants';
import { type Author } from '@/features/Authors/types';
import FormSection from '@/components/FormSection.vue';
import UploadAvatarWidget from '@/features/UploadImage/components/UploadAvatarWidget.vue';
import { useFetchAuthorById } from '@/features/Authors/queries';
import { useBreadcrumbStore } from '@/features/Breadcrumbs/store';
import { AuthorsService } from '@/features/Authors/service';
import { UploadImageService } from '@/features/UploadImage/service/uploadImage';
import UpdateModal from '@/features/Authors/components/modals/UpdateModal.vue';
import { convertStringToKebabCase } from '@/utils/url';
import { authorsFormSchema } from '@/features/Authors/validators';

const route = useRoute();
const toast = useToast();
const isEditMode = computed(() => !!route.params[AUTHOR_FIELD_ID]);
const hasUnsavedChanges = computed(() => meta.value.dirty);

const isUpdateModalOpen = ref<boolean>(false);

const { defineField, errors, values, meta, validate, setErrors, resetForm } = useFormData({
  data: {
    [AUTHOR_FIELD_ID]: null,
    [AUTHOR_FIELD_AVATAR]: '',
    [AUTHOR_FIELD_NAME]: '',
    [AUTHOR_FIELD_SLUG]: '',
    [AUTHOR_FIELD_DESCRIPTION]: '',
    [AUTHOR_FIELD_TWITTER]: '',
    [AUTHOR_FIELD_FACEBOOK]: '',
    [AUTHOR_FIELD_EMAIL]: '',
    [AUTHOR_FIELD_LINKEDIN]: '',
    [AUTHOR_FIELD_SEO_TITLE]: '',
    [AUTHOR_FIELD_SEO_DESCRIPTION]: '',
    [AUTHOR_FIELD_ENABLED]: true,
    [AUTHOR_FIELD_LANGUAGE_ID]: 1, // TODO: Update when translate arrive
  },
  validator: authorsFormSchema,
});

const isAuthorLoading = ref<boolean>(true);
const enabled = ref<boolean>(false);
const updatedAt = ref<string>('');
const createdAt = ref<string>('');
const { setBreadcrumbsLatestElement } = useBreadcrumbStore();

onMounted(() => {
  if (isEditMode.value) {
    useFetchAuthorById(
      {
        [AUTHOR_FIELD_ID]: Number(route.params[AUTHOR_FIELD_ID]),
        [AUTHOR_FIELD_LANGUAGE_ID]: 1,
      },
      (result: Author) => {
        setBreadcrumbsLatestElement({
          name: result[AUTHOR_FIELD_NAME],
          title: result[AUTHOR_FIELD_NAME],
        });
        enabled.value = result[AUTHOR_FIELD_ENABLED];
        updatedAt.value = result[AUTHOR_FIELD_UPDATED_AT] as string;
        createdAt.value = result[AUTHOR_FIELD_CREATED_AT] as string;

        const { [AUTHOR_FIELD_UPDATED_AT]: _, [AUTHOR_FIELD_CREATED_AT]: __, ...filteredResult } = result;

        resetForm({
          values: filteredResult,
          initialValues: filteredResult,
          resetDirty: true,
        });
        isAuthorLoading.value = false;
      },
    );
  }
});

const [avatar, _avatarAttrs] = defineField(AUTHOR_FIELD_AVATAR);
const [name, nameAttrs] = defineField(AUTHOR_FIELD_NAME);
const [slug, slugAttrs] = defineField(AUTHOR_FIELD_SLUG);
const [description, descriptionAttrs] = defineField(AUTHOR_FIELD_DESCRIPTION);
const [twitter, twitterAttrs] = defineField(AUTHOR_FIELD_TWITTER);
const [facebook, facebookAttrs] = defineField(AUTHOR_FIELD_FACEBOOK);
const [linkedIn, linkedInAttrs] = defineField(AUTHOR_FIELD_LINKEDIN);
const [email, emailAttrs] = defineField(AUTHOR_FIELD_EMAIL);
const [seoTitle, seoTitleAttrs] = defineField(AUTHOR_FIELD_SEO_TITLE);
const [seoDescription, seoDescriptionAttrs] = defineField(AUTHOR_FIELD_SEO_DESCRIPTION);

const uploadAvatar = async (payload: Author) => {
  if (payload.avatar) {
    const { url } = await UploadImageService.imageUpload(
      payload.avatar instanceof File ? { file: payload.avatar } : { url: payload.avatar },
      {
        validateType: 'image',
        minSize: '400x400',
        resizeTo: '400x400',
      },
    );

    avatar.value = url;
  }
};

const { action: createAuthor } = useSimpleAction(async (payload: Author) => {
  try {
    const { valid, errors: validationErrors } = await validate();
    if (!valid) {
      throw validationErrors;
    }
  } catch (errors: any) {
    setErrors(errors as Record<string, string>);
    return;
  }

  try {
    await uploadAvatar(payload);
    await AuthorsService.create(payload);

    setTimeout(() => {
      toast.success({
        id: 'SUCCESS_AUTHOR_CREATION',
        message: 'Author was successfully created',
      });
    }, 0);

    resetForm();
  } catch {
    toast.errorTemporary({
      id: 'ERROR_AUTHOR_CREATION',
      message: 'Unable to create author. Try one more time.',
    });
  }
});

const { action: updateAuthor } = useSimpleAction(async (payload: Author) => {
  try {
    const { id, languageId } = payload;

    await uploadAvatar(payload);
    const result = await AuthorsService.edit(id, languageId, payload);
    toast.success({
      id: 'SUCCESS_AUTHOR_UPDATE',
      message: 'Changes updated successfully.',
    });
    const {
      [AUTHOR_FIELD_CREATED_AT]: _,
      [AUTHOR_FIELD_UPDATED_AT]: __,
      [AUTHOR_FIELD_ENABLED]: ___,
      ...filteredResult
    } = result;
    enabled.value = result[AUTHOR_FIELD_ENABLED];
    updatedAt.value = result[AUTHOR_FIELD_UPDATED_AT] as string;
    createdAt.value = result[AUTHOR_FIELD_CREATED_AT] as string;
    resetForm({
      values: filteredResult,
      initialValues: filteredResult,
      resetDirty: true,
    });
    isUpdateModalOpen.value = false;
  } catch (err) {
    toast.errorTemporary({
      id: 'ERROR_AUTHOR_UPDATE',
      message: 'Something went wrong. Try updating the changes again.',
    });
    throw err;
  }
});

const onSubmit = (e: SubmitEvent) => {
  isEditMode.value ? updateAuthor(values) : createAuthor(values);
  e.preventDefault();
};

const updatedAtText = computed<string>(() => {
  if (!updatedAt.value) return '';

  return `Changes saved on ${getFormattedDate(updatedAt.value)} at ${getFormattedTime(updatedAt.value)}`;
});

const isLeavePageModalOpen = ref(false);
const isCancelModalOpen = ref(false);
const pendingNavigation = ref<(() => void) | null>(null);
onBeforeRouteLeave((_to, _from, next) => {
  if (hasUnsavedChanges.value && isEditMode.value) {
    isLeavePageModalOpen.value = true;
    pendingNavigation.value = () => next();
  } else {
    next();
  }
});

const closeModalAndRedirect = () => {
  isLeavePageModalOpen.value = false;
  if (pendingNavigation.value) {
    pendingNavigation.value(); // Proceed with navigation
    pendingNavigation.value = null;
  }
};

const onRedirectModalConfirm = async () => {
  await updateAuthor(values);
  closeModalAndRedirect();
};

const onRedirectModalClose = () => {
  closeModalAndRedirect();
};
</script>

<template>
  <div
    v-if="isAuthorLoading && isEditMode"
    class="max-w-[100vw] min-h-[60vh] flex items-center justify-center"
  >
    <div class="h-10 w-10">
      <LoaderIcon class="w-10 h-10" />
    </div>
  </div>

  <form
    v-else
    novalidate
    @submit="onSubmit"
  >
    <div class="flex justify-between items-center mb-4 sticky z-10 top-[57px] bg-imperium-bg-3">
      <div
        v-if="updatedAtText"
        class="flex items-center gap-2 mb-2"
      >
        <CloudIcon class="w-6 min-w-6 h-6 min-h-6 text-imperium-ds-green-strong block" />
        <p class="text-base text-imperium-ds-green-strong font-semibold">{{ updatedAtText }}</p>
      </div>

      <div
        v-if="isEditMode"
        class="flex gap-2"
      >
        <Button
          :visual-type="BUTTON_TYPE.TERTIARY"
          :disabled="!hasUnsavedChanges"
          @click="isCancelModalOpen = true"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          :disabled="!meta.valid || !hasUnsavedChanges"
        >
          Update
        </Button>
      </div>

      <Button
        v-else
        type="submit"
        class="ml-auto"
      >
        Create new author
      </Button>
    </div>

    <div class="flex flex-col gap-8 bg-imperium-bg-sub-base p-4 rounded-2xl">
      <FormSection
        number="1"
        title="Profile 📝"
      >
        <template
          v-if="isEditMode"
          #titleRight
        >
          <Tooltip
            name="enabled-info"
            placement="bottom-start"
          >
            <template #content>
              <Badge
                :id="`author-${AUTHOR_FIELD_ENABLED}`"
                :label="enabled ? 'Active' : 'Deactivated'"
                :size="SIZES.XSMALL"
                :theme="enabled ? BADGE_THEMES.GREEN : BADGE_THEMES.DEFAULT"
                class="mr-2"
              >
                <template #icon>
                  <component
                    :is="enabled ? CheckMarkIcon : DeniedIcon"
                    class="w-4 h-4"
                  />
                </template>
              </Badge>
            </template>
            <template
              v-if="enabled"
              #tooltip
            >
              Created date: {{ getFormattedDate(createdAt) }}
            </template>
            <template
              v-else
              #tooltip
            >
              Created date: Dec 25, 2023<br />
              <!-- TODO: Needs backend update -->
              <!-- Deactivated date: Nov 25, 2024 -->
            </template>
          </Tooltip>
        </template>

        <div class="mb-4">
          <UploadAvatarWidget
            :id="name"
            v-model="avatar"
            :label="name || 'new profile pic'"
          />
        </div>

        <div class="flex flex-col md:flex-row gap-4 w-full">
          <div class="flex flex-col gap-4 w-full md:w-1/2">
            <FormInput
              :id="`author-${AUTHOR_FIELD_NAME}`"
              v-model="name"
              is-required
              :attrs="nameAttrs"
              :is-errored="!!errors.name"
              placeholder="Enter full name"
              @update:modelValue="(value: string) => (slug = convertStringToKebabCase(value))"
            >
              <template #label>Author’s Name</template>
              <template #error> {{ errors.name }} </template>
            </FormInput>

            <FormUrlInput
              :name="`author-${AUTHOR_FIELD_SLUG}`"
              label="Author's URL"
              placeholder="author-name"
              is-required
              :post-url="slug === meta.initialValues.slug ? slug : ''"
              :has-post="false"
              :url="slug"
              :url-attrs="slugAttrs"
              :is-disabled="false"
              :size="SIZES.MEDIUM"
              :url-origin="'cointelegraph.com/authors/'"
              :is-readonly="false"
              :is-errored="!!errors.slug"
              @update:url="(value) => (slug = value || '')"
            >
              <template #error> {{ errors.slug }} </template>
            </FormUrlInput>
          </div>

          <FormTextarea
            v-model="description"
            :name="`author-${AUTHOR_FIELD_DESCRIPTION}`"
            class="w-full md:w-1/2"
            min-height="152px"
            placeholder="Provide a summary of the author’s career and contributions"
            :attrs="descriptionAttrs"
          >
            <template #label>About author</template>
          </FormTextarea>
        </div>
      </FormSection>

      <FormSection
        number="2"
        title="Social networks 💬"
      >
        <div class="flex flex-col md:flex-row gap-4 w-full">
          <div class="flex flex-col gap-4 w-full md:w-1/2">
            <FormInput
              :id="`author-${AUTHOR_FIELD_TWITTER}`"
              v-model="twitter"
              :name="`author-${AUTHOR_FIELD_TWITTER}`"
              placeholder="@username"
              :attrs="twitterAttrs"
            >
              <template #label>X (Twitter)</template>
            </FormInput>

            <FormInput
              :id="`author-${AUTHOR_FIELD_LINKEDIN}`"
              v-model="linkedIn"
              :name="`author-${AUTHOR_FIELD_LINKEDIN}`"
              placeholder="https://linkedin.com/in/yourname"
              :attrs="linkedInAttrs"
            >
              <template #label>LinkedIn</template>
            </FormInput>
          </div>

          <div class="flex flex-col gap-4 w-full md:w-1/2">
            <FormInput
              :id="`author-${AUTHOR_FIELD_FACEBOOK}`"
              v-model="facebook"
              :name="`author-${AUTHOR_FIELD_FACEBOOK}`"
              placeholder="https://facebook.com/yourusername"
              :attrs="facebookAttrs"
            >
              <template #label>Facebook</template>
            </FormInput>

            <FormInput
              :id="`author-${AUTHOR_FIELD_EMAIL}`"
              v-model="email"
              :name="`author-${AUTHOR_FIELD_EMAIL}`"
              placeholder="name@example.com"
              :is-errored="!!errors.email"
              :attrs="emailAttrs"
            >
              <template #label>Email</template>
              <template #error>{{ errors.email }}</template>
            </FormInput>
          </div>
        </div>
      </FormSection>

      <FormSection
        number="3"
        title="SEO 🔍"
      >
        <div class="flex flex-col md:flex-row gap-4 w-full">
          <div class="flex flex-col gap-4 w-full md:w-1/2">
            <FormInput
              :id="`author-${AUTHOR_FIELD_SEO_TITLE}`"
              v-model="seoTitle"
              :name="`author-${AUTHOR_FIELD_SEO_TITLE}`"
              placeholder="Enter a concise, keyword-rich title"
              :attrs="seoTitleAttrs"
            >
              <template #label>SEO-title</template>
            </FormInput>
          </div>

          <FormTextarea
            v-model="seoDescription"
            :name="`author-${AUTHOR_FIELD_SEO_DESCRIPTION}`"
            class="w-full md:w-1/2"
            min-height="112px"
            placeholder="Summarize the author's expertise briefly"
            :attrs="seoDescriptionAttrs"
          >
            <template #label>SEO description</template>
          </FormTextarea>
        </div>
      </FormSection>
    </div>
  </form>
  <UpdateModal
    :is-visible="isLeavePageModalOpen"
    @close="onRedirectModalClose"
    @cancel="onRedirectModalClose"
    @confirm="onRedirectModalConfirm"
  >
    <template #description>
      Changes not saved.
      <br />
      Would you like to update them?
    </template>
  </UpdateModal>
  <UpdateModal
    v-if="isEditMode"
    :is-visible="isCancelModalOpen"
    @close="isCancelModalOpen = false"
    @cancel="
      resetForm();
      isCancelModalOpen = false;
    "
    @confirm="
      updateAuthor(values);
      isCancelModalOpen = false;
    "
  >
    <template #description>
      You made changes.
      <br />
      Would you like to update them?
    </template>
  </UpdateModal>
</template>
