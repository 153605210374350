import { checkImageUsedAsCover, imageSetAttributes, imageUpload, imageGalleryUpload } from '@/features/UploadImage/api';
import type {
  ImageUploadResponse,
  ImageSetAttributesRequest,
  ImageSetAttributesResponse,
  ImageUsedAsCoverCounter,
  ImageUsedAsCoverPayload,
  PostData,
  ImageValidationParams,
} from '@/features/UploadImage/types';

function setFormData(payload: PostData) {
  const formData = new FormData();
  if (payload.file) {
    formData.append('file', payload.file, payload.file?.name);
  }

  if (payload.url) {
    formData.append('url', payload.url);
  }

  return formData;
}

export const UploadImageService = {
  imageGalleryUpload: (payload: PostData): Promise<ImageUploadResponse> => {
    return imageGalleryUpload(setFormData(payload));
  },
  imageUpload: (payload: PostData, params: ImageValidationParams): Promise<ImageUploadResponse> => {
    return imageUpload(setFormData(payload), params);
  },
  imageSetAttributes: (id: number, payload: ImageSetAttributesRequest): Promise<ImageSetAttributesResponse> =>
    imageSetAttributes(id, payload),
  checkImageUsedAsCover: (imageId: number): Promise<ImageUsedAsCoverCounter> => checkImageUsedAsCover(imageId),
};
