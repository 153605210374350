import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { users as fetchUsers } from '../api';
import type { UsersFetchPayload, UsersFetchResponse } from '../types';
import type { ComputedRef } from 'vue';

import { USERS_RESOURCE } from '../constants';

export const useFetchUsers = (
  params: ComputedRef<UsersFetchPayload>,
  enabled: boolean = true,
  onSuccess: ((result: UsersFetchResponse) => void) | null = null,
) => {
  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: [USERS_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: UsersFetchPayload = queryKey[1] as UsersFetchPayload;

      const result = await fetchUsers(payload);

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    },
    staleTime: 0,
    refetchInterval: false,
    enabled,
    placeholderData: keepPreviousData,
  });

  return {
    data,
    isLoading,
    refetch,
    isFetching,
  };
};
