<template>
  <Tooltip
    :key="props.key"
    :name="props.key"
    :tooltip-visible="props.tooltipVisible"
    width="unset"
    placement="auto"
    button-class="w-full text-left"
  >
    <template #content>
      <slot />
    </template>
    <template #tooltip>
      <div class="text-sm whitespace-nowrap">{{ props.tooltipText }}</div>
    </template>
  </Tooltip>
</template>

<script lang="ts" setup>
import Tooltip from '@/components/Tooltip.vue';

const props = withDefaults(
  defineProps<{
    key: string;
    tooltipVisible?: boolean;
    tooltipText?: string;
  }>(),
  {
    tooltipVisible: false,
    tooltipText: 'No access to edit for your role',
  },
);
</script>
