<template>
  <Dropdown
    :id="`limit-${props.id}`"
    ref="limitDropdown"
    menu-width="25"
    placement="top"
  >
    <template #button>
      <Button
        class="inline-flex items-center h-10 gap-1.5 text-sm font-medium text-center"
        type="button"
        :visual-type="BUTTON_TYPE.TERTIARY"
        :size="SIZES.SMALL"
      >
        <div
          :data-testid="`current-limit-value-${id}`"
          class="text-right"
        >
          {{ currentLimit }}
        </div>
        <template #rightIcon>
          <ArrowDown
            :class="{
              'w-5 h-5': props.size === SIZES.SMALL,
              'w-6 h-6': props.size === SIZES.MEDIUM,
            }"
          />
        </template>
      </Button>
    </template>
    <template #menu>
      <div
        :id="`dropdown-radio-bg-hover-${id}`"
        class="z-10 bg-imperium-bg-sub-base divide-y divide-imperium-bg-3 rounded-lg shadow"
      >
        <ul
          class="p-3 space-y-1 text-sm text-imperium-fg-strong"
          :aria-labelledby="`dropdown-radio-bg-hover-${id}`"
        >
          <li
            v-for="value of props.limits"
            :key="value"
            class="py-1"
          >
            <RadioButton
              v-model="currentLimit"
              :value="value"
              @click="chooseLimit(value)"
            >
              <template #label>
                {{ value }}
              </template>
            </RadioButton>
          </li>
        </ul>
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import Dropdown from '@/components/Dropdown.vue';
import ArrowDown from '@/assets/icons/arrow-down.svg?component';
import RadioButton from '@/components/RadioButton.vue';
import Button from '@/components/Button.vue';
import { BUTTON_TYPE, SIZES, THEMES } from '@/types';
import { DEFAULT_TABLE_PER_PAGE } from '@/config.ts';

const emits = defineEmits<{
  (event: 'change-limit', value: number): void;
}>();

const props = withDefaults(
  defineProps<{
    limit: number;
    id: string;
    size?: SIZES;
    // In case you need non default limits values
    limits?: number[];
  }>(),
  {
    limit: DEFAULT_TABLE_PER_PAGE,
    limits: [DEFAULT_TABLE_PER_PAGE, 50, 100],
    size: SIZES.MEDIUM,
  },
);

const currentLimit = ref<number>(DEFAULT_TABLE_PER_PAGE);
const limitDropdown = ref<typeof Dropdown | null>(null);

watchEffect(() => {
  currentLimit.value = props.limit;
});

const chooseLimit = (value: number) => {
  emits('change-limit', value);
  limitDropdown.value?.close();
};
</script>
