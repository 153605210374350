<template>
  <div class="mb-4">
    <ConvertersTableHeader class="mt-2 mb-4" />
    <div class="relative overflow-x-auto">
      <div
        :class="{
          'rounded-2xl': !isMobile,
        }"
        class="min-w-[926px] relative bg-imperium-bg-sub-base"
      >
        <Table :top-rounded="!isMobile">
          <TableHead>
            <ConverterHeader />
          </TableHead>
          <TableBody>
            <div
              v-if="isFetching && !isLoading"
              class="w-full h-full flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            >
              <div class="h-10 w-10">
                <LoaderIcon />
              </div>
            </div>
            <template v-if="isLoading">
              <TableRowSkeleton
                v-for="item in new Array(paginationStore.state.limit)"
                :key="item"
                :columns="6"
              />
            </template>
            <ConverterRow
              v-for="converter in converters.items"
              v-else
              :key="converter.id"
              :converter="converter"
              :class="{
                'opacity-30': isFetching,
              }"
            />
          </TableBody>
        </Table>

        <div
          v-if="!paginationStore.state.total"
          class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
        >
          <div class="font-semibold text-imperium-fg-strong">No converters found</div>
        </div>
        <div
          v-else
          class="py-3 p-4 bg-white rounded-b-2xl"
        >
          <Pagination
            :current-page="paginationStore.state.page"
            :is-mobile="isMobile || isTablet"
            :items-count="paginationStore.state.total || 0"
            :limit="paginationStore.state.limit"
            @change-page="paginationStore.changePage"
            @change-limit="paginationStore.changeLimit"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, inject } from 'vue';
import { Table, TableBody, TableHead, TableRow } from '@/components/Table';
import ConverterRow from '@/features/Converter/components/ConverterTable/ConverterRow.vue';
import ConverterHeader from '@/features/Converter/components/ConverterTable/ConverterHeader.vue';
import Pagination from '@/components/Table/Pagination/Pagination.vue';
import { usePagination } from '@/features/Converter/stores/convertersPagination.store';
import { useConvertersFilters } from '@/features/Converter/stores/convertersFilter.store';
import ConvertersTableHeader from '@/features/Converter/components/ConverterTable/ConvertersTableHeader.vue';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import { useFetchConverters } from '@/features/Converter/queries/converters.query.ts';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries.ts';
import { type ConverterFetchResponse } from '@/features/Converter/types/api.ts';
import TableRowSkeleton from '@/components/Skeletons/TableRowSkeleton.vue';

const isTablet = inject('isTablet');
const isMobile = inject('isMobile');

const paginationStore = usePagination();
const filtersStore = useConvertersFilters();

const converterParams = computed(() => {
  const params = {
    page: paginationStore.state.page,
    itemsPerPage: paginationStore.state.limit,
    languageId: DEFAULT_LANGUAGE_ID,
    pagination: true,
  };

  if (filtersStore.state.searchQuery) {
    params['filter[search]'] = filtersStore.state.searchQuery;
  }

  for (const key in filtersStore.state.sort) {
    if (filtersStore.state.sort[key]) {
      params[`order[${key}]`] = filtersStore.state.sort[key];
    }
  }

  return params;
});

const {
  data: converters,
  isLoading,
  isFetching,
} = useFetchConverters(converterParams, (result: ConverterFetchResponse) => {
  if (!result) {
    return;
  }

  paginationStore.setState({
    page: result.page,
    total: result.total,
  });
});
</script>
