import { Plugin } from 'prosemirror-state';
import { WIDGETS } from '@/features/CollaborativeEditor/utils/state.ts';
import { useToast } from '@/composables';

// Prevent from drag & drop items to special nodes
const toast = useToast();
export function preventDropPlugin() {
  return new Plugin({
    props: {
      handleDOMEvents: {
        drop(view, event) {
          const { state } = view;
          const { schema } = state;
          const { doc } = state;

          const pos = view.posAtCoords({ left: event.clientX, top: event.clientY });

          if (pos) {
            const $pos = doc.resolve(pos.pos);

            let isForbidden = false;
            for (let i = $pos.depth; i >= 0; i--) {
              const node = $pos.node(i);
              if (WIDGETS.includes(node.type.name)) {
                isForbidden = true;
                break;
              }
            }

            if (isForbidden) {
              toast.warning({
                id: 'WRONG_DRAG_ELEMENT',
                message: 'Element drop is not allowed here. Try a different area',
              });
              event.preventDefault();
              return true;
            }
          }

          return false;
        },
      },
    },
  });
}
