import { computed } from 'vue';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import type { Condition } from '@/features/Conditions/types.ts';
import { ROLES, UserConditions, UserRoles } from '@/types';

export const useUserConditions = () => {
  const articleStore = useArticleStore();
  const user = useUserStore();

  const {
    getUserId,
    documentStatusToFinishTextCondition,
    documentStatusToFinishArtworkCondition,
    documentStatusToPublishCondition,
    documentMetadataTextCondition,
    documentMetadataCoverDeadlineCondition,
    documentMetadataEmbargoCondition,
    documentMetadataHideFromMainPageCondition,
    documentMetadataShowInMarketsCondition,
    documentMetadataPromoPostCondition,
    documentMetadataNoIndexCondition,
    uploadMediaConditions,
    editMediaConditions,
    deleteMediaConditions,
    inviteUserConditions,
    inviteSuperAdminConditions,
    changeUserStatusConditions,
    createDocumentConditions,
    documentStatusToWorkConditions,
    documentStatusToBackToWorkConditions,
    documentStatusToRejectTextConditions,
    documentStatusToRejectArtConditions,
    documentStatusToFinishReviewConditions,
    documentStatusToUpdateConditions,
    documentMetadataSlugConditions,
    documentMetadataTitleConditions,
    documentMetadataCoverConditions,
    documentMetadataBreakingNewsConditions,
    documentMetadataOriginalArtConditions,
    documentMetadataEditorSelectConditions,
    documentMetadataWriterSelectConditions,
    documentMetadataCopyEditorSelectConditions,
    documentMetadataYoutubeCoverConditions,
    documentMetadataScheduledConditions,
    documentMetadataCategoryConditions,
    documentMetadataLabelConditions,
    documentMetadataSuperTagsConditions,
    documentMetadataTagsConditions,
    documentMetadataXLeadTextConditions,
    documentMetadataExcludeFromXRSSConditions,
    documentMetadataExcludeFromTgChannelsConditions,
    documentMetadataSeoDescConditions,
    documentMetadataRepublishArticleConditions,
    documentMetadataOriginContentConditions,
    documentMetadataSearchCoverAIConditions,
    tagsConditions,
    documentStatusToSpikedConditions,
  } = useUserPermissions();

  //UserConditions
  const isUserAssignedToArticle = (conditions: Array<string>): boolean => {
    if (!conditions || !user.state?.id) return true;

    if (conditions.includes(UserConditions.WRITER_ASSIGNED)) {
      return articleStore?.state?.writerId === user.state.id && user.state.roles.includes(ROLES.ROLE_WRITER);
    }
    if (conditions.includes(UserConditions.SENIOR_WRITER_ASSIGNED)) {
      return articleStore?.state?.writerId === user.state.id && user.state.roles.includes(ROLES.ROLE_SENIOR_WRITER);
    }
    if (conditions.includes(UserConditions.WRITERS_ASSIGNED)) {
      return (
        articleStore?.state?.writerId === user.state.id &&
        (user.state.roles.includes(ROLES.ROLE_WRITER) || user.state.roles.includes(ROLES.ROLE_SENIOR_WRITER))
      );
    }
  };

  const finishTextCondition = computed<boolean>(() => {
    return documentStatusToFinishTextCondition && isUserAssignedToArticle(documentStatusToFinishTextCondition);
  });

  const artworkCondition = computed<boolean>(() => {
    return documentStatusToFinishArtworkCondition && isUserAssignedToArticle(documentStatusToFinishArtworkCondition);
  });
  const publishCondition = computed<boolean>(() => {
    return isUserAssignedToArticle(documentStatusToPublishCondition) && documentStatusToPublishCondition;
  });
  const documentTextCondition = computed<boolean>(() => {
    return documentMetadataTextCondition && isUserAssignedToArticle(documentMetadataTextCondition);
  });
  const metadataCoverCondition = computed<boolean>(() => {
    return documentMetadataCoverDeadlineCondition && isUserAssignedToArticle(documentMetadataCoverDeadlineCondition);
  });
  const metadataEmbargoCondition = computed<boolean>(() => {
    return documentMetadataEmbargoCondition && isUserAssignedToArticle(documentMetadataEmbargoCondition);
  });
  const hideFromMainCondition = computed<boolean>(() => {
    return (
      documentMetadataHideFromMainPageCondition && isUserAssignedToArticle(documentMetadataHideFromMainPageCondition)
    );
  });
  const showInMarketCondition = computed<boolean>(() => {
    return documentMetadataShowInMarketsCondition && isUserAssignedToArticle(documentMetadataShowInMarketsCondition);
  });
  const promoPostCondition = computed<boolean>(() => {
    return documentMetadataPromoPostCondition && isUserAssignedToArticle(documentMetadataPromoPostCondition);
  });

  const editNoIndexCondition = computed<boolean>(() => {
    return documentMetadataNoIndexCondition && isUserAssignedToArticle(documentMetadataNoIndexCondition);
  });

  const uploadMediaCondition = computed(() => {
    return uploadMediaConditions && isUserAssignedToArticle(uploadMediaConditions);
  });

  const editMediaCondition = computed(() => {
    return isUserAssignedToArticle(editMediaConditions) && editMediaConditions;
  });

  const deleteMediaCondition = computed(() => {
    return deleteMediaConditions && isUserAssignedToArticle(deleteMediaConditions);
  });

  const inviteUserCondition = computed(() => {
    return inviteUserConditions && isUserAssignedToArticle(inviteUserConditions);
  });
  const inviteSuperAdminCondition = computed(() => {
    return inviteSuperAdminConditions && isUserAssignedToArticle(inviteSuperAdminConditions);
  });
  const changeUserStatusCondition = computed(() => {
    return changeUserStatusConditions && isUserAssignedToArticle(changeUserStatusConditions);
  });
  const createDocumentCondition = computed(() => {
    return createDocumentConditions && isUserAssignedToArticle(createDocumentConditions);
  });
  const documentStatusToWorkCondition = computed(() => {
    return documentStatusToWorkConditions && isUserAssignedToArticle(documentStatusToWorkConditions);
  });
  const documentStatusToBackToWorkCondition = computed(() => {
    return documentStatusToBackToWorkConditions && isUserAssignedToArticle(documentStatusToBackToWorkConditions);
  });
  const documentStatusToRejectTextCondition = computed(() => {
    return documentStatusToRejectTextConditions && isUserAssignedToArticle(documentStatusToRejectTextConditions);
  });
  const documentStatusToRejectArtCondition = computed(() => {
    return documentStatusToRejectArtConditions && isUserAssignedToArticle(documentStatusToRejectArtConditions);
  });
  const documentStatusToFinishReviewCondition = computed(() => {
    return documentStatusToFinishReviewConditions && isUserAssignedToArticle(documentStatusToFinishReviewConditions);
  });
  const documentStatusToUpdateCondition = computed(() => {
    return documentStatusToUpdateConditions && isUserAssignedToArticle(documentStatusToUpdateConditions);
  });

  const documentStatusToSpikedCondition = computed(() => {
    return documentStatusToSpikedConditions && isUserAssignedToArticle(documentStatusToSpikedConditions);
  });

  const documentMetadataSlugCondition = computed(() => {
    return documentMetadataSlugConditions && isUserAssignedToArticle(documentMetadataSlugConditions);
  });
  const documentMetadataTitleCondition = computed(() => {
    return documentMetadataTitleConditions && isUserAssignedToArticle(documentMetadataTitleConditions);
  });
  const documentMetadataCoverCondition = computed(() => {
    return documentMetadataCoverConditions && isUserAssignedToArticle(documentMetadataCoverConditions);
  });
  const documentMetadataBreakingNewsCondition = computed(() => {
    return documentMetadataBreakingNewsConditions && isUserAssignedToArticle(documentMetadataBreakingNewsConditions);
  });
  const documentMetadataOriginalArtCondition = computed(() => {
    return documentMetadataOriginalArtConditions && isUserAssignedToArticle(documentMetadataOriginalArtConditions);
  });
  const documentMetadataEditorSelectCondition = computed(() => {
    return documentMetadataEditorSelectConditions && isUserAssignedToArticle(documentMetadataEditorSelectConditions);
  });
  const documentMetadataWriterSelectCondition = computed(() => {
    return documentMetadataWriterSelectConditions && isUserAssignedToArticle(documentMetadataWriterSelectConditions);
  });
  const documentMetadataCopyEditorSelectCondition = computed(() => {
    return (
      documentMetadataCopyEditorSelectConditions && isUserAssignedToArticle(documentMetadataCopyEditorSelectConditions)
    );
  });
  const documentMetadataYoutubeCoverCondition = computed(() => {
    return documentMetadataYoutubeCoverConditions && isUserAssignedToArticle(documentMetadataYoutubeCoverConditions);
  });
  const documentMetadataScheduledCondition = computed(() => {
    return documentMetadataScheduledConditions && isUserAssignedToArticle(documentMetadataScheduledConditions);
  });
  const documentMetadataCategoryCondition = computed(() => {
    return documentMetadataCategoryConditions && isUserAssignedToArticle(documentMetadataCategoryConditions);
  });
  const documentMetadataLabelCondition = computed(() => {
    return documentMetadataLabelConditions && isUserAssignedToArticle(documentMetadataLabelConditions);
  });
  const documentMetadataSuperTagsCondition = computed(() => {
    return documentMetadataSuperTagsConditions && isUserAssignedToArticle(documentMetadataSuperTagsConditions);
  });
  const documentMetadataTagsCondition = computed(() => {
    return documentMetadataTagsConditions && isUserAssignedToArticle(documentMetadataTagsConditions);
  });
  const documentMetadataXLeadTextCondition = computed(() => {
    return documentMetadataXLeadTextConditions && isUserAssignedToArticle(documentMetadataXLeadTextConditions);
  });
  const documentMetadataExcludeFromXRSSCondition = computed(() => {
    return (
      documentMetadataExcludeFromXRSSConditions && isUserAssignedToArticle(documentMetadataExcludeFromXRSSConditions)
    );
  });
  const documentMetadataExcludeFromTgChannelsCondition = computed(() => {
    return (
      documentMetadataExcludeFromTgChannelsConditions &&
      isUserAssignedToArticle(documentMetadataExcludeFromTgChannelsConditions)
    );
  });
  const documentMetadataSeoDescCondition = computed(() => {
    return documentMetadataSeoDescConditions && isUserAssignedToArticle(documentMetadataSeoDescConditions);
  });
  const documentMetadataRepublishArticleCondition = computed(() => {
    return (
      documentMetadataRepublishArticleConditions && isUserAssignedToArticle(documentMetadataRepublishArticleConditions)
    );
  });
  const documentMetadataOriginContentCondition = computed(() => {
    return documentMetadataOriginContentConditions && isUserAssignedToArticle(documentMetadataOriginContentConditions);
  });
  const documentMetadataSearchCoverAICondition = computed(() => {
    return documentMetadataSearchCoverAIConditions && isUserAssignedToArticle(documentMetadataSearchCoverAIConditions);
  });
  const tagsCondition = computed(() => {
    return tagsConditions && isUserAssignedToArticle(tagsConditions);
  });

  return {
    finishTextCondition,
    artworkCondition,
    publishCondition,
    documentTextCondition,
    metadataCoverCondition,
    metadataEmbargoCondition,
    hideFromMainCondition,
    showInMarketCondition,
    promoPostCondition,
    editNoIndexCondition,
    uploadMediaCondition,
    editMediaCondition,
    deleteMediaCondition,
    inviteUserCondition,
    inviteSuperAdminCondition,
    changeUserStatusCondition,
    createDocumentCondition,
    documentStatusToWorkCondition,
    documentStatusToBackToWorkCondition,
    documentStatusToRejectTextCondition,
    documentStatusToRejectArtCondition,
    documentStatusToFinishReviewCondition,
    documentStatusToUpdateCondition,
    documentStatusToSpikedCondition,
    documentMetadataSlugCondition,
    documentMetadataTitleCondition,
    documentMetadataCoverCondition,
    documentMetadataBreakingNewsCondition,
    documentMetadataOriginalArtCondition,
    documentMetadataEditorSelectCondition,
    documentMetadataWriterSelectCondition,
    documentMetadataCopyEditorSelectCondition,
    documentMetadataYoutubeCoverCondition,
    documentMetadataScheduledCondition,
    documentMetadataCategoryCondition,
    documentMetadataLabelCondition,
    documentMetadataSuperTagsCondition,
    documentMetadataTagsCondition,
    documentMetadataXLeadTextCondition,
    documentMetadataExcludeFromXRSSCondition,
    documentMetadataExcludeFromTgChannelsCondition,
    documentMetadataRepublishArticleCondition,
    documentMetadataOriginContentCondition,
    documentMetadataSeoDescCondition,
    documentMetadataSearchCoverAICondition,
    tagsCondition,
  };
};
