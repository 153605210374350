<script setup lang="ts">
import ModalHolder from '@/components/ModalHolder.vue';
import { BUTTON_TYPE, SIZES } from '@/types';
import ExclamationShapesIcon from '@/assets/icons/exclamation-shapes.svg?component';
import Button from '@/components/Button.vue';

const props = withDefaults(
  defineProps<{
    isVisible?: boolean;
    bodyText?: string;
  }>(),
  {
    isVisible: false,
    bodyText: 'Changes not saved. Would you like to save them?',
  },
);

const emits = defineEmits<{
  (event: 'close'): void;
  (event: 'closeByCross'): void;
  (event: 'save'): void;
}>();
</script>

<template>
  <ModalHolder
    :is-visible="props.isVisible"
    :size="SIZES.SMALL"
    data-testid="insert-image-modal"
    @close="emits('closeByCross')"
  >
    <div class="flex flex-col justify-center items-center">
      <ExclamationShapesIcon class="w-20 h-20 text-imperium-bg-5 mb-4" />
      <p class="text-imperium-fg-base text-base font-normal text-center">
        {{ props.bodyText }}
      </p>
    </div>

    <div class="flex items-center gap-4 justify-center pt-5">
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        @click="emits('close')"
      >
        Don’t save changes
      </Button>

      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        @click="emits('save')"
      >
        Save changes
      </Button>
    </div>
  </ModalHolder>
</template>
