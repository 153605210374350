// Clean y.doc if article_widget has no attrs
export const cleanYDoc = (ydoc, schema, docName) => {
  const tr = ydoc.getXmlFragment(docName);

  tr.forEach((node, index) => {
    if (node.nodeName === 'article_widget') {
      const idAttr = node.getAttribute('id');
      if (!idAttr) {
        tr.delete(index, 1);
      }
    }
  });
};
