import zod from 'zod';

const MAX_USERNAME_LENGTH = 100;

export const userInvitationFormValidate = zod.object({
  userName: zod.string().min(3, 'Enter a valid name and surname').max(MAX_USERNAME_LENGTH, ' '),
  email: zod.string().email('Enter a valid email address'),
  roles: zod.array(zod.number()).min(1, 'Choose a role for the user'),
  languages: zod.array(zod.number()).min(1, 'Choose a language access'),
  shift: zod.string().optional(),
});
