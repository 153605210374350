<script setup>
import { isAuthenticatedByStore } from '@/services/auth';
import { computed, onBeforeUnmount, watch } from 'vue';
import { useErrorStore } from '@/stores/error.store.ts';
import { useRoute } from 'vue-router';

const errorStore = useErrorStore();
const route = useRoute();

const errorMessage = computed(() => {
  if (errorStore.state && errorStore.state.errorMessage) {
    return errorStore.state.errorMessage;
  } else {
    return 'Sorry, that page cannot be found.';
  }
});

watch(
  () => route.path,
  (newVal) => {
    if (newVal) errorStore.clearError();
  },
);
</script>
<template>
  <div
    class="m-auto items-center justify-center absolute md:static top-1/2 -translate-y-1/2 lg:translate-y-0 right-0 left-0 min-w-full lg:min-w-[unset] flex"
  >
    <div class="flex flex-col gap-8 md:flex-row items-center px-4">
      <p class="text-5xl font-semibold text-imperium-fg-strong leading-[57.6px]">404</p>
      <hr class="w-px h-24 bg-imperium-bg-4 hidden md:block" />
      <div class="text-base font-semibold">
        <p>{{ errorMessage }}</p>
        <RouterLink
          v-if="!isAuthenticatedByStore()"
          to="/auth/login"
          class="text-imperium-ds-secondary-base"
        >
          Return to Login
        </RouterLink>
      </div>
    </div>
  </div>
</template>
