import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { fetchArticles } from '../api';
import { type ArticlesFetchRequest } from '../types';
import { type ComputedRef } from 'vue';

import { ARTICLES_RESOURCE } from '../constants';

// Converts filter values like { id: 2, label: 'string' } to array with ids
const transformFilterObjectsToIdsArray = (payload: ArticlesFetchRequest): ArticlesFetchRequest => {
  Object.entries(payload).forEach(([key, value]) => {
    const typedKey = key as keyof ArticlesFetchRequest;
    if (typedKey.startsWith('filter[') && Array.isArray(value)) {
      payload[typedKey] = value.map((item) => (item.id || item) as number);
    }
  });
  return payload;
};

export const useFetchArticles = (params: ComputedRef<ArticlesFetchRequest>, onSuccess = null) => {
  const { data, isLoading, isFetched, isFetching } = useQuery({
    queryKey: [ARTICLES_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: ArticlesFetchRequest = queryKey[1] as ArticlesFetchRequest;

      const result = await fetchArticles(transformFilterObjectsToIdsArray(payload));

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    },
    refetchOnMount: 'always',
    placeholderData: keepPreviousData,
  });

  return {
    data,
    isLoading,
    isFetched,
    isFetching,
  };
};
