<template>
  <div class="flex flex-col mt-2">
    <div class="flex mb-4">
      <Search
        class="md:max-w-[300px]"
        is-suffix-shown
        :placeholder="t('authors.search-placeholder')"
        :value="searchQuery"
        @update:modelValue="(value) => updateSearchQuery(value)"
      />

      <div class="flex gap-2 ml-auto">
        <Button
          v-if="authorPageView"
          :size="SIZES.MEDIUM"
          data-testid="create-new-author-modal-show"
          @click="router.push({ path: '/authors/create' })"
        >
          <template #leftIcon>
            <PlusIcon class="w-5 h-5" />
          </template>
          {{ t('authors.new-author') }}
        </Button>
      </div>
    </div>

    <AuthorsTable />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useUserPermissions } from '@/stores/user.store';
import { useAuthorsFilters } from '@/features/Authors/stores/filters.store';
import Button from '@/components/Button.vue';
import { SIZES } from '@/types';
import Search from '@/components/Table/Filters/Search.vue';
import PlusIcon from '@/assets/icons/plus.svg?component';
import AuthorsTable from '@/features/Authors/components/AuthorsTable.vue';

const { authorPageView } = useUserPermissions();

const { t } = useI18n();
const router = useRouter();

const filtersStore = useAuthorsFilters();

const searchQuery = computed(() => filtersStore.state?.searchQuery || '');

const updateSearchQuery = (searchQuery: string) => {
  filtersStore.setSearchQuery(searchQuery);
};
</script>
