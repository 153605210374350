import { defineStore } from 'pinia';
import { ref } from 'vue';
import { fetchDomain } from '@/api/domain.ts';

export const useWebsiteDomainStore = defineStore('useWebsiteDomainStore', () => {
  const state = ref<string | null>(null);

  const getDomain = async (languageId: number) => {
    const { domain } = await fetchDomain({ languageId });
    return domain;
  };

  const setDomain = (domain: string) => {
    state.value = domain;
  };

  const clearDomain = () => {
    state.value = null;
  };

  return {
    state,
    getDomain,
    setDomain,
    clearDomain,
  };
});
