<template>
  <Dropdown
    v-if="hasLanguagesOptions"
    id="article-translate"
    ref="dropdownElement"
    placement="bottom-start"
    :classes="{
      dropdown: 'w-60',
    }"
  >
    <template #button>
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        :is-disabled="props.isLoading || props.isDisabled"
        @click="emits('toggle')"
      >
        <template #leftIcon>
          <PlusIcon class="w-4 h-4 text-[#101010]" />
        </template>

        <template #rightIcon>
          <ArrowDownIcon class="w-4 h-4 text-[#101010]" />
        </template>

        Translate
      </Button>
    </template>

    <template #menu>
      <p
        v-if="props.isLoading"
        class="pt-2 px-3 pb-3 w-full flex items-center justify-center"
      >
        <LoaderIcon class="h-10 w-10" />
      </p>
      <ul
        v-else
        class="max-h-64 py-2 px-2 overflow-y-auto overflow-x-hidden text-base"
      >
        <li
          v-for="language in languagesOptions"
          :key="language.short"
          class="w-full py-2 px-1 text-md font-semibold text-gray-900 hover:bg-imperium-bg-2 rounded dark:text-gray-300 flex items-center gap-2 cursor-pointer"
          @click="select(language)"
        >
          <CountryIcon
            :key="language.short"
            class="flex-none"
            :code="language.short"
            :name="language.title"
          />
          {{ language.title }}
        </li>
      </ul>
    </template>
  </Dropdown>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { SIZES, BUTTON_TYPE, type Language } from '@/types';

import Dropdown from '@/components/Dropdown.vue';
import Button from '@/components/Button.vue';
import ArrowDownIcon from '@/assets/icons/arrow-down.svg?component';
import PlusIcon from '@/assets/icons/plus.svg?component';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import CountryIcon from '@/shared/ui/CountryIcon/CountryIcon.vue';
import { useUserStore } from '@/stores/user.store.ts';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store.ts';

const { state: user } = useUserStore();
const articleStore = useArticleStore();

const props = defineProps<{
  isLoading: boolean;
  documentLanguageId: number;
  isDisabled?: boolean;
}>();

const emits = defineEmits<{
  (event: 'toggle'): void;
  (event: 'select', value: Language): void;
}>();

const dropdownElement = ref<typeof Dropdown | null>(null);

const articleTranslates = computed(() => articleStore.state?.languages ?? []);
const select = (language: Language) => {
  emits('select', language);
  dropdownElement.value?.close();
};
const languagesOptions = computed(() => {
  return user.languages.filter(
    (language: Language) =>
      language.id !== props.documentLanguageId &&
      language.id !== 0 &&
      !articleTranslates.value.includes(language.id) &&
      language.active,
  );
});

const hasLanguagesOptions = computed<boolean>(() => {
  return languagesOptions.value?.length > 0;
});
</script>
