import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import type { ComputedRef } from 'vue';

import { CONVERTERS_RESOURCE } from '@/features/Converter/constants';
import type { ConverterFetchPayload } from '@/features/Converter/types/api';
import { type ConverterFetchResponse } from '@/features/Converter/types/api';
import { converters } from '@/features/Converter/api';

type OnSuccessCallback = (result: ConverterFetchResponse) => void;
export const useFetchConverters = (
  params: ComputedRef<ConverterFetchPayload>,
  onSuccess: OnSuccessCallback | null = null,
) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [CONVERTERS_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: ConverterFetchPayload = queryKey[1] as ConverterFetchPayload;

      const result = await converters(payload);

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    },
    staleTime: 0,
    refetchInterval: false,
    placeholderData: keepPreviousData,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};
