import type { Media } from '@/features/Media/types';

export type ImageUploadRequest = FormData;

export interface ImageUploadResponse extends Media {}

export interface ImageSetAttributesRequest {
  title: string;
}

export type ImageSetAttributesResponse = string;

export const enum ImageTabState {
  UPLOAD = 'upload',
  GALLERY = 'gallery',
  LINK = 'link',
}

export interface NavTab {
  id: ImageTabState;
  title: string;
}

export enum ImageLoadingState {
  CHOOSE = 'choose',
  LOADING = 'loading',
  SET_ATTRIBUTES = 'setAttributes',
}

export interface ImageUsedAsCoverCounter {
  counter: number;
}

export interface ImageUsedAsCoverPayload {
  limitDocuments: number;
}

export interface PostData {
  url?: string;
  file?: File;
}

export interface ImageValidationParams {
  validateType?: string;
  minSize?: string;
  resizeTo?: string;
}
