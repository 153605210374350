import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M14.5 11.55v-.1h.5c1.5 0 3-1.494 3-3.45 0-2.741-1.5-5-6-5H6.5a.5.5 0 0 0-.5.5v17a.5.5 0 0 0 .5.5h7.59C17 21 19 19 19 16c0-2.5-1.5-4.45-4-4.45zM9.5 10V6h3c1.5 0 2.2.916 2.2 2 0 1.089-.2 2-2.004 2zm3.5 3c2 0 2.7 1.219 2.7 2.5 0 2-1.2 2.5-2.7 2.5H9.5v-5z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }