import { type ComputedRef } from 'vue';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { tags } from '@/features/Tags/api';
import type { TagsFetchRequest, TagsFetchResponse } from '@/features/Tags/types';

import { TAGS_RESOURCE } from '@/features/Tags/constants';

export const useFetchTags = (
  params: TagsFetchRequest | ComputedRef<TagsFetchRequest>,
  onSuccess: ((result: TagsFetchResponse) => void) | null = null,
) => {
  const { data, isLoading, isFetched, isFetching, refetch } = useQuery({
    queryKey: [TAGS_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: TagsFetchRequest = queryKey[1] as TagsFetchRequest;

      const result = await tags(payload);

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    },

    staleTime: 0,
    gcTime: 0,
    refetchInterval: false,
    refetchOnMount: 'always',
    placeholderData: keepPreviousData,
  });

  return {
    data,
    isLoading,
    isFetched,
    isFetching,
    refetch,
  };
};
