<template>
  <FormInput
    ref="SearchInput"
    :model-value="props.value"
    :attrs="{
      class: ['w-full'],
    }"
    name="search"
    class="w-full"
    type="text"
    :sizes="SIZES.MEDIUM"
    :placeholder="props.placeholder"
    @update:model-value="debouncedInput"
  >
    <template #prefix>
      <Search />
    </template>
    <template
      v-if="isSuffixShown"
      #suffix
    >
      <span class="text-imperium-fg-muted">
        {{ isMac() ? '⌘+K' : 'Ctrl+K' }}
      </span>
    </template>
  </FormInput>
</template>
<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import Search from '@/assets/icons/search.svg?component';
import FormInput from '@/components/FormInput.vue';
import { isMac } from '@/utils';
import { SIZES } from '@/types';

const props = withDefaults(
  defineProps<{
    placeholder: string;
    value: string;
    isSuffixShown: boolean;
  }>(),
  {
    placeholder: 'Search',
    value: '',
    isSuffixShown: false,
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

let timeoutId: number | ReturnType<typeof setTimeout> | undefined;

const debouncedInput = (value: string) => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    emits('update:modelValue', value);
  }, 1000) as unknown as number;
};

const SearchInput = ref<HTMLElement | null>(null);

const handleKeyDown = (event: KeyboardEvent) => {
  if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
    event.preventDefault();
    if (SearchInput.value) {
      SearchInput.value.focus();
    }
  }
};

onMounted(() => {
  if (props.isSuffixShown) window.addEventListener('keydown', handleKeyDown);
});
onBeforeUnmount(() => {
  if (props.isSuffixShown) window.removeEventListener('keydown', handleKeyDown);
});
</script>
