<template>
  <div class="flex flex-col mt-2">
    <div class="flex mb-4 justify-between full-width">
      <Search
        class="md:max-w-[300px]"
        is-suffix-shown
        placeholder="Search for labels"
        :value="searchQuery"
        @update:modelValue="(value) => updateSearchQuery(value)"
      />
      <div>
        <Button
          :size="SIZES.MEDIUM"
          data-testid="create-new-role-modal-show"
          @click="goToLabelCreate"
        >
          <template #leftIcon>
            <PlusIcon class="w-5 h-5" />
          </template>

          New label
        </Button>
      </div>
    </div>

    <LabelsTable @redirect="goToLabelEdit" />
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import Button from '@/components/Button.vue';
import { SIZES } from '@/types';

import PlusIcon from '@/assets/icons/plus.svg?component';
import Search from '@/components/Table/Filters/Search.vue';

import LabelsTable from '@/features/Labels/components/LabelsTable.vue';
import { computed } from 'vue';
import { debounce } from 'lodash';
import { useLabelsFilters } from '@/features/Labels/stores/filters.store.ts';

const router = useRouter();

const goToLabelEdit = (roleId: number) => {
  router.push({
    name: 'labels-edit',
    params: {
      id: roleId,
    },
  });
};

const goToLabelCreate = () => {
  router.push({
    name: 'labels-create',
  });
};

const filtersStore = useLabelsFilters();

const searchQuery = computed(() => filtersStore.state?.searchQuery || '');
const updateSearchQuery = debounce((searchQuery: string) => {
  filtersStore.setSearchQuery(searchQuery);
}, 300);
</script>
