import type { NavigationGuard } from 'vue-router';
import { isAuthenticatedByStore } from '@/services/auth';
import { useLanguagesStore } from '@/stores/languages.store.ts';
import { computed } from 'vue';
import type { Language } from '@/types';
import { useArticleCurrentLanguageStore } from '@/stores/article-current-language.ts';

export const authGuard: NavigationGuard = async (to, from, next) => {
  const redirectedFrom = !isAuthenticatedByStore().value && to.redirectedFrom ? to.redirectedFrom.fullPath : null;

  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);

  if (requiresGuest && isAuthenticatedByStore().value) {
    next({ path: '/' });
    return;
  }

  if (!isAuthenticatedByStore().value && redirectedFrom) {
    sessionStorage.setItem('prevPath', redirectedFrom);
  }

  if (!to.meta.auth) {
    next();
    return;
  }

  if (to.query && 'language' in to.query && to.query.language) {
    const languagesStore = useLanguagesStore();
    const articleLanguageStore = useArticleCurrentLanguageStore();
    const languageIdFromQuery = to.query.language;
    if (languagesStore.state && languagesStore.state.length) {
      const languageFullData = computed(() =>
        languagesStore.state.find((lang: Language) => lang.id === Number(languageIdFromQuery)),
      );

      if (languagesStore.state?.length && languageIdFromQuery) {
        const languageFullData = languagesStore.state.find((lang: Language) => lang.id === Number(languageIdFromQuery));

        if (languageFullData) {
          articleLanguageStore.updateLanguage(languageFullData);
        }
      }
    }
  }

  if (!isAuthenticatedByStore().value) {
    next({ name: 'Login' });
    return;
  }

  if (isAuthenticatedByStore().value && sessionStorage.getItem('prevPath')) {
    next({ path: sessionStorage.getItem('prevPath') });
    sessionStorage.clear();
    return;
  } else if (isAuthenticatedByStore().value) {
    next();
    return;
  }

  next({ name: 'Login' });
  return;
};
