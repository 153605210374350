import { toTypedSchema } from '@vee-validate/zod';
import zod from 'zod';
import {
  TAG_FIELD_TITLE,
  TAG_FIELD_ID,
  TAG_FIELD_DESCRIPTION,
  TAG_FIELD_SLUG,
  TAG_FIELD_AVATAR,
  TAG_FIELD_SUPER,
  TAG_FIELD_META_TITLE,
  TAG_FIELD_META_DESCRIPTION,
  TAG_FIELD_PAGE_TITLE,
} from '@/features/Tags/constants';

const tagFormSchema = zod.object({
  [TAG_FIELD_ID]: zod.number().nullable().optional(),
  [TAG_FIELD_TITLE]: zod.string().min(1, 'This is a mandatory field.'),
  [TAG_FIELD_SLUG]: zod.string().min(1, 'This is a mandatory field.'),
  [TAG_FIELD_AVATAR]: zod.union([zod.string().nullable().optional(), zod.instanceof(File).optional()]),
  [TAG_FIELD_DESCRIPTION]: zod.string().nullable().optional(),
  [TAG_FIELD_SUPER]: zod.boolean().nullable().optional(),
  [TAG_FIELD_META_DESCRIPTION]: zod.string().nullable().optional(),
  [TAG_FIELD_META_TITLE]: zod.string().nullable().optional(),
  [TAG_FIELD_PAGE_TITLE]: zod.string().nullable().optional(),
});

export const tagsFormSchema: unknown = toTypedSchema(tagFormSchema);
