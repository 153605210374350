import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M16.667 4.167a2.5 2.5 0 0 0-2.5-2.5H5.833a2.5 2.5 0 0 0-2.5 2.5v14.396c0 .223.27.335.427.177l5.945-5.945a.417.417 0 0 1 .59 0l5.945 5.945a.25.25 0 0 0 .427-.177zM15 15.143V4.167a.833.833 0 0 0-.833-.834H5.833A.833.833 0 0 0 5 4.167v10.976l3.527-3.527a2.083 2.083 0 0 1 2.946 0z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }