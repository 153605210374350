<template>
  <div>
    <Teleport to="#modals">
      <UnsavedChangesModal
        :is-visible="isCloseModalVisible"
        @close="isCloseModalVisible = false"
        @continue="onContinueModal"
        @save="onSaveModal"
      />
    </Teleport>

    <SlidePanel
      :id="`${props.id}-modal`"
      :data-testid="`${props.id}-modal`"
      :is-opened="props.isOpened"
      @close="onClose"
    >
      <div
        v-if="usersStore.isEditedUserLoading"
        class="max-w-[100vw] min-h-[60vh] flex items-center justify-center"
      >
        <div class="h-10 w-10">
          <LoaderIcon class="w-10 h-10" />
        </div>
      </div>
      <div
        v-else
        class="py-4 h-[calc(100%-30px)]"
      >
        <UserInfoEdit
          v-if="isEdit"
          :is-loading="saveIsLoading"
          :user-data="usersStore.state.editedUser"
          @cancel-edit="onStopEditing"
          @close="emits('close')"
          @save="onSaveUserInfo"
          @update-data="onUpdateData"
          @refetch-user="refetchUser"
        />
        <UserInfoView
          v-else
          :user-data="usersStore.state.editedUser"
          @edit="isEdit = true"
          @deactivate="emits('deactivate')"
          @restore="emits('restore')"
        />
      </div>
      <template #title>
        {{ isEdit ? 'Editing profile' : 'Profile settings' }}
      </template>
    </SlidePanel>
  </div>
</template>

<script lang="ts" setup>
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import SlidePanel from '@/components/SlidePanel.vue';
import UserInfoView from '@/features/Users/components/UserInfo/UserInfoView.vue';
import UserInfoEdit from '@/features/Users/components/UserInfo/UserInfoEdit.vue';
import { ref, type Ref, watchEffect } from 'vue';
import { UsersService } from '@/features/Users/service';
import type { User } from '@/features/Users/types';
import { useSimpleAction } from '@/composables';
import UserEditErrorToast from '@/features/Users/components/UserInfo/toasts/UserEditErrorToast.vue';
import { useToast } from '@/composables/useToast';
import UnsavedChangesModal from '@/features/Users/components/UserInfo/UnsavedChangesModal.vue';
import isEqual from 'lodash/isEqual';
import type { Language, Role } from '@/types';
import type { FormErrors } from 'vee-validate';
import { useUserFilters } from '@/features/Users/stores/filters.store';
import { usePagination } from '@/features/Users/stores/pagination.store';
import { useUsersPage } from '@/features/Users/stores/users.store';
import { useUserStore } from '@/stores/user.store.ts';

const props = withDefaults(
  defineProps<{
    id: number;
    isOpened: boolean;
  }>(),
  {
    isOpened: false,
  },
);

const emits = defineEmits<{
  (event: 'close'): void;
  (event: 'deactivate'): void;
  (event: 'restore'): void;
  (event: 'onUpdateData'): void;
  (event: 'refetch-user'): void;
}>();

const isCloseModalVisible = ref<boolean>(false);
const updatedUserData = ref<User>();
const updatedUserDataErrors = ref<Ref<FormErrors<User>>>();
const isEdit = ref<boolean>(false);

const toast = useToast();

const userStore = useUserStore();

const filtersStore = useUserFilters();
const paginationStore = usePagination();
const usersStore = useUsersPage();

const { isLoading: saveIsLoading, action: saveAction } = useSimpleAction(async () => {
  try {
    if (updatedUserData?.value?.id) {
      const patchData = {
        status: updatedUserData.value.status,
        name: updatedUserData.value.name,
        avatar: updatedUserData.value?.avatar,
        email: updatedUserData.value.email,
        facebook: updatedUserData.value.facebook,
        twitter: updatedUserData.value.twitter,
        telegram: updatedUserData.value.telegram,
        languages: updatedUserData.value?.languages,
        roles: updatedUserData.value?.roles,
        shift: updatedUserData.value?.shift,
      };

      await UsersService.edit(updatedUserData.value.id, patchData);
      if (updatedUserData.value.id === userStore.state.id) {
        await userStore.fetchUserData();
      }
    } else {
      throw new Error();
    }
    toast.success({ id: 'SUCCESS_UPDATE_USER', message: 'Profile settings edited successfully.' });
  } catch (err) {
    console.error(err);
    toast.errorTemporary({ id: 'ERROR_UPDATE_USER' }, UserEditErrorToast);
    throw err;
  }

  await usersStore.fetchEditingUser(updatedUserData.value.id);
  await usersStore.fetchUsersList({ filtersStore, paginationStore });
});

watchEffect(async () => {
  if (props.id && props.isOpened) {
    await usersStore.fetchEditingUser(props.id);
  }
});
const refetchUser = async () => {
  emits('refetch-user');
};
const onContinueModal = () => {
  onClose(false);
  isCloseModalVisible.value = false;
  isEdit.value = false;
};

const onSaveModal = async () => {
  isCloseModalVisible.value = false;
  if (!Object.keys(updatedUserDataErrors.value?.value).length) {
    await onSave();
    onClose(false);
  } else {
    toast.warning({ id: 'WARNING_UPDATE_USER', message: 'Cannot save invalid user info. Please fix invalid fields.' });
  }
};

const onSave = async () => {
  await saveAction();
  isEdit.value = false;
};

const onUpdateData = async (user: User, errors: Ref<FormErrors<typeof user>>) => {
  emits('onUpdateData');
  updatedUserData.value = user;
  updatedUserDataErrors.value = errors;
};

const onCancelEdit = (): boolean => {
  emits('onUpdateData');
  const initialData = { ...usersStore.state.editedUser };
  initialData.roles = initialData.roles.map((item: Role) => (item?.id ? item.id : item));
  initialData.languages = initialData.languages.map((item: Language) => (item?.id ? item.id : item));

  if (!isEqual(updatedUserData.value, initialData) && isEdit.value === true) {
    isCloseModalVisible.value = true;

    return true;
  }

  return false;
};

const onStopEditing = () => {
  const wasEditing = onCancelEdit();
  if (wasEditing) return;

  isEdit.value = false;
};

const onSaveUserInfo = async () => {
  await onSave();
  onClose(false);
};

const onClose = (check: boolean = true) => {
  if (check) {
    const wasEditing = onCancelEdit();
    if (wasEditing) return;
  }
  usersStore.cleanEditingUser();
  isEdit.value = false;
  emits('close');
};
</script>
