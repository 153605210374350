<template>
  <tr>
    <td
      v-for="(column, colIndex) in columnsArray"
      :key="colIndex"
      class="px-6 whitespace-nowrap text-sm text-gray-900"
      :class="{
        'py-4': size === 'medium',
        'py-2': size === 'small',
      }"
    >
      <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5" />
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { SIZES } from '@/types';

interface Props {
  columns: number;
  size: 'small' | 'medium';
}

const props = withDefaults(defineProps<Props>(), {
  columns: 10,
  size: 'medium',
});

const columnsArray = computed(() => {
  return Array.from({ length: props.columns });
});
</script>
