<template>
  <FormSearchMultiDropdown
    :model-value="props.modelValue?.sort((a, b) => a.id - b.id) || []"
    :name="props.name"
    is-handle-search-input
    class="mb-2"
    :attrs="props.attrs"
    :is-loading="isLoading"
    :is-disabled="props.isDisabled"
    placeholder="Type to search"
    :return-full-value="true"
    :size="SIZES.SMALL"
    :values="tagsVisibleList"
    :is-errored="props.isErrored"
    @update:model-value="handleUpdate"
    @search="
      (searchString: string) => {
        isLoading = true;
        debouncedOnSearch(searchString);
      }
    "
  >
    <template #label>
      <slot name="label" />
    </template>

    <template #error>
      <slot name="error" />
    </template>

    <template #help>
      <slot name="help" />
    </template>
  </FormSearchMultiDropdown>
</template>
<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';

import type { ArticleTagsFetchRequest, Tag } from '@/features/ArticleLayout/types';
import { fetchTags } from '@/features/ArticleLayout/api';

import FormSearchMultiDropdown, { type SearchMultidropdownItem } from '@/components/FormSearchMultiDropdown.vue';

import { SIZES, SortStatus } from '@/types';
import { debounce } from 'lodash';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import { useArticleCurrentLanguageStore } from '@/stores/article-current-language.ts';

const props = defineProps<{
  modelValue: SearchMultidropdownItem[];
  initValues: SearchMultidropdownItem[];
  attrs: Record<string, unknown>;
  isErrored: boolean;
  isDisabled?: boolean;
  name: string;
  isSuper: boolean;
  withoutSuperFilter?: boolean;
}>();

const emits = defineEmits<{
  (event: 'update:model-value', value: number[] | SearchMultidropdownItem[]): void;
}>();

const currentLanguageStore = useArticleCurrentLanguageStore();
const languageId = computed(() => currentLanguageStore.state.id);

const tags = ref<SearchMultidropdownItem[]>([]);
const selectedTags = ref<SearchMultidropdownItem[]>([]);
const isLoading = ref<boolean>(false);

watchEffect(() => {
  isLoading.value = false;
  tags.value = props.initValues;
  selectedTags.value = props.modelValue;
});

const onSearch = async (searchString: string) => {
  const payload: ArticleTagsFetchRequest = {
    languageId: languageId.value,
    'filter[super]': props.isSuper,
    'order[popular]': SortStatus.DESC,
  };

  if (props.withoutSuperFilter) {
    delete payload['filter[super]'];
  }

  if (searchString) {
    payload['filter[search]'] = searchString;
    payload.pagination = false;
  } else {
    payload.pagination = !props.isSuper;
    if (!props.isSuper) {
      payload.itemsPerPage = props.isSuper ? 20 : 10;
      payload.page = 1;
    }
  }

  try {
    const data = await fetchTags(payload);
    tags.value = data?.items.map((tagTranslate: Tag) => ({
      id: tagTranslate.id,
      label: tagTranslate.title,
    }));
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};

const tagsVisibleList = computed(() => {
  return tags.value?.filter((tag) => !selectedTags.value?.find((selectedTag) => tag.id === selectedTag.id)) || [];
});

const handleUpdate = (value) => {
  selectedTags.value = value;
  emits('update:model-value', value);
};

const debouncedOnSearch = debounce(onSearch, 500);
</script>
