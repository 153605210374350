import { ref, computed } from 'vue';
import { useFetchUsers } from '@/features/Users/queries';
import { type UsersFetchPayload } from '@/features/Users/types';
import { mapUsersToSelectItem } from '@/features/Users/utils';
import { ROLES } from '@/types';
import { useFetchRoles } from '@/features/Roles/queries';
import * as Sentry from '@sentry/vue';

export const useUsers = (immediate: boolean = true) => {
  const { data: roles } = useFetchRoles();

  const usersSearch = ref('');
  const isLoading = ref(false);

  const getWriterRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_WRITER).id) ?? null;
  const getSeniorWriterRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_SENIOR_WRITER).id) ?? null;
  const getEditorRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_EDITOR).id) ?? null;
  const getCopyEditorRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_COPY_EDITOR).id) ?? null;
  const getArtEditorRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_ART_EDITOR).id) ?? null;
  const getEditorChiefRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_EDITOR_IN_CHIEF).id) ?? null;
  const getSuperAdminRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_SUPER_ADMIN).id) ?? null;

  const fetchUserParams = computed<UsersFetchPayload>(() => ({
    page: 1,
    itemsPerPage: 15,
    filters: {
      search: usersSearch.value,
      roles: [
        getCopyEditorRoleId.value,
        getWriterRoleId.value,
        getEditorRoleId.value,
        getSeniorWriterRoleId.value,
        getArtEditorRoleId.value,
        getEditorChiefRoleId.value,
        getSuperAdminRoleId.value,
      ],
    },
  }));
  const { data: result, refetch } = useFetchUsers(fetchUserParams, immediate);

  const fetchUsers = async (search?: string) => {
    if (search) {
      usersSearch.value = search;
    }

    isLoading.value = true;
    try {
      await refetch();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      isLoading.value = false;
    }

    return users.value;
  };

  const users = computed(() => {
    return mapUsersToSelectItem(result.value?.items) || [];
  });

  return {
    users,
    isLoading,
    fetchUsers,
  };
};
