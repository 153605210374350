<template>
  <div class="flex flex-col">
    <div class="flex mb-4">
      <Search
        class="md:max-w-[300px]"
        is-suffix-shown
        :placeholder="t('tags.search-placeholder')"
        :value="searchQuery"
        @update:modelValue="(value) => updateSearchQuery(value)"
      />

      <div class="flex gap-2 ml-auto">
        <Button
          v-if="tagsPageView"
          data-testid="create-new-author-modal-show"
          :size="SIZES.MEDIUM"
          :disabled="!tagsPageEdit"
          @click="router.push({ path: '/tags/create' })"
        >
          <template #leftIcon>
            <PlusIcon class="w-5 h-5" />
          </template>
          {{ t('tags.new-tag') }}
        </Button>
      </div>
    </div>

    <TagsTable />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useUserPermissions } from '@/stores/user.store';
import { useTagsFilters } from '@/features/Tags/stores/filters.store';
import Button from '@/components/Button.vue';
import { SIZES } from '@/types';
import Search from '@/components/Table/Filters/Search.vue';
import PlusIcon from '@/assets/icons/plus.svg?component';
import TagsTable from '@/features/Tags/components/TagsTable.vue';

const { tagsPageView, tagsPageEdit } = useUserPermissions();

const { t } = useI18n();
const router = useRouter();

const filtersStore = useTagsFilters();

const searchQuery = computed(() => filtersStore.state?.searchQuery || '');

const updateSearchQuery = (searchQuery: string) => {
  filtersStore.setSearchQuery(searchQuery);
};
</script>
