import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#10171B",
      "fill-rule": "evenodd",
      d: "M8.75 7.917a2.917 2.917 0 1 1-5.834 0 2.917 2.917 0 0 1 5.833 0m-1.667 0a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#10171B",
      d: "M10.251 14.61c.056.2-.102.39-.31.39H8.88c-.254 0-.474-.168-.587-.395a2.75 2.75 0 0 0-4.922 0c-.114.227-.333.395-.587.395h-1.06c-.208 0-.366-.19-.31-.39a4.585 4.585 0 0 1 8.836 0m1.417-8.36c0-.23.186-.417.416-.417h5.834c.23 0 .416.187.416.417v.833c0 .23-.186.417-.416.417h-5.834a.417.417 0 0 1-.416-.417zm0 3.333c0-.23.186-.416.416-.416h2.5c.23 0 .417.186.417.416v.834c0 .23-.187.416-.417.416h-2.5a.417.417 0 0 1-.416-.416zm.416 2.917a.417.417 0 0 0-.416.417v.833c0 .23.186.417.416.417h4.167c.23 0 .417-.187.417-.417v-.833a.417.417 0 0 0-.417-.417z"
    }, null, -1)
  ])))
}
export default { render: render }