<script setup lang="ts">
import type { Post } from '@/features/Posts/types';
import LinkWithIcon from '@/components/LinkWithIcon.vue';
import { computed } from 'vue';

interface Published {
  date: string;
  time: string;
}
const props = defineProps<{
  post: Post;
}>();

const formatPublishedAt = computed<string | Published>(() => {
  if (!props.post.publishedAt) return;
  const now = new Date();
  const publishedDate = new Date(props.post.publishedAt);
  const diffInMilliseconds = now.getTime() - publishedDate.getTime();
  const diffInMinutes = diffInMilliseconds / (1000 * 60);
  const diffInHours = diffInMinutes / 60;

  if (diffInMinutes < 1) {
    return 'Just now';
  } else if (diffInMinutes < 60) {
    const minutesAgo = Math.floor(diffInMinutes);
    return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
  } else if (diffInHours < 24) {
    const hoursAgo = Math.floor(diffInHours);
    return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
  } else {
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedDate = new Intl.DateTimeFormat('en', dateOptions).format(publishedDate);
    const formattedTime = new Intl.DateTimeFormat('en', timeOptions).format(publishedDate);
    return { date: formattedDate, time: formattedTime };
  }
});

const publishedAt = computed(() => {
  if (!formatPublishedAt.value) return;

  if (typeof formatPublishedAt.value === 'string') {
    return formatPublishedAt.value;
  } else {
    return formatPublishedAt.value.date;
  }
});

const publishedTime = computed(() => {
  if (typeof formatPublishedAt.value === 'object') return formatPublishedAt.value.time;
});
</script>

<template>
  <div class="border-imperium-border-clear border rounded-xl p-2 pr-3 flex">
    <slot name="iconLeft" />
    <div class="flex flex-col mx-3">
      <div class="title-section mb-1.5">
        <span class="break-words">{{ props.post.title }}</span>
      </div>
      <div class="flex flex-wrap items-center gap-1">
        <span class="text-imperium-fg-default">{{ publishedAt }}</span>
        <span
          v-if="publishedAt && publishedTime"
          class="mx-1"
        >
          &middot;
        </span>
        <span
          v-if="publishedTime"
          class="text-imperium-fg-default"
        >
          {{ publishedTime }}
        </span>
        <span
          v-if="publishedTime || publishedAt"
          class="mx-1"
        >
          &middot;
        </span>
        <span class="text-imperium-fg-default">{{ props.post.categoryTitle }}</span>
        <span class="mx-1">&middot;</span>
        <LinkWithIcon :url="props.post.url ?? props.post.postUrl" />
      </div>
    </div>
    <slot name="iconRight" />
  </div>
</template>

<style scoped lang="scss">
.title-section {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
