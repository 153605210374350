<template>
  <div class="flex items-center gap-2 pr-2">
    <template v-if="isNew">
      <SpikeButton
        v-if="documentStatusToSpikedView"
        :is-loading="isLoadingStatus === DocumentStatus.SPIKED"
        @click="emits('update:modelValue', DocumentStatus.SPIKED)"
      />

      <PermissionTooltip
        key="tooltip-start-button"
        :tooltip-visible="!documentStatusToWorkEdit && !documentStatusToWorkCondition"
      >
        <Button
          v-if="documentStatusToWorkView"
          :is-disabled="
            (!documentStatusToWorkEdit && !documentStatusToWorkCondition) || !userStore.hasAccessToCurrentLanguage()
          "
          :is-loading="isLoadingStatus === DocumentStatus.IN_PROGRESS"
          @click="emits('update:modelValue', DocumentStatus.IN_PROGRESS)"
        >
          Start work
        </Button>
      </PermissionTooltip>
    </template>

    <template v-if="isInProgress">
      <ButtonSplitDropdown
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        :is-dropdown-shown="documentStatusToSpikedView"
        :is-dropdown-opened="isButtonSplitDropdownOpened"
        @click-dropdown="isButtonSplitDropdownOpened = true"
      >
        <template #mainButton>
          <Button
            :visual-type="BUTTON_TYPE.PRIMARY"
            :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
            :color="BUTTON_COLOR.PRIMARY"
            :is-loading="isLoadingStatus === DocumentStatus.NEW"
            :is-disabled="!userStore.hasAccessToCurrentLanguage()"
            :rounded="BUTTON_ROUNDED.NONE"
            class="rounded-tl-lg rounded-bl-lg"
            @click="emits('update:modelValue', DocumentStatus.NEW)"
          >
            Stop work
          </Button>
        </template>
        <template #menu>
          <SpikeButton
            :is-loading="isLoadingStatus === DocumentStatus.SPIKED"
            @click="emits('update:modelValue', DocumentStatus.SPIKED)"
          />
        </template>
      </ButtonSplitDropdown>

      <PermissionTooltip
        key="tooltip-finish-text-button-progress"
        :tooltip-visible="!documentStatusToFinishEdit && !finishTextCondition"
      >
        <Button
          v-if="documentStatusToFinishView"
          :is-disabled="
            (!documentStatusToFinishEdit && !finishTextCondition) || !userStore.hasAccessToCurrentLanguage()
          "
          :is-loading="isLoadingStatus === DocumentStatus.READY_TO_REVIEW"
          @click="emits('update:modelValue', DocumentStatus.READY_TO_REVIEW)"
        >
          Finish text
        </Button>
      </PermissionTooltip>
    </template>

    <template v-if="isCopyEditorAndNeedsProofreading && isApproveButtonVisible">
      <Button
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        :is-disabled="!userStore.hasAccessToCurrentLanguage()"
        @click="onApproveByCopyEditor"
      >
        Approve
      </Button>
    </template>

    <template v-if="isReadyToReview">
      <ButtonSplitDropdown
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        :is-dropdown-shown="documentStatusToSpikedView"
        :is-dropdown-opened="isButtonSplitDropdownOpened"
        @click-dropdown="isButtonSplitDropdownOpened = true"
      >
        <template #mainButton>
          <PermissionTooltip
            v-if="documentStatusToBackToWorkView"
            key="tooltip-reject-text"
            :tooltip-visible="!documentStatusToBackToWorkEdit && !documentStatusToBackToWorkCondition"
          >
            <Button
              :class="documentStatusToSpikedView ? 'rounded-tl-lg rounded-bl-lg' : 'rounded'"
              :rounded="BUTTON_ROUNDED.NONE"
              :is-loading="isLoadingStatus === DocumentStatus.IN_PROGRESS"
              :is-disabled="
                (!documentStatusToBackToWorkEdit && !documentStatusToBackToWorkCondition) ||
                !userStore.hasAccessToCurrentLanguage()
              "
              :visual-type="BUTTON_TYPE.PRIMARY"
              :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
              :color="BUTTON_COLOR.PRIMARY"
              @click="emits('update:modelValue', DocumentStatus.IN_PROGRESS)"
            >
              Back to work
            </Button>
          </PermissionTooltip>
        </template>
        <template #menu>
          <SpikeButton
            v-if="documentStatusToSpikedView"
            class="rounded-br-lg rounded-bl-lg"
            :rounded="BUTTON_ROUNDED.NONE"
            :is-loading="isLoadingStatus === DocumentStatus.TEXT_REJECTED"
            @click="emits('update:modelValue', DocumentStatus.SPIKED)"
          />
        </template>
      </ButtonSplitDropdown>

      <SpikeButton
        v-if="!documentStatusToRejectTextView && !documentStatusToFinishArtworkView && documentStatusToSpikedView"
        :is-loading="isLoadingStatus === DocumentStatus.TEXT_REJECTED"
        @click="emits('update:modelValue', DocumentStatus.SPIKED)"
      />

      <PermissionTooltip
        key="tooltip-finish-review"
        :tooltip-visible="!documentStatusToFinishReviewEdit && !documentStatusToFinishReviewCondition"
      >
        <Button
          v-if="documentStatusToFinishReviewView"
          :is-loading="isLoadingStatus === DocumentStatus.READY_TO_PUBLISH"
          :is-disabled="
            (!documentStatusToFinishReviewEdit && !documentStatusToFinishReviewCondition) ||
            !userStore.hasAccessToCurrentLanguage()
          "
          @click="emits('update:modelValue', DocumentStatus.READY_TO_PUBLISH)"
        >
          Finish review
        </Button>
      </PermissionTooltip>
    </template>

    <template v-if="isTextRejected && documentStatusToFinishView">
      <SpikeButton
        v-if="documentStatusToSpikedView"
        :is-loading="isLoadingStatus === DocumentStatus.SPIKED"
        @click="emits('update:modelValue', DocumentStatus.SPIKED)"
      />

      <PermissionTooltip
        key="tooltip-finish-text-button"
        :tooltip-visible="!documentStatusToFinishEdit && !finishTextCondition"
      >
        <Button
          :is-loading="isLoadingStatus === DocumentStatus.READY_TO_REVIEW"
          :is-disabled="
            (!documentStatusToFinishEdit && !finishTextCondition) || !userStore.hasAccessToCurrentLanguage()
          "
          @click="emits('update:modelValue', DocumentStatus.READY_TO_REVIEW)"
        >
          Finish text
        </Button>
      </PermissionTooltip>
    </template>

    <template v-if="isReadyToPublish">
      <SpikeButton
        v-if="documentStatusToSpikedView"
        :is-loading="isLoadingStatus === DocumentStatus.SPIKED"
        @click="emits('update:modelValue', DocumentStatus.SPIKED)"
      />

      <PermissionTooltip
        key="tooltip-publish-1-button"
        :tooltip-visible="!documentStatusToPublishEdit && !publishCondition"
      >
        <Button
          v-if="documentStatusToPublishView"
          :is-loading="isLoadingStatus === DocumentStatus.PUBLISHED"
          :is-disabled="(!documentStatusToPublishEdit && !publishCondition) || !userStore.hasAccessToCurrentLanguage()"
          @click="revealPublish"
        >
          Publish
        </Button>
      </PermissionTooltip>
    </template>

    <template v-if="isPublished">
      <ButtonSplitDropdown
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        :is-dropdown-opened="isButtonSplitDropdownOpened"
        :is-dropdown-shown="documentMetadataOnlyCoverUpdateView || documentStatusToSpikedView"
        @click-dropdown="isButtonSplitDropdownOpened = true"
      >
        <template #mainButton>
          <Button
            v-if="documentStatusToUpdateView"
            class="rounded-tl-lg rounded-bl-lg"
            :rounded="BUTTON_ROUNDED.NONE"
            :visual-type="BUTTON_TYPE.PRIMARY"
            :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
            :color="BUTTON_COLOR.PRIMARY"
            :is-loading="isLoadingStatus === DocumentStatus.PUBLISHED"
            :is-disabled="
              (!documentStatusToUpdateEdit && !documentStatusToUpdateCondition) ||
              !userStore.hasAccessToCurrentLanguage()
            "
            @click="revealUpdate"
          >
            Update article
          </Button>
          <Button
            v-else
            class="rounded-tl-lg rounded-bl-lg"
            :rounded="BUTTON_ROUNDED.NONE"
            :visual-type="BUTTON_TYPE.PRIMARY"
            :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
            :color="BUTTON_COLOR.PRIMARY"
            :is-loading="isLoadingStatus === DocumentStatus.UNPUBLISHED"
            :is-disabled="!userStore.hasAccessToCurrentLanguage()"
            @click="revealUnpublish"
          >
            Unpublish
          </Button>
        </template>
        <template #menu>
          <Button
            v-if="documentStatusToUpdateView"
            class="rounded-tl-lg rounded-tr-lg"
            :class="{
              'rounded-bl-lg rounded-br-lg': !documentStatusToSpikedView && !documentMetadataOnlyCoverUpdateView,
            }"
            is-full-width
            :visual-type="BUTTON_TYPE.GHOST"
            :rounded="BUTTON_ROUNDED.NONE"
            :is-loading="isLoadingStatus === DocumentStatus.UNPUBLISHED"
            :is-disabled="!userStore.hasAccessToCurrentLanguage()"
            @click="revealUnpublish"
          >
            Unpublish
          </Button>
          <Button
            v-if="documentMetadataOnlyCoverUpdateView"
            is-full-width
            :visual-type="BUTTON_TYPE.GHOST"
            :rounded="BUTTON_ROUNDED.NONE"
            :class="{
              'rounded-tl-lg rounded-tr-lg': !documentStatusToUpdateView,
              'rounded-bl-lg rounded-br-lg': !documentStatusToSpikedView,
            }"
            @click="
              emits('updateOnlyCover');
              isButtonSplitDropdownOpened = false;
            "
          >
            Update cover image
          </Button>
          <SpikeButton
            v-if="documentStatusToSpikedView"
            :class="{
              'rounded-tl-lg rounded-tr-lg': !documentStatusToUpdateView && !documentMetadataOnlyCoverUpdateView,
            }"
            class="rounded-bl-lg rounded-br-lg"
            :rounded="BUTTON_ROUNDED.NONE"
            :is-loading="isLoadingStatus === DocumentStatus.SPIKED"
            :is-disabled="true"
            @click="emits('update:modelValue', DocumentStatus.SPIKED)"
          />
        </template>
      </ButtonSplitDropdown>

      <PermissionTooltip
        key="tooltip-publish-button"
        :tooltip-visible="!documentStatusToPublishEdit && !publishCondition"
      >
        <Button
          v-if="documentStatusToPublishView && isArticleScheduled"
          :is-loading="isLoadingStatus === DocumentStatus.PUBLISHED"
          :is-disabled="(!documentStatusToPublishEdit && !publishCondition) || !userStore.hasAccessToCurrentLanguage()"
          @click="revealPublish"
        >
          Publish now
        </Button>
      </PermissionTooltip>
    </template>

    <template v-if="isUnpublished">
      <ButtonSplitDropdown
        v-if="documentStatusToBackToWorkView"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        :is-loading="isLoadingStatus === DocumentStatus.IN_PROGRESS"
        :is-disabled="
          (!documentStatusToBackToWorkEdit && !documentStatusToBackToWorkCondition) ||
          !userStore.hasAccessToCurrentLanguage()
        "
        :is-dropdown-shown="documentStatusToSpikedView"
        @click-dropdown="isButtonSplitDropdownOpened = true"
      >
        <template #mainButton>
          <PermissionTooltip
            key="tooltip-back-to-work"
            :tooltip-visible="!documentStatusToBackToWorkEdit && !documentStatusToBackToWorkCondition"
          >
            <Button
              :is-loading="isLoadingStatus === DocumentStatus.IN_PROGRESS"
              :is-disabled="
                (!documentStatusToBackToWorkEdit && !documentStatusToBackToWorkCondition) ||
                !userStore.hasAccessToCurrentLanguage()
              "
              :visual-type="BUTTON_TYPE.PRIMARY"
              :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
              :color="BUTTON_COLOR.PRIMARY"
              :rounded="BUTTON_ROUNDED.NONE"
              class="rounded-tl-lg rounded-bl-lg"
              @click="emits('update:modelValue', DocumentStatus.IN_PROGRESS)"
            >
              Back to work
            </Button>
          </PermissionTooltip>
        </template>
        <template #menu>
          <SpikeButton
            :is-loading="isLoadingStatus === DocumentStatus.SPIKED"
            @click="emits('update:modelValue', DocumentStatus.SPIKED)"
          />
        </template>
      </ButtonSplitDropdown>

      <SpikeButton
        v-if="!documentStatusToBackToWorkView && documentStatusToSpikedView"
        :is-loading="isLoadingStatus === DocumentStatus.SPIKED"
        @click="emits('update:modelValue', DocumentStatus.SPIKED)"
      />

      <PermissionTooltip
        key="tooltip-publish"
        :tooltip-visible="!documentStatusToPublishEdit && !publishCondition"
      >
        <Button
          v-if="documentStatusToPublishView"
          :is-loading="isLoadingStatus === DocumentStatus.PUBLISHED"
          :is-disabled="(!documentStatusToPublishEdit && !publishCondition) || !userStore.hasAccessToCurrentLanguage()"
          @click="revealPublish"
        >
          Publish
        </Button>
      </PermissionTooltip>
    </template>

    <template v-if="isSpiked">
      <Button
        v-if="documentStatusToSpikedView"
        :is-loading="isLoadingStatus === DocumentStatus.IN_PROGRESS"
        :is-disabled="
          (!documentStatusToSpikedEdit && !documentStatusToSpikedCondition) || !userStore.hasAccessToCurrentLanguage()
        "
        @click="emits('update:modelValue', DocumentStatus.IN_PROGRESS)"
      >
        Recover
      </Button>
    </template>

    <Teleport to="#modals">
      <component
        :is="isArticleEmbargoed && !isArticleScheduled ? PublishEmbargoedModal : PublishModal"
        v-if="isPublishRevealed"
        :is-visible="isPublishRevealed"
        :published-at="props.publishedAt"
        :is-publish-now="isPublishNow"
        @close="cancelPublish"
        @schedule="onConfirmSchedule"
        @publish="onConfirmPublish"
      />

      <UnpublishModal
        :is-visible="isUnpublishRevealed"
        :is-scheduled="isArticleScheduled"
        @close="cancelUnpublish"
        @confirm="onConfirmUnpublish"
      />

      <UpdateModal
        :is-visible="isUpdateRevealed"
        :is-published="isPublished && !isArticleScheduled"
        :can-be-republished="props.canBeRepublished"
        @close="cancelUpdate"
        @update="onConfirmUpdate"
      />
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import Button from '@/components/Button.vue';
import SpikeButton from '@/features/ArticleLayout/components/statusButtons/SpikeButton.vue';
import ButtonSplitDropdown from '@/components/ButtonSplitDropdown.vue';
import { useModal } from '@/composables/useModal';

import UnpublishModal from './modals/UnpublishModal.vue';
import PublishModal from './modals/PublishModal.vue';
import PublishEmbargoedModal from './modals/PublishEmbargoedModal.vue';
import UpdateModal from './modals/UpdateModal.vue';

import { useCurrentDocument } from '../composables';

import { BUTTON_COLOR, BUTTON_ON_SURFACE, BUTTON_TYPE, BUTTON_ROUNDED, DocumentStatus, ROLES } from '@/types';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import PermissionTooltip from '@/features/ArticleLayout/components/statusButtons/PermissionTooltip.vue';

const props = defineProps<{
  modelValue: DocumentStatus;
  publishedAt: string;
  hasPost: boolean;
  canBeRepublished: boolean;
  languageId: number;
  isLoadingStatus: DocumentStatus | null;
}>();

const emits = defineEmits<{
  (event: 'update:modelValue', status: DocumentStatus): void;
  (event: 'publish', articleUrl: string): void;
  (event: 'schedule', articleUrl: string, embargoedUntil: string | null, publishedAt: string | null): void;
  (event: 'update', isUpdatePublicationDate: boolean): void;
  (event: 'approve', field: string, value: boolean): void;
  (event: 'updateOnlyCover'): void;
}>();

const userStore = useUserStore();

const { data: articleData } = useCurrentDocument();
const articleStore = useArticleStore();

const user = computed(() => userStore.state);

const isButtonSplitDropdownOpened = ref(false);

const isCopyEditorAndNeedsProofreading = computed(
  () => user.value?.roles?.includes(ROLES.ROLE_COPY_EDITOR) && articleStore.state.needsProofreading,
);

const {
  documentStatusToFinishView,
  documentStatusToFinishEdit,
  documentStatusToFinishArtworkView,
  documentStatusToBackToWorkView,
  documentStatusToBackToWorkEdit,
  documentStatusToRejectTextView,
  documentStatusToFinishReviewView,
  documentStatusToFinishReviewEdit,
  documentStatusToPublishView,
  documentStatusToPublishEdit,
  documentStatusToUpdateView,
  documentStatusToUpdateEdit,
  documentStatusToWorkView,
  documentStatusToWorkEdit,
  documentMetadataOnlyCoverUpdateView,
  documentStatusToSpikedView,
  documentStatusToSpikedEdit,
} = useUserPermissions();

const {
  finishTextCondition,
  publishCondition,
  documentStatusToFinishReviewCondition,
  documentStatusToWorkCondition,
  documentStatusToBackToWorkCondition,
  documentStatusToUpdateCondition,
  documentStatusToSpikedCondition,
} = useUserConditions();

const {
  reveal: revealUnpublish,
  cancel: cancelUnpublish,
  confirm: confirmUnpublish,
  isRevealed: isUnpublishRevealed,
} = useModal();

const {
  reveal: revealPublish,
  cancel: cancelPublish,
  confirm: confirmPublish,
  isRevealed: isPublishRevealed,
} = useModal();

const { reveal: revealUpdate, cancel: cancelUpdate, confirm: confirmUpdate, isRevealed: isUpdateRevealed } = useModal();

const isArticleScheduled = computed(() => {
  const currentDate = new Date();
  const publishDate = props.publishedAt ? new Date(props.publishedAt) : currentDate;

  return publishDate.getTime() > currentDate.getTime();
});

const isApproveButtonVisible = computed(
  () =>
    props.modelValue === DocumentStatus.PUBLISHED ||
    props.modelValue === DocumentStatus.READY_TO_REVIEW ||
    props.modelValue === DocumentStatus.READY_TO_PUBLISH ||
    isArticleScheduled.value,
);

const isArticleEmbargoed = computed(() => !!articleData.value?.embargoUntil);

const isNew = computed(() => props.modelValue === DocumentStatus.NEW);
const isInProgress = computed(() => props.modelValue === DocumentStatus.IN_PROGRESS);
const isReadyToPublish = computed(() => props.modelValue === DocumentStatus.READY_TO_PUBLISH);
const isReadyToReview = computed(() => props.modelValue === DocumentStatus.READY_TO_REVIEW);
const isPublished = computed(() => props.modelValue === DocumentStatus.PUBLISHED);
const isUnpublished = computed(() => props.modelValue === DocumentStatus.UNPUBLISHED);
const isTextRejected = computed(() => props.modelValue === DocumentStatus.TEXT_REJECTED);
const isSpiked = computed(() => props.modelValue === DocumentStatus.SPIKED);

const isPublishNow = computed(() => {
  return isPublished.value && isArticleScheduled.value;
});

const onConfirmSchedule = (articleUrl: string, embargoedUntil: string | null, publishedAt: string | null) => {
  confirmPublish();
  emits('schedule', articleUrl, embargoedUntil, publishedAt);
};

const onConfirmPublish = (articleUrl: string) => {
  confirmPublish();
  emits('publish', articleUrl);
};

const onConfirmUnpublish = () => {
  confirmUnpublish();
  emits('update:modelValue', DocumentStatus.UNPUBLISHED);
};

const onConfirmUpdate = (isUpdatePublicationDate: boolean) => {
  confirmUpdate();
  emits('update', isUpdatePublicationDate);
};

const onApproveByCopyEditor = (): void => {
  emits('approve', 'needsProofreading', false);
};
</script>
