import Heading2Icon from '@/assets/icons/editor/heading-2.svg?raw';
import Heading3Icon from '@/assets/icons/editor/heading-3.svg?raw';
import Heading4Icon from '@/assets/icons/editor/heading-4.svg?raw';

import { blockTypeItem } from '@/features/CollaborativeEditor/plugins/menu';

import type { NodeType } from 'prosemirror-model';
import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import { schema } from '@/features/CollaborativeEditor/schema';
import { getProseMirrorMenuTitle, ProseMirrorMenuLabel } from '@/features/CollaborativeEditor/helpers/menuTooltip';

export const buildHeadings = (node: NodeType): InstallableBlock[] => {
  const heading2IconNode = new DOMParser().parseFromString(Heading2Icon, 'text/html').body.firstElementChild;
  const heading3IconNode = new DOMParser().parseFromString(Heading3Icon, 'text/html').body.firstElementChild;
  const heading4IconNode = new DOMParser().parseFromString(Heading4Icon, 'text/html').body.firstElementChild;

  return [
    {
      key: 'makeHead2',
      item: blockTypeItem(schema.nodes.h2, {
        title: getProseMirrorMenuTitle(ProseMirrorMenuLabel.H2),
        icon: { dom: heading2IconNode as Node },
        render: () => {
          const buttonContainer = document.createElement('div');
          buttonContainer.id = 'make-head2-button';
          buttonContainer.className = 'ProseMirror-icon';

          if (heading2IconNode) {
            buttonContainer.appendChild(heading2IconNode.cloneNode(true));
          }

          return buttonContainer;
        },
        attrs: {},
      }),
    },
    {
      key: 'makeHead3',
      item: blockTypeItem(schema.nodes.h3, {
        title: getProseMirrorMenuTitle(ProseMirrorMenuLabel.H3),
        icon: { dom: heading3IconNode as Node },
        render: () => {
          const buttonContainer = document.createElement('div');
          buttonContainer.id = 'make-head3-button';
          buttonContainer.className = 'ProseMirror-icon';

          if (heading3IconNode) {
            buttonContainer.appendChild(heading3IconNode.cloneNode(true));
          }

          return buttonContainer;
        },
        attrs: {},
      }),
    },
    {
      key: 'makeHead4',
      item: blockTypeItem(schema.nodes.h4, {
        title: getProseMirrorMenuTitle(ProseMirrorMenuLabel.H4),
        icon: { dom: heading4IconNode as Node },
        render: () => {
          const buttonContainer = document.createElement('div');
          buttonContainer.id = 'make-head4-button';
          buttonContainer.className = 'ProseMirror-icon';

          if (heading4IconNode) {
            buttonContainer.appendChild(heading4IconNode.cloneNode(true));
          }

          return buttonContainer;
        },
        attrs: {},
      }),
    },
  ];
};
