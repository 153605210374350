import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { fetchLabels } from '@/features/Labels/api';
import type { ComputedRef } from 'vue';
import type { LabelsFetchRequest } from '@/features/Labels/types';
import { LABELS_RESOURCE } from '@/features/Labels/constants';
import { type LabelsFetchResponse } from '@/features/Labels/types';

type OnSuccessCallback = (result: LabelsFetchResponse) => void;
export const useFetchLabels = (params: ComputedRef<LabelsFetchRequest>, onSuccess: OnSuccessCallback | null = null) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [LABELS_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: LabelsFetchRequest = queryKey[1] as LabelsFetchRequest;

      const result = await fetchLabels(payload);

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    },

    gcTime: 0,
    staleTime: 0,
    refetchInterval: false,
    refetchOnMount: 'always',
    placeholderData: keepPreviousData,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};
