<template>
  <div>
    <div class="relative">
      <div
        class="relative bg-imperium-bg-sub-base"
        :class="{
          'rounded-2xl': !isMobile,
        }"
      >
        <div>
          <Table
            class="max-w-full relative overflow-x-auto"
            :top-rounded="!isMobile"
          >
            <TableHead>
              <TableHeadRow
                :size="SIZES.MEDIUM"
                :bordered="TABLE_BORDERED.BOTTOM"
                :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
              >
                <TableHeadCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :is-sticky="true"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="id"
                    class="p-2.5"
                    :label="t('authors.table.th-id')"
                    :value="filtersStore.state.sort?.id || SortStatus.DISABLE"
                    @change-sort="filtersStore.setSort('id', $event)"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="name"
                    :label="t('authors.table.th-name')"
                    :value="filtersStore.state.sort?.name || SortStatus.DISABLE"
                    @change-sort="filtersStore.setSort('name', $event)"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  {{ t('authors.table.th-about') }}
                </TableHeadCell>

                <!-- TODO: Update when translate arrive -->
                <!-- <TableHeadCell -->
                <!--   :size="SIZES.MEDIUM" -->
                <!--   :scale="TABLE_SCALE.DOUBLE" -->
                <!--   is-uppercase -->
                <!-- > -->
                <!--   {{ t('authors.table.th-translate') }} -->
                <!-- </TableHeadCell> -->
              </TableHeadRow>
            </TableHead>

            <TableBody>
              <div
                v-if="isFetching && !isLoading"
                class="w-full h-full flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <div class="h-10 w-10">
                  <LoaderIcon />
                </div>
              </div>
              <template v-if="isLoading">
                <TableRowSkeleton
                  v-for="item in new Array(paginationStore.state.limit)"
                  :key="item"
                  size="small"
                  :columns="3"
                />
              </template>
              <TableRow
                v-for="author in authors.items"
                v-else
                :key="author.id"
                :size="SIZES.MEDIUM"
                :striped="TABLE_STRIPED.STRIPED"
                :bordered="TABLE_BORDERED.BOTTOM"
                class="cursor-pointer"
                :class="{
                  'opacity-30': isFetching,
                }"
                @click="author.id && redirectToAuthor(author.id)"
              >
                <TableCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  :is-sticky="true"
                  is-uppercase
                >
                  <span class="text-imperium-fg-muted">{{ author.id }}</span>
                </TableCell>

                <TableCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  {{ author.name || '—' }}
                </TableCell>

                <TableCell
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="truncate font-semibold overflow-hidden text-ellipsis"
                  is-full-width
                  is-uppercase
                >
                  <Tooltip
                    :name="`author-description-${author.id}`"
                    width="320px"
                    placement="top"
                  >
                    <template #content>
                      <p class="truncate">{{ author.description || '—' }}</p>
                    </template>
                    <template #tooltip>
                      {{ author.description || '—' }}
                    </template>
                  </Tooltip>
                </TableCell>

                <!-- TODO: Update when translate arrive -->
                <!-- <TableCell -->
                <!--   :size="SIZES.MEDIUM" -->
                <!--   :scale="TABLE_SCALE.DOUBLE" -->
                <!--   :vertical-align="TABLE_VALIGN.TOP" -->
                <!--   class="truncate font-semibold overflow-hidden text-ellipsis" -->
                <!--   is-uppercase -->
                <!-- > -->
                <!--   languages -->
                <!-- </TableCell> -->
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div
          v-if="!paginationStore.state.total"
          class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
        >
          <div class="font-semibold text-imperium-fg-strong">{{ t('authors.no-authors-found') }}</div>
        </div>

        <div
          v-if="paginationStore.state.total"
          class="py-3 p-4 mt-1"
        >
          <Pagination
            :current-page="paginationStore.state.page"
            :is-mobile="isMobile || isTablet"
            :items-count="paginationStore.state.total"
            :limit="paginationStore.state.limit"
            @change-page="paginationStore.changePage"
            @change-limit="paginationStore.changeLimit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import LoaderIcon from '@/assets/icons/spinner.svg?component';

import { useAuthorsFilters } from '@/features/Authors/stores/filters.store';
import { useAuthorsPagination } from '@/features/Authors/stores/pagination.store';
import Pagination from '@/components/Table/Pagination/Pagination.vue';
import { useFetchAuthors } from '@/features/Authors/queries';

import { TABLE_VALIGN } from '@/types';

import type { AuthorsFetchRequest, AuthorsFetchResponse, AuthorsPayload } from '@/features/Authors/types';

import { TABLE_BORDERED, TABLE_ON_SURFACE, TABLE_STRIPED, TABLE_SCALE, SIZES, SortStatus } from '@/types';

import { Table, TableBody, TableHead, TableCell, TableHeadCell, TableRow, TableHeadRow } from '@/components/Table';
import SortColumnHeader from '@/components/Table/Filters/SortColumnHeader.vue';
import Tooltip from '@/components/Tooltip.vue';
import TableRowSkeleton from '@/components/Skeletons/TableRowSkeleton.vue';
import ConverterRow from '@/features/Converter/components/ConverterTable/ConverterRow.vue';

const { t } = useI18n();
const router = useRouter();

const isMobile = inject<boolean>('isMobile');
const isTablet = inject<boolean>('isTablet');

const filtersStore = useAuthorsFilters();
const paginationStore = useAuthorsPagination();

const filters = computed(() => ({
  searchQuery: filtersStore.state.searchQuery || '',
  sort: {
    id: (filtersStore.state.sort?.id as SortStatus) || SortStatus.DISABLE,
    name: (filtersStore.state.sort?.name as SortStatus) || SortStatus.DISABLE,
  },
}));

const authorsPayload = computed<AuthorsFetchRequest>(() => {
  const payload: AuthorsPayload = {
    languageId: 1,
    page: paginationStore.state.page,
    itemsPerPage: paginationStore.state.limit,
    pagination: true,
  };

  payload['filter[search]'] ??= filters.value.searchQuery || undefined;
  payload['order[name]'] ??= filters.value.sort?.name || undefined;
  payload['order[id]'] ??= filters.value.sort?.id || undefined;

  return payload as AuthorsFetchRequest;
});

const {
  data: authors,
  isLoading,
  isFetching,
} = useFetchAuthors(authorsPayload, (result: AuthorsFetchResponse) => {
  if (!result) {
    return;
  }

  paginationStore.setState({
    page: result.page,
    total: result.total,
  });
});

const redirectToAuthor = (authorId: number) => {
  router.push({
    name: 'author',
    params: {
      id: authorId,
    },
  });
};

onMounted(() => {
  filtersStore.initFromQuery();
});
</script>
