export const TAGS_RESOURCE = 'tags-resource';
export const TAG_RESOURCE = 'tag-resource';
export const TAG_CREATE_RESOURCE = 'tag-create-resource';

export const TAG_FIELD_TITLE = 'title';
export const TAG_FIELD_ID = 'id';
export const TAG_FIELD_DESCRIPTION = 'description';
export const TAG_FIELD_SLUG = 'slug';
export const TAG_FIELD_AVATAR = 'avatar';
export const TAG_FIELD_UPDATED_AT = 'updatedAt';
export const TAG_FIELD_CREATED_AT = 'createdAt';
export const TAG_FIELD_META_TITLE = 'metaTitle';
export const TAG_FIELD_META_DESCRIPTION = 'metaDescription';
export const TAG_FIELD_ENABLED = 'enabled';
export const TAG_FIELD_LANGUAGE_ID = 'languageId';
export const TAG_FIELD_SUPER = 'super';
export const TAG_FIELD_PAGE_TITLE = 'pageTitle';

// Filtered after fetch by id
export const TAG_FIELD_WORDS = 'words';
export const TAG_FIELD_KEYWORDS = 'keywords';
export const TAG_FIELD_TOP = 'top';
export const TAG_FIELD_ACTIVE = 'active';
export const TAG_FIELD_IS_TOP = 'isTop';
export const TAG_FIELD_POSTS_COUNT = 'postsCount';
