<template>
  <div
    v-if="isTagLoading"
    class="max-w-[100vw] min-h-[60vh] flex items-center justify-center"
  >
    <div class="h-10 w-10">
      <LoaderIcon class="w-10 h-10" />
    </div>
  </div>
  <TagsForm
    v-else
    ref="tagsForm"
    :key="`${languageId}-${id}`"
    :initial-values="initialValues"
    :updated-at="updatedAt"
    @submit="updateTag"
  />
</template>

<script lang="ts" setup>
import { computed, watch, ref, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { useBreadcrumbStore } from '@/features/Breadcrumbs/store';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import type { Tag } from '@/features/Tags/types';
import TagsForm from '@/features/Tags/components/TagsForm.vue';
import { TagsService } from '@/features/Tags/service';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import {
  TAG_FIELD_TITLE,
  TAG_FIELD_ID,
  TAG_FIELD_UPDATED_AT,
  TAG_FIELD_CREATED_AT,

  // Filtered after fetch by id
  TAG_FIELD_ENABLED,
  TAG_FIELD_WORDS,
  TAG_FIELD_KEYWORDS,
  TAG_FIELD_TOP,
  TAG_FIELD_ACTIVE,
  TAG_FIELD_IS_TOP,
  TAG_FIELD_POSTS_COUNT,
} from '@/features/Tags/constants';
import { useSimpleAction, useToast } from '@/composables';

const route = useRoute();
const toast = useToast();
const { setBreadcrumbsLatestElement } = useBreadcrumbStore();

const currentLanguageStore = useCurrentLanguageStore();
const currentLanguage = computed(() => currentLanguageStore.currentLanguage);
const languageId = computed(() => currentLanguage.value.id);
const id = computed(() => +route.params[TAG_FIELD_ID]);
const updatedAt = ref<string>('');
const tagsForm = ref<typeof TagsForm | null>(null);

const isTagLoading = ref<boolean>(true);
const initialValues = ref<Tag | null>(null);

const filterTag = (tag: Tag) => {
  // Filtering of extra properties needed for vuelidate meta.dirty to work
  const {
    [TAG_FIELD_UPDATED_AT]: _,
    [TAG_FIELD_CREATED_AT]: __,
    [TAG_FIELD_WORDS]: ___,
    [TAG_FIELD_KEYWORDS]: ____,
    [TAG_FIELD_TOP]: _____,
    [TAG_FIELD_ACTIVE]: ______,
    [TAG_FIELD_IS_TOP]: _______,
    [TAG_FIELD_POSTS_COUNT]: ________,
    ...filteredResult
  } = tag;

  return filteredResult;
};

const fetchTagCallback = (result: Tag) => {
  setBreadcrumbsLatestElement({
    name: result[TAG_FIELD_TITLE],
    title: result[TAG_FIELD_TITLE],
  });

  updatedAt.value = result[TAG_FIELD_UPDATED_AT] as string;

  initialValues.value = filterTag(result);
  isTagLoading.value = false;
};

const { action: updateTag } = useSimpleAction(async (payload: Tag) => {
  try {
    const { id } = payload;
    const result = await TagsService.edit(id, languageId.value, payload);
    toast.success({
      id: 'SUCCESS_TAG_UPDATE',
      message: 'Changes updated successfully.',
    });

    fetchTagCallback(result);

    nextTick(() => {
      if (tagsForm.value) {
        tagsForm.value.resetFormValues();
      }
    });
  } catch (err) {
    toast.errorTemporary({
      id: 'ERROR_TAG_UPDATE',
      message: 'Something went wrong. Try updating the changes again.',
    });
    throw err;
  }
});

watch(
  [languageId, id],
  async ([newLanguageId, newId]) => {
    if (newId && newLanguageId) {
      try {
        isTagLoading.value = true;
        initialValues.value = null;
        const result = await TagsService.fetch(newId, newLanguageId);
        fetchTagCallback(result);
      } catch (error) {
        toast.errorTemporary({
          id: 'ERROR_TAG_FETCH_BY_ID',
          message: (error as any).data?.errorMessage || 'Something went wrong. Try updating the changes again.',
        });
      }
    }
  },
  { immediate: true },
);
</script>
