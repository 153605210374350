import { ref, computed } from 'vue';
import { useFetchUsers } from '@/features/Users/queries';
import { type UsersFetchPayload } from '@/features/Users/types';
import { mapUsersToSelectItem } from '@/features/Users/utils';
import { ROLES } from '@/types';
import { useFetchRoles } from '@/features/Roles/queries';
import * as Sentry from '@sentry/vue';

type UsersParams = {
  roles: Array<
    | ROLES.ROLE_WRITER
    | ROLES.ROLE_SENIOR_WRITER
    | ROLES.ROLE_EDITOR
    | ROLES.ROLE_COPY_EDITOR
    | ROLES.ROLE_ART_EDITOR
    | ROLES.ROLE_EDITOR_IN_CHIEF
    | ROLES.ROLE_SUPER_ADMIN
  >;
};

export const useUsersByRoles = (params: UsersParams, immediate: boolean = true) => {
  const { data: roles } = useFetchRoles();

  const usersSearch = ref('');
  const isLoading = ref(false);

  const rolesFilter = computed(() => {
    return (
      roles?.value?.items
        .filter((role) => {
          return params.roles.includes(role.slug);
        })
        .map((role) => role.id) || []
    );
  });

  const fetchUserParams = computed<UsersFetchPayload>(() => ({
    page: 1,
    itemsPerPage: 15,
    filters: {
      search: usersSearch.value,
      roles: rolesFilter.value,
    },
  }));
  const { data: result, refetch } = useFetchUsers(fetchUserParams, immediate);

  const users = computed(() => {
    return mapUsersToSelectItem(result.value?.items) || [];
  });

  const fetchUsers = async (search?: string) => {
    if (search) {
      usersSearch.value = search;
    }

    isLoading.value = true;
    try {
      await refetch();
    } catch (error: unknown) {
      Sentry.captureException(error);
    } finally {
      isLoading.value = false;
    }

    return users.value;
  };

  return {
    users,
    isLoading,
    fetchUsers,
  };
};

export const useWriters = (immediate: boolean = true) =>
  useUsersByRoles(
    {
      roles: [ROLES.ROLE_WRITER],
    },
    immediate,
  );

export const useCopyEditors = (immediate: boolean = true) =>
  useUsersByRoles(
    {
      roles: [ROLES.ROLE_COPY_EDITOR],
    },
    immediate,
  );

export const useEditors = (immediate: boolean = true) =>
  useUsersByRoles(
    {
      roles: [ROLES.ROLE_EDITOR],
    },
    immediate,
  );
