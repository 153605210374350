export const USERS_RESOURCE = 'users-resource';

// TODO: Remove if options list provided by BE
export const SHIFT_OPTIONS = [
  { id: 'EU', label: 'EU' },
  { id: 'US', label: 'US' },
  { id: 'AUS', label: 'AUS' },
  { id: 'EU/US', label: 'EU/US' },
  { id: '-', label: '-' },
];
