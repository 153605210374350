import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useSimpleAction } from '@/composables';
import { UsersService } from '@/features/Users/service';
import { useToast } from '@/composables/useToast';
import type { User } from '@/features/Users/types';
import { useFetchUsers } from '@/features/Users/queries';
import { type UsersFetchResponse } from '@/features/Users/types';
import { boolean } from 'zod';

interface UsersState {
  users: User[];
  editedUser: User;
  isLoading: boolean;
  isFetching: boolean;
}

export const useUsersPage = defineStore('usersPage', () => {
  const state = ref<UsersState>({
    users: [],
    editedUser: {},
    isLoading: false,
    isFetching: false,
  });

  const toast = useToast();

  const { isLoading: isUsersLoading, action } = useSimpleAction<any>(async ({ paginationStore, filtersStore }) => {
    try {
      const payload = computed(() => {
        return {
          page: paginationStore.state.page,
          itemsPerPage: paginationStore.state.limit,
          ['filters[search]']: filtersStore.state.searchQuery,
          filters: filtersStore.state.filters,
          sort: filtersStore.state.sort,
        };
      });
      const { data, isFetching, isLoading } = useFetchUsers(payload, true, (result: UsersFetchResponse) => {
        if (!result) {
          return;
        }

        state.value.users = result.items;
        paginationStore.setState({
          page: result.page,
          total: result.total,
        });
      });

      state.value.isLoading = isLoading;
      state.value.isFetching = isFetching;
    } catch (err) {
      console.error('error', err);
      throw err;
    }
  });

  const { isLoading: isEditedUserLoading, action: fetchEditingUser } = useSimpleAction(async (id: number) => {
    try {
      state.value.editedUser = await UsersService.fetch(id);
    } catch (err) {
      console.error(err);
      toast.errorTemporary({ id: 'ERROR_FETCH_USER_INFO', message: 'Cannot fetch user info. Please try later' });
      throw err;
    }
  });

  const cleanEditingUser = () => {
    state.value.editedUser = {};
  };

  return {
    isUsersLoading,
    fetchUsersList: action,
    isEditedUserLoading,
    fetchEditingUser,
    cleanEditingUser,
    state,
  };
});
