// Prevent link from deleting if last char was deleted
import { EditorView } from 'prosemirror-view';

export const deleteLinkHelper = (view: EditorView, event: InputEvent): boolean | undefined => {
  if (event.inputType === 'deleteContentBackward' || event.inputType === 'deleteContentForward') {
    const { state, dispatch } = view;
    const { selection } = state;
    const { $from, $to } = selection;

    if (!$to.nodeBefore?.marks) {
      return;
    }

    const hasLink = $to.nodeBefore.marks.some((mark) => mark.type.name === 'a');
    if (hasLink) {
      event.preventDefault();

      // Delete only text inside a mark
      const tr = state.tr.delete($from.pos - 1, $to.pos);
      dispatch(tr);
      return true;
    }
  }
};
