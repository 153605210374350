<script setup lang="ts">
import { BUTTON_COLOR, BUTTON_TYPE, SIZES, SortStatus } from '@/types';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import { computed, ref } from 'vue';
import PostCard from '@/features/EditorsChoice/components/PostCard.vue';
import PlusIcon from '@/assets/icons/plus.svg?component';
import Button from '@/components/Button.vue';
import Search from '@/components/Table/Filters/Search.vue';
import { useEditorsChoiceStore } from '@/features/EditorsChoice/stores/editorsChoice.store.ts';
import { useFetchPosts } from '@/features/Posts/services';
import LoaderIcon from '@/assets/icons/spinner.svg?component';

const currentLanguageStore = useCurrentLanguageStore();
const languageId = computed(() => currentLanguageStore.currentLanguage.id);

const editorsChoiceStore = useEditorsChoiceStore();

const addPostButtonDisabled = computed(() => editorsChoiceStore.state.posts?.length === 5 ?? false);

const searchQuery = ref<string>('');

const updateSearchQuery = (search: string) => {
  searchQuery.value = search;
};

const postsParams = computed(() => {
  const payload = {
    languageId: languageId.value,
    itemsPerPage: 10,
    pagination: true,
    page: 1,
    'order[publishedAt]': SortStatus.DESC,
    'filter[publishedAt][to]': new Date().toISOString(),
  };

  payload['filter[search]'] ??= searchQuery.value || undefined;

  return payload;
});

const { data: articles, isLoading } = useFetchPosts(postsParams);
</script>

<template>
  <div class="bg-white rounded-xl p-4 gap-4 flex flex-col">
    <div class="flex justify-between">
      <h2 class="text-lg font-semibold">Published Articles ✔️</h2>
    </div>
    <Search
      placeholder="Search for articles"
      :value="searchQuery"
      @update:modelValue="(value) => updateSearchQuery(value)"
    />
    <div
      v-if="articles && articles.items && !isLoading"
      class="flex flex-col gap-2"
    >
      <template v-if="articles.items && articles.items.length">
        <template
          v-for="post in articles.items"
          :key="post.id"
        >
          <PostCard :post="post">
            <template #iconRight>
              <Button
                :color="BUTTON_COLOR.DEFAULT"
                :size="SIZES.MEDIUM"
                data-testid="restore-modal-close-button"
                :visual-type="BUTTON_TYPE.TERTIARY"
                class="w-7 h-6 p-0 ml-auto"
                :is-disabled="addPostButtonDisabled || editorsChoiceStore.postAlreadyAdded(post.id)"
                @click="editorsChoiceStore.addPostToEditorsChoice(post)"
              >
                <PlusIcon class="h-4 w-4" />
              </Button>
            </template>
          </PostCard>
        </template>
      </template>
      <div
        v-else
        class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
      >
        <div class="font-semibold text-imperium-fg-strong">No published articles found</div>
      </div>
    </div>
    <div
      v-if="isLoading"
      class="max-w-[100vw] min-h-[60vh] flex items-center justify-center"
    >
      <div class="h-10 w-10">
        <LoaderIcon class="w-10 h-10" />
      </div>
    </div>
  </div>
</template>
