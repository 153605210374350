export enum DocumentStatus {
  NEW = 1,
  IN_PROGRESS = 2,
  READY_TO_REVIEW = 4,
  READY_TO_PUBLISH = 5,
  PUBLISHED = 6,
  UNPUBLISHED = 8,
  TEXT_REJECTED = 9,
  SPIKED = 11,
}

export enum TemporaryDocumentStatuses {
  SCHEDULED = 'scheduled',
}

export const enum DocumentStatusAliases {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  READY_TO_REVIEW = 'READY_TO_REVIEW',
  READY_TO_PUBLISH = 'READY_TO_PUBLISH',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  TEXT_REJECTED = 'TEXT_REJECTED',
}

export interface DocumentPatchPayload {
  status: string;
  author: number;
  articleTitle: string;
  articleDescription: string;
  articleUrl: string;
  assignment: string;
  needsCover: boolean;
  writer: number;
  editor: number;
  copyEditor: number;
  deadline: Date;
  publish: Date;
  embargo: Date;
  category: number;
  label: number[];
  superTags: number[];
  tags: number[];
  twitterPost: string;
  twitterLeadText: string;
  twitterExcludeRss: boolean;
  twitterExcludeChannels: boolean;
  description: string;
  isBreakingNews: boolean;
  isShowInMarkets: boolean;
  isExcludeFromRss: boolean;
  isHideFromHot: boolean;
  isHideFromMainPage: boolean;
  isPromo: boolean;
}
