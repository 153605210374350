import { extractData, http } from '@/http';
import qs from 'qs';
import type {
  ImageSetAttributesRequest,
  ImageSetAttributesResponse,
  ImageUploadRequest,
  ImageUploadResponse,
} from '@/features/Media/types/api';
import type { ImageUsedAsCoverCounter, ImageValidationParams } from '@/features/UploadImage/types';

export const imageSetAttributes = (
  id: number,
  patchBody: ImageSetAttributesRequest,
): Promise<ImageSetAttributesResponse> =>
  http.patch<ImageSetAttributesResponse>(`/gallery-images/${id}`, patchBody, { useAuth: true }).then(extractData);

export const imageGalleryUpload = (postBody: ImageUploadRequest): Promise<ImageUploadResponse> =>
  http
    .postForm<any>(`/gallery-images`, postBody, {
      useAuth: true,
    })
    .then(extractData);

export const imageUpload = (
  postBody: ImageUploadRequest,
  params: ImageValidationParams,
): Promise<ImageUploadResponse> => {
  const queryString = qs.stringify(params, { encode: true }); // Convert object to query string

  return http
    .postForm<any>(`/files/upload?${queryString}`, postBody, {
      useAuth: true,
    })
    .then(extractData);
};

export const checkImageUsedAsCover = (imageId: number): Promise<ImageUsedAsCoverCounter> =>
  http.get(`/gallery-images/${imageId}/used-as-cover`, { useAuth: true }).then(extractData);
