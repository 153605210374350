import { ref, watch, type Ref } from 'vue';
import zod from 'zod';
import {
  MAX_ASSIGNMENT_LENGTH,
  MAX_DEK_LENGTH,
  MAX_SEO_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
} from '@/features/ArticleLayout/constants';
import { DocumentStatus } from '@/types';
import { useDocumentStatus } from '@/features/ArticleLayout/composables';
import { toTypedSchema } from '@vee-validate/zod';
import type { RefinementCtx } from 'zod/lib/types';

const tagSchema = zod.object({
  id: zod.number(),
  label: zod.string(),
});

// Define the base schema object (old schema)
const BASE_SCHEMA = {
  authorId: zod.coerce.number().min(1, 'Select public author'),
  title: zod.string().max(MAX_TITLE_LENGTH, 'Too long title').min(1, 'Enter a title for the article'),
  dek: zod.string().max(MAX_DEK_LENGTH, 'Too long dek').min(1, 'Enter a lead text (Dek) for the article'),
  slug: zod.string().min(1, 'Enter article url').nullable(),
  assignment: zod.string().max(MAX_ASSIGNMENT_LENGTH, 'Too long').min(1, 'Enter the assignment').nullable(),
  needsOriginalArtwork: zod.boolean(),
  writerId: zod.number().nullable(),
  editorId: zod.number().nullable(),
  copyEditorId: zod.number().nullable(),
  coverImageDeadLineAt: zod.string().nullable(),
  publishedAt: zod.string().nullable().optional(),
  updatedAt: zod.string().nullable().optional(),
  embargoUntil: zod.string().nullable(),
  categoryId: zod.coerce.number().min(1, 'Select a category'),
  badgeId: zod.coerce.number().min(1, 'Select a label to display on the cover'),
  superTags: zod.array(tagSchema).nonempty('Select super tag'),
  tags: zod.array(tagSchema).nonempty('Select tag'),
  twitterPost: zod.string().nullable(),
  twitterExcludeRss: zod.boolean(),
  isExcludedFromTelegram: zod.boolean(),
  seoMetaDescription: zod.string().min(1, 'Fill the description').max(MAX_SEO_DESCRIPTION_LENGTH),
  isBreakingNews: zod.boolean(),
  isShowingInMarkets: zod.boolean(),
  excludeFromAllRss: zod.boolean(),
  hideFromHotStories: zod.boolean(),
  hideFromMainPage: zod.boolean(),
  isPromoPost: zod.boolean(),
  coverArt: zod.number().min(1, 'Insert image as cover').nullable(),
  youtubeUrl: zod.string().nullable(),
};

// Create schema rules for text and full validation
const SCHEMA_TEXT_REQUIRED_RULESET = {
  authorId: zod.coerce.number().min(1, 'Select public author'),
  title: zod.string().min(1, 'Enter a title for the article').max(MAX_TITLE_LENGTH, 'Too long title'),
  dek: zod.string().min(1, 'Enter a lead text (Dek) for the article').max(MAX_DEK_LENGTH, 'Too long dek'),
  twitterPost: zod.string().nullable(),
  twitterExcludeRss: zod.boolean(),
  categoryId: zod.coerce.number().min(1, 'Select a category'),
  badgeId: zod.coerce.number().min(1, 'Select a label to display on the cover'),
  tags: zod.array(tagSchema).nonempty('Select tag'),
  superTags: zod.array(tagSchema).nonempty('Select super tag'),
  seoMetaDescription: zod.string().min(1, 'Fill the description').max(MAX_SEO_DESCRIPTION_LENGTH),
};

const SCHEMA_FULL_REQUIRED_RULESET = {
  ...SCHEMA_TEXT_REQUIRED_RULESET,
  coverArt: zod.coerce.number().min(1, 'Insert image as cover'),
};

// Map document status to corresponding validation schema type
const DOCUMENT_STATUS_TO_RULE_SET = {
  [DocumentStatus.READY_TO_PUBLISH]: {
    [DocumentStatus.READY_TO_REVIEW]: SCHEMA_FULL_REQUIRED_RULESET,
  },
  [DocumentStatus.PUBLISHED]: {
    [DocumentStatus.READY_TO_PUBLISH]: SCHEMA_FULL_REQUIRED_RULESET,
    [DocumentStatus.UNPUBLISHED]: SCHEMA_FULL_REQUIRED_RULESET,
  },
  [DocumentStatus.NEW]: {
    [DocumentStatus.IN_PROGRESS]: SCHEMA_TEXT_REQUIRED_RULESET,
  },
};

const superRefineCallback = (fields: { twitterExcludeRss: boolean; twitterPost: string }, ctx: RefinementCtx): void => {
  if (!fields.twitterExcludeRss && (!fields.twitterPost || fields.twitterPost.length < 1)) {
    return ctx.addIssue({
      message: 'Enter a text for Twitter post',
      path: ['twitterPost'],
      code: zod.ZodIssueCode.custom,
    });
  }
};

// Composable to manage article schema
export const useTypedSchema = () => {
  const { documentStatus, nextStepIs } = useDocumentStatus();
  const articleSchema: Ref<zod.ZodSchema<any>> = ref(
    toTypedSchema(zod.object(BASE_SCHEMA).superRefine(superRefineCallback)),
  ); // Initial schema

  // Build the article schema based on the document's next step
  const buildArticleSchema = (nextStep: DocumentStatus | undefined) => {
    if (!nextStep || documentStatus.value === nextStep) return;

    const schema = DOCUMENT_STATUS_TO_RULE_SET[nextStep]?.[documentStatus.value] ?? SCHEMA_FULL_REQUIRED_RULESET;

    articleSchema.value = toTypedSchema(zod.object({ ...BASE_SCHEMA, ...schema }).superRefine(superRefineCallback));
  };

  watch(() => nextStepIs.value, buildArticleSchema);

  return {
    articleSchema,
  };
};
