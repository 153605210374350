<template>
  <div class="mt-2">
    <UploadMediaButton
      v-if="isMobile && !isChoosingMedia"
      class="mb-4"
      :is-full-width="true"
    />
    <div class="flex mb-1 flex-col xs:flex-row xs:justify-between w-full gap-3 flex-nowrap xs:items-center">
      <Search
        class="md:max-w-[300px]"
        placeholder="Search for image"
        is-suffix-shown
        :value="searchQuery"
        @update:modelValue="(value) => updateSearchQuery(value)"
      />
      <div class="flex max-h-10 flex-nowrap w-fit gap-2">
        <SortDropdown
          name="media"
          :fields="SORT_FIELDS"
          :direction="SortStatus.DESC"
          :filter-store="filtersStore"
          @update="onSortUpdate"
          @reset="onSortReset"
        />

        <UploadMediaButton v-if="!isMobile && !isChoosingMedia" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, inject } from 'vue';
import { useMediaFilters } from '@/features/Media/stores/filters.store';
import { SortStatus } from '@/types';

import Search from '@/components/Table/Filters/Search.vue';
import UploadMediaButton from '@/features/Media/components/UploadMediaButton.vue';
import UploadMediaCreatedAtSort from '@/features/Media/components/UploadMediaCreatedAtSort.vue';
import SortDropdown, { type SortField } from '@/components/Table/Filters/SortDropdown.vue';

const SORT_FIELDS: SortField[] = [
  {
    value: 'createdAt',
    label: 'Created',
  },
  {
    value: 'lastUsage',
    label: 'Last usage',
  },
];

const props = withDefaults(
  defineProps<{
    isChoosingMedia: boolean;
  }>(),
  {
    isChoosingMedia: false,
  },
);

const isMobile = inject<boolean>('isMobile');

const filtersStore = useMediaFilters();

const searchQuery = computed(() => filtersStore.state?.searchQuery || '');

const updateSearchQuery = (searchQuery: string) => {
  filtersStore.setSearchQuery(searchQuery);
};

const onSortUpdate = (newField: SortField, newDirection: SortStatus) => {
  filtersStore.setSorts({
    [newField.value]: newDirection,
  });
};

const onSortReset = () => {
  filtersStore.resetSort();
};
</script>
