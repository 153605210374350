import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useLayoutAppearance = () => {
  const route = useRoute();

  const isMainLayoutVisible = computed(() => !/^\/auth(\/|$)/.test(route.path));

  // Features
  const isDashboardSwitcherVisible = computed(() => route.name === 'dashboard');
  const isDashboardPage = computed(() => route.name === 'dashboard');
  const isArticlesPage = computed(() => route.path === '/articles');
  const isArticlePage = computed(() => route.name === 'article');
  const isTagsPage = computed(() => route.path === '/tags');
  const isTagPage = computed(() => route.name === 'tag');
  const isEditorsChoicePage = computed(() => route.name === 'editors-choice');

  return {
    isMainLayoutVisible,

    isDashboardSwitcherVisible,
    isDashboardPage,
    isArticlesPage,
    isArticlePage,
    isTagsPage,
    isTagPage,
    isEditorsChoicePage,
  };
};
