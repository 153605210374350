import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import {
  ARTICLE_FLAGS,
  FILTERS_BY_FLAG_MAP,
  FILTERS_BY_PRESET_MAP,
  FILTERS_DATE,
  QUICK_FILTERS,
} from '@/features/Articles/constants';
import { useArticlesPagination } from '@/features/Articles/stores/pagination.store';
import { DocumentStatus } from '@/types';
import { SortStatus } from '@/types';
import { ARTICLE_COLUMNS } from '../constants';

type Filters = Record<string, string | string[]>;

export type State = {
  filters: Filters;
  quickFilters: QUICK_FILTERS | null;
  sort: unknown;
  searchQuery: string;
  enabledColumns: Record<string, boolean>;
};

const DEFAULT_SORT = { id: SortStatus.DESC };

export const useArticlesFilters = defineStore(
  'articlesPageFilters',
  () => {
    const articlesPagination = useArticlesPagination();

    const state = ref<State>({
      filters: {},
      quickFilters: null,
      sort: DEFAULT_SORT,
      searchQuery: '',
      enabledColumns: {
        [ARTICLE_COLUMNS.FLAGS]: true,
        [ARTICLE_COLUMNS.STATUS]: true,
        [ARTICLE_COLUMNS.EMBARGO]: true,
        [ARTICLE_COLUMNS.DEADLINE]: true,
        [ARTICLE_COLUMNS.PUBLISHED]: true,
        [ARTICLE_COLUMNS.EDITOR]: true,
        [ARTICLE_COLUMNS.WRITER]: true,
        [ARTICLE_COLUMNS.CATEGORY]: true,
        [ARTICLE_COLUMNS.WORDS]: true,
        [ARTICLE_COLUMNS.LANGUAGE]: true,
      },
    });

    const setFilters = (filters: Filters) => {
      state.value = { ...state.value, filters: { ...state.value.filters, ...filters } } as State;
      articlesPagination.setState({ page: 1 });
    };

    const setSorts = (sort) => {
      state.value = { ...state.value, sort: { ...sort } } as State;
    };

    const setSearchQuery = (searchQuery: string) => {
      state.value = { ...state.value, searchQuery };
      articlesPagination.setState({ page: 1 });
    };

    const resetSort = () => {
      state.value = { ...state.value, sort: DEFAULT_SORT };
    };

    const resetFilters = () =>
      (state.value = {
        ...state.value,
        filters: {},
        quickFilters: null,
      });

    const toggleFastFiltersByPreset = (filter: QUICK_FILTERS) => {
      if (state.value.quickFilters === filter) {
        state.value.quickFilters = null;
        resetFilters();
        return;
      } else {
        state.value.quickFilters = filter;
      }

      resetFilters();

      const preset = FILTERS_BY_PRESET_MAP[filter];

      const resultFlags: ARTICLE_FLAGS[] = [];
      if (preset.flag) {
        preset.flag.forEach((flag: ARTICLE_FLAGS) => {
          if (!(state.value.filters?.flag && state.value.filters?.flag?.includes(flag))) {
            resultFlags.push(flag);
          }
        });
      }

      const resultStatuses: DocumentStatus[] = [];
      if (preset.status) {
        preset.status.forEach((status: DocumentStatus) => {
          if (!(state.value.filters?.status && state.value.filters?.status?.includes(status))) {
            resultStatuses.push(status);
          }
        });
      }

      const resultFilters: Filters = {};

      if (resultFlags.length) {
        resultFilters.flag = resultFlags;
      }
      if (resultStatuses.length) {
        resultFilters.status = resultStatuses;
      }

      if (Object.keys(preset).length) {
        Object.keys(preset).forEach((filterName: string) => {
          if (filterName === 'filter[published][from]') {
            if (preset['filter[published][from]'] === FILTERS_DATE.NOW) {
              resultFilters.publishedFrom = new Date().toISOString();
              return;
            }

            resultFilters.publishedFrom = preset['filter[published][from]'];
            return;
          }
          if (filterName === 'filter[published][to]') {
            if (preset['filter[published][to]'] === FILTERS_DATE.NOW) {
              resultFilters.publishedTo = new Date().toISOString();
              return;
            }

            resultFilters.publishedTo = preset['filter[published][to]'];
            return;
          }
        });
      }

      setFilters(resultFilters);
    };

    const normalizeFilters = () => {
      const result = {
        deadline: state.value.filters.deadline,
        createdAt: state.value.filters.createdAt,
        publishedFrom: state.value.filters.publishedFrom,
        publishedTo: state.value.filters.publishedTo,
        embargoedUntil: state.value.filters.embargoedUntil,
        needsProofreading: state.value.filters.needsProofreading,
        needsOriginalCover: state.value.filters.needsOriginalCover,
        language: state.value.filters.language,
        status: state.value.filters.status,
        editor: state.value.filters.editor,
        copyEditor: state.value.filters.copyEditor,
        writer: state.value.filters.writer,
        author: state.value.filters.author,
        creator: state.value.filters.creator,
        category: state.value.filters.category,
      };

      if (Array.isArray(state.value.filters.flag) && state.value.filters.flag.length) {
        state.value.filters.flag.forEach((currentFlag: ARTICLE_FLAGS) => {
          const filtersByFlag = FILTERS_BY_FLAG_MAP[currentFlag];

          filtersByFlag?.forEach((filter) => {
            if (!filter) {
              return;
            }

            const [key, value] = Object.entries(filter)[0];

            if (!result[key]) {
              result[key] = value;
              return;
            }

            if (Array.isArray(value)) {
              result[key] = result[key].concat(value);
            }
          });
        });
      }

      return result;
    };

    const updateColumn = (column: ARTICLE_COLUMNS) => {
      if (!isColumnSelected(column)) {
        state.value = {
          ...state.value,
          enabledColumns: {
            ...state.value.enabledColumns,
            [column]: true,
          },
        };
        return;
      }

      state.value = {
        ...state.value,
        enabledColumns: {
          ...state.value.enabledColumns,
          [column]: false,
        },
      };
    };

    const resetColumns = () => {
      state.value = {
        ...state.value,
        enabledColumns: {
          [ARTICLE_COLUMNS.FLAGS]: true,
          [ARTICLE_COLUMNS.STATUS]: true,
          [ARTICLE_COLUMNS.EMBARGO]: true,
          [ARTICLE_COLUMNS.DEADLINE]: true,
          [ARTICLE_COLUMNS.PUBLISHED]: true,
          [ARTICLE_COLUMNS.EDITOR]: true,
          [ARTICLE_COLUMNS.WRITER]: true,
          [ARTICLE_COLUMNS.CATEGORY]: true,
          [ARTICLE_COLUMNS.WORDS]: true,
          [ARTICLE_COLUMNS.CREATED]: false,
          [ARTICLE_COLUMNS.UPDATED]: false,
          // temporary removed according to https://ctmain.slack.com/archives/C04JSK57DU1/p1723033881244309
          // [ARTICLE_COLUMNS.VIEWS]: false,
          // [ARTICLE_COLUMNS.SHARES]: false,
          [ARTICLE_COLUMNS.COPY_EDITOR]: false,
          [ARTICLE_COLUMNS.AUTHOR]: false,
          [ARTICLE_COLUMNS.CREATOR]: false,
          [ARTICLE_COLUMNS.CHARACTERS]: false,
          [ARTICLE_COLUMNS.LANGUAGE]: false,
        },
      };
    };

    const isColumnSelected = (column: ARTICLE_COLUMNS): boolean => {
      return state.value.enabledColumns && state.value.enabledColumns[column];
    };

    return {
      state,
      setFilters,
      setSorts,
      setSearchQuery,
      toggleFastFiltersByPreset,

      resetFilters,
      normalizeFilters,

      resetSort,

      updateColumn,
      resetColumns,
      isColumnSelected,
      enabledColumns: computed(() => state.value.enabledColumns),

      selectedFiltersCount: computed(() => {
        const baseCount = Object.keys(state.value.filters).length;
        const statusCount = state.value.filters?.status?.length ? state.value.filters?.status?.length - 1 : 0;
        const languageCount = state.value.filters?.language?.length ? state.value.filters?.language?.length - 1 : 0;
        const flagCount = state.value.filters?.flag?.length ? state.value.filters?.flag?.length - 1 : 0;

        return baseCount + statusCount + languageCount + flagCount;
      }),
    };
  },
  {
    persist: true,
  },
);
