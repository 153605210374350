<script lang="ts" setup>
import { computed, onMounted, ref, defineExpose } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useFormData, useToast } from '@/composables';
import { getFormattedDate, getFormattedTime } from '@/utils';
import CloudIcon from '@/assets/icons/cloud.svg?component';
import Button from '@/components/Button.vue';
import FormInput from '@/components/FormInput.vue';
import FormUrlInput from '@/components/FormUrlInput.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import FormCheckbox from '@/components/FormCheckbox.vue';
import { BUTTON_TYPE, SIZES } from '@/types';
import {
  TAG_FIELD_TITLE,
  TAG_FIELD_ID,
  TAG_FIELD_DESCRIPTION,
  TAG_FIELD_SLUG,
  TAG_FIELD_AVATAR,
  TAG_FIELD_META_DESCRIPTION,
  TAG_FIELD_META_TITLE,
  TAG_FIELD_ENABLED,
  TAG_FIELD_SUPER,
  TAG_FIELD_PAGE_TITLE,
} from '@/features/Tags/constants';
import type { Tag } from '@/features/Tags/types';
import FormSection from '@/components/FormSection.vue';
import UpdateModal from '@/features/Tags/components/modals/UpdateModal.vue';
import UploadImageButton from '@/features/UploadImage/components/UploadImageButton.vue';
import { UploadImageService } from '@/features/UploadImage/service/uploadImage';
import { convertStringToKebabCase } from '@/utils/url';
import { tagsFormSchema } from '@/features/Tags/validators';
import { useUserPermissions } from '@/stores/user.store';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries';
import { fetchDomain } from '@/api/domain.ts';

const props = defineProps<{
  initialValues?: Tag | null;
  updatedAt?: string;
}>();

const emits = defineEmits<{
  (event: 'submit', values: Tag): void;
}>();

const { tagsPageEdit } = useUserPermissions();

const { t } = useI18n();
const toast = useToast();
const isEditMode = computed(() => !!props.initialValues?.[TAG_FIELD_ID]);
const hasUnsavedChanges = computed(() => meta.value.dirty);
const currentLanguageStore = useCurrentLanguageStore();
const currentLanguage = computed(() => currentLanguageStore.currentLanguage);
const languageId = computed(() => currentLanguage.value.id);
const domainName = ref<string | null>(null);
import { useWebsiteDomainStore } from '@/stores/domain.store.ts';

const domainStore = useWebsiteDomainStore();
const isUpdateModalOpen = ref<boolean>(false);

const { defineField, errors, values, meta, validate, setErrors, resetForm } = useFormData({
  data: {
    id: null,
    [TAG_FIELD_AVATAR]: '',
    [TAG_FIELD_TITLE]: '',
    [TAG_FIELD_SLUG]: '',
    [TAG_FIELD_DESCRIPTION]: '',
    [TAG_FIELD_META_TITLE]: '',
    [TAG_FIELD_META_DESCRIPTION]: '',
    [TAG_FIELD_PAGE_TITLE]: '',
    [TAG_FIELD_ENABLED]: true,
    [TAG_FIELD_SUPER]: false,
  },
  validator: tagsFormSchema,
});

const [avatar, avatarAttrs] = defineField(TAG_FIELD_AVATAR);
const [name, nameAttrs] = defineField(TAG_FIELD_TITLE);
const [slug, slugAttrs] = defineField(TAG_FIELD_SLUG);
const [superTag, superAttrs] = defineField(TAG_FIELD_SUPER);
const [description, descriptionAttrs] = defineField(TAG_FIELD_DESCRIPTION);
const [pageTitle, pageTitleAttrs] = defineField(TAG_FIELD_PAGE_TITLE);
const [metaTitle, metaTitleAttrs] = defineField(TAG_FIELD_META_TITLE);
const [metaDescription, metaDescriptionAttrs] = defineField(TAG_FIELD_META_DESCRIPTION);

const resetFormValues = () => {
  resetForm({
    values: { ...props.initialValues },
    initialValues: { ...props.initialValues },
    resetDirty: true,
  });
};

onMounted(async () => {
  resetFormValues();
  domainName.value = await domainStore.getDomain(languageId.value);
});

const uploadTagImage = async (image: File | string) => {
  if (image) {
    const { url } = await UploadImageService.imageUpload(image instanceof File ? { file: image } : { url: image }, {
      validateType: 'image',
      minSize: '400x400',
      resizeTo: '400x400',
    });

    avatar.value = url;
  }
};

const onSubmit = async (e: SubmitEvent) => {
  if (e.preventDefault) e.preventDefault();

  try {
    const { valid, errors: validationErrors } = await validate();
    if (!valid) {
      toast.errorTemporary({
        id: 'ERROR_INVALID_TAG_FORM',
        message: 'Fill all required field!',
      });
      throw validationErrors;
    }
  } catch (errors: any) {
    setErrors(errors as Record<string, string>);
    return;
  }

  try {
    if (values.avatar && values.avatar !== props.initialValues?.avatar) {
      await uploadTagImage(values.avatar);
    }
  } catch (error: any) {
    toast.errorTemporary({
      id: 'ERROR_TAG_IMAGE_UPLOAD',
      message: error.data?.errorMessage || 'Unable to upload image. Try one more time.',
    });
  }

  emits('submit', values);
};

const updatedAtText = computed<string>(() => {
  if (!props.updatedAt) return '';

  return `Changes saved on ${getFormattedDate(props.updatedAt)} at ${getFormattedTime(props.updatedAt)}`;
});

const isLeavePageModalOpen = ref(false);
const isCancelModalOpen = ref(false);
const pendingNavigation = ref<(() => void) | null>(null);
onBeforeRouteLeave((_to, _from, next) => {
  if (hasUnsavedChanges.value && isEditMode.value) {
    isLeavePageModalOpen.value = true;
    pendingNavigation.value = () => next();
  } else {
    next();
  }
});

const closeModalAndRedirect = () => {
  isLeavePageModalOpen.value = false;
  if (pendingNavigation.value) {
    pendingNavigation.value(); // Proceed with navigation
    pendingNavigation.value = null;
  }
};

const onRedirectModalConfirm = async () => {
  emits('submit', values);
  closeModalAndRedirect();
};

const onRedirectModalClose = () => {
  isUpdateModalOpen.value = false;
  closeModalAndRedirect();
};

const onTitleUpdate = (value: string) => {
  if (!isEditMode.value) {
    slug.value = convertStringToKebabCase(value);
  }
};

defineExpose({
  resetFormValues,
});
</script>

<template>
  <form
    novalidate
    @submit="onSubmit"
  >
    <div class="flex justify-between items-center mb-4 sticky z-10 top-[57px] bg-imperium-bg-3">
      <div
        v-if="updatedAtText"
        class="flex items-center gap-2 mb-2"
      >
        <CloudIcon class="w-6 min-w-6 h-6 min-h-6 text-imperium-ds-green-strong block" />
        <p class="text-base text-imperium-ds-green-strong font-semibold">{{ updatedAtText }}</p>
      </div>

      <div
        v-if="isEditMode"
        class="flex gap-2"
      >
        <Button
          :visual-type="BUTTON_TYPE.TERTIARY"
          :disabled="!hasUnsavedChanges || !tagsPageEdit"
          @click="isCancelModalOpen = true"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          :disabled="!hasUnsavedChanges || !tagsPageEdit"
        >
          Update
        </Button>
      </div>

      <Button
        v-else
        type="submit"
        class="ml-auto"
        :disabled="!tagsPageEdit"
      >
        Create new tag
      </Button>
    </div>

    <div class="flex flex-col gap-8 bg-imperium-bg-sub-base p-4 rounded-2xl">
      <FormSection
        number="1"
        title="General settings 🌐"
      >
        <div class="flex flex-col md:flex-row gap-4 w-full">
          <div class="flex flex-col gap-4 w-full md:w-1/2">
            <FormInput
              :id="`tag-${TAG_FIELD_TITLE}`"
              v-model="name"
              is-required
              :attrs="nameAttrs"
              :is-errored="!!errors[TAG_FIELD_TITLE]"
              placeholder="Enter tag name"
              @update:modelValue="onTitleUpdate"
            >
              <template #label>Tag name</template>
              <template #error> {{ errors[TAG_FIELD_TITLE] }} </template>
            </FormInput>

            <FormCheckbox
              v-if="languageId === DEFAULT_LANGUAGE_ID"
              name="Super"
              :size="SIZES.SMALL"
              :model-value="superTag"
              :attrs="superAttrs"
              @update:model-value="(value) => (superTag = value)"
            >
              <span class="text-sm text text-imperium-fg-base">Super tag</span>
            </FormCheckbox>
          </div>
          <div class="flex flex-col gap-4 w-full md:w-1/2">
            <FormUrlInput
              :name="`tag-${TAG_FIELD_SLUG}`"
              label="Tag URL"
              placeholder="tag-name"
              :is-required="false"
              :post-url="slug === meta.initialValues.slug ? slug : ''"
              :has-post="false"
              :url="slug"
              :url-attrs="slugAttrs"
              :size="SIZES.MEDIUM"
              :url-origin="domainName ? `${domainName}/tags/` : 'cointelegraph.com/tags/'"
              :is-readonly="isEditMode && meta.initialValues.slug"
              :is-disabled="isEditMode && meta.initialValues.slug"
              :is-errored="!!errors.slug"
              @update:url="(value) => (slug = value || '')"
            >
              <template #error> {{ errors.slug }} </template>
            </FormUrlInput>
          </div>
        </div>
      </FormSection>

      <FormSection
        number="2"
        title="Language specific"
      >
        <div class="flex flex-col md:flex-row gap-4 w-full">
          <div class="flex flex-col gap-4 w-full md:w-1/2">
            <FormInput
              :id="`tag-${TAG_FIELD_PAGE_TITLE}`"
              v-model="pageTitle"
              :attrs="pageTitleAttrs"
              :name="`tag-${TAG_FIELD_PAGE_TITLE}`"
              placeholder="Page title (H1)"
            >
              <template #label>Page title (H1)</template>
            </FormInput>

            <!-- TODO: It should be html editor in the future -->
            <FormTextarea
              v-model="description"
              :name="`tag-${TAG_FIELD_DESCRIPTION}`"
              class="w-full"
              min-height="112px"
              placeholder="Summarize the tag briefly"
              :attrs="descriptionAttrs"
            >
              <template #label>Description</template>
            </FormTextarea>
          </div>

          <div class="flex flex-col gap-4 w-full md:w-1/2">
            <FormInput
              :id="`tag-${TAG_FIELD_META_TITLE}`"
              v-model="metaTitle"
              :name="`tag-${TAG_FIELD_META_TITLE}`"
              placeholder="Enter a concise, keyword-rich title"
              :attrs="metaTitleAttrs"
            >
              <template #label>SEO-title</template>
            </FormInput>

            <FormTextarea
              v-model="metaDescription"
              :name="`tar-${TAG_FIELD_META_DESCRIPTION}`"
              class="w-full"
              min-height="112px"
              placeholder="Summarize the tag's expertise briefly"
              :attrs="metaDescriptionAttrs"
            >
              <template #label>SEO description</template>
            </FormTextarea>
          </div>
        </div>
        <div class="flex flex-col md:flex-row gap-4 w-full mt-4">
          <div class="flex flex-col gap-4 w-full md:w-1/2">
            <div class="inline-block">
              <UploadImageButton
                v-model="avatar"
                title="Image URL"
              />
            </div>
          </div>
        </div>
      </FormSection>
    </div>
  </form>
  <UpdateModal
    :is-visible="isLeavePageModalOpen"
    @close="onRedirectModalClose"
    @cancel="onRedirectModalClose"
    @confirm="onRedirectModalConfirm"
  >
    <template #description>
      Changes not saved.
      <br />
      Would you like to update them?
    </template>
  </UpdateModal>
  <UpdateModal
    v-if="isEditMode"
    :is-visible="isCancelModalOpen"
    @close="isCancelModalOpen = false"
    @cancel="
      resetForm();
      isCancelModalOpen = false;
    "
    @confirm="
      onSubmit(values);
      isCancelModalOpen = false;
    "
  >
    <template #description>
      You made changes.
      <br />
      Would you like to update them?
    </template>
  </UpdateModal>
</template>
