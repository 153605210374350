<script setup lang="ts">
import EditorsChoice from '@/features/EditorsChoice/views/EditorsChoice.vue';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import { computed, onMounted } from 'vue';
import { useUserStore } from '@/stores/user.store.ts';

const currentLanguageStore = useCurrentLanguageStore();
const userStore = useUserStore();

const userLanguages = computed(() => userStore.state.languages);
onMounted(() => {
  // Select first user available language if global was selected before
  if (currentLanguageStore.currentLanguage.code === 'global') {
    currentLanguageStore.updateLanguage(userLanguages.value[0]);
  }
});
</script>

<template>
  <EditorsChoice />
</template>
