<template>
  <div class="flex">
    <Avatar
      :id="`avatar-${props.id}`"
      :label="props.label"
      :custom-size="{ width: '72px', height: '72px' }"
      :img="avatarSrc || image || NoAvatarImage"
      :class="!image && 'border border-opacity-15 border-imperium-border-clear rounded-full'"
    />
    <div class="ml-4">
      <Button
        :visual-type="BUTTON_TYPE.TERTIARY"
        @click="
          reveal();
          emits('clickUpload');
        "
      >
        Upload new image
      </Button>
      <Button
        v-if="image"
        :visual-type="BUTTON_TYPE.TERTIARY"
        :color="BUTTON_COLOR.RED"
        class="ml-2"
        @click="
          emits('update:modelValue', '');
          emits('clickDelete');
        "
      >
        Delete image
      </Button>
      <p class="mt-2 text-xs text-imperium-fg-muted">Maximum size: 1 MB. Accepted format: PNG, JPG.</p>
    </div>
  </div>

  <UploadImageModal
    :is-visible="isRevealed"
    is-attributes-hidden
    skip-upload
    @close="cancel"
    @update-post-data="onAvatarUpdate"
  />
</template>

<script lang="ts" setup>
import { ref, watch, computed, onUnmounted } from 'vue';
import Button from '@/components/Button.vue';
import { BUTTON_TYPE, BUTTON_COLOR } from '@/types';
import Avatar from '@/components/Avatar.vue';
import UploadImageModal from '@/features/UploadImage/modals/UploadImageModal.vue';
import NoAvatarImage from '@/assets/img/noAvatar.png';
import { type PostData } from '@/features/UploadImage/types';
import { useModal } from '@/composables/useModal';

const { reveal, cancel, isRevealed } = useModal();

const emits = defineEmits<{
  (event: 'clickUpload'): void;
  (event: 'clickDelete'): void;
  (event: 'update:modelValue', value: File | string): void;
}>();

const props = defineProps<{
  modelValue: string | File;
  image?: string;
  id: number;
  label: string;
}>();

const onAvatarUpdate = (avatarData: PostData) => {
  emits('update:modelValue', avatarData.url || avatarData.file || '');
  cancel();
};

const avatarSrc = computed(() => {
  if (typeof props.modelValue === 'string') {
    return props.modelValue;
  } else if (props.modelValue instanceof File) {
    return URL.createObjectURL(props.modelValue);
  }

  return '';
});

watch(
  () => props.modelValue,
  (_newAvatar, oldAvatar) => {
    if (oldAvatar instanceof File) {
      URL.revokeObjectURL(URL.createObjectURL(oldAvatar));
    }
  },
);

onUnmounted(() => {
  if (props.modelValue instanceof File) {
    URL.revokeObjectURL(URL.createObjectURL(props.modelValue));
  }
});
</script>
