<template>
  <component
    :is="badgeComponent"
    :id="`article-${calculatedStatus}`"
    :label="STATUS_TO_LABEL_MAP[calculatedStatus]"
    :size="props.size"
    :theme="badgeTheme"
    :rounded="BADGE_ROUNDED.FULL"
    :is-hoverable="false"
    :is-closable="false"
    :no-wrap="props.noWrap"
    is-readonly
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Badge from '@/components/Badge.vue';
import BadgeWeak from '@/components/BadgeWeak.vue';
import { BADGE_ROUNDED, BADGE_THEMES, DocumentStatus, SIZES, TemporaryDocumentStatuses } from '@/types';

const STATUS_TO_LABEL_MAP = {
  [DocumentStatus.NEW]: 'New',
  [DocumentStatus.IN_PROGRESS]: 'In progress',
  [DocumentStatus.READY_TO_REVIEW]: 'Ready to review',
  [DocumentStatus.READY_TO_PUBLISH]: 'Ready to publish',
  [DocumentStatus.PUBLISHED]: 'Published',
  [DocumentStatus.UNPUBLISHED]: 'Unpublished',
  [DocumentStatus.TEXT_REJECTED]: 'Text Rejected',
  [TemporaryDocumentStatuses.SCHEDULED]: 'Scheduled',
  [DocumentStatus.SPIKED]: 'Spiked',
};

const STATUS_TO_BADGE_COMPONENT_MAP = {
  [DocumentStatus.NEW]: Badge,
  [DocumentStatus.IN_PROGRESS]: Badge,
  [DocumentStatus.READY_TO_REVIEW]: BadgeWeak,
  [DocumentStatus.READY_TO_PUBLISH]: BadgeWeak,
  [DocumentStatus.PUBLISHED]: Badge,
  [DocumentStatus.UNPUBLISHED]: Badge,
  [DocumentStatus.TEXT_REJECTED]: BadgeWeak,
  [TemporaryDocumentStatuses.SCHEDULED]: BadgeWeak,
  [DocumentStatus.SPIKED]: Badge,
};

const STATUS_TO_BADGE_COLOR_MAP = {
  [DocumentStatus.NEW]: BADGE_THEMES.DEFAULT,
  [DocumentStatus.IN_PROGRESS]: BADGE_THEMES.GREEN,
  [DocumentStatus.READY_TO_REVIEW]: BADGE_THEMES.PRIMARY,
  [DocumentStatus.READY_TO_PUBLISH]: BADGE_THEMES.PRIMARY,
  [DocumentStatus.PUBLISHED]: BADGE_THEMES.GREEN,
  [DocumentStatus.UNPUBLISHED]: BADGE_THEMES.RED,
  [DocumentStatus.TEXT_REJECTED]: BADGE_THEMES.RED,
  [TemporaryDocumentStatuses.SCHEDULED]: BADGE_THEMES.GREEN,
  [DocumentStatus.SPIKED]: BADGE_THEMES.DISABLED,
};

const props = withDefaults(
  defineProps<{
    size?: SIZES;
    status?: DocumentStatus | TemporaryDocumentStatuses;
    publishedAt?: string;
    noWrap?: boolean;
  }>(),
  {
    sizes: SIZES.SMALL,
    status: DocumentStatus.NEW,
  },
);

const calculatedStatus = computed(() => {
  if (props.status === DocumentStatus.PUBLISHED && props.publishedAt) {
    const currentDate = new Date();
    const publishDate = props.publishedAt ? new Date(props.publishedAt) : currentDate;

    if (publishDate.getTime() > currentDate.getTime()) {
      return TemporaryDocumentStatuses.SCHEDULED;
    }
  }

  return props.status;
});

const badgeComponent = computed(() => STATUS_TO_BADGE_COMPONENT_MAP[props.status]);
const badgeTheme = computed(() => STATUS_TO_BADGE_COLOR_MAP[props.status]);
</script>
