<template>
  <ModalHolder
    data-testid="update-author-modal"
    :is-visible="props.isVisible"
    :size="SIZES.SMALL"
    :type="MODAL_TYPES.POPUP"
    @close="emits('close')"
  >
    <div class="flex flex-col justify-center items-center">
      <ExclamationShapesIcon class="w-20 h-20 text-imperium-bg-5 mb-4" />

      <div class="text-imperium-fg-base text-base font-normal text-center">
        <slot name="description" />
      </div>
    </div>

    <div class="flex items-center gap-4 justify-center pt-5">
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        data-testid="button-cancel"
        @click="emits('cancel')"
      >
        Don’t save changes
      </Button>

      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-loading="false"
        data-testid="button-cta"
        @click="emits('confirm')"
      >
        Update
      </Button>
    </div>
  </ModalHolder>
</template>

<script setup lang="ts">
import ExclamationShapesIcon from '@/assets/icons/exclamation-shapes.svg?component';

import Button from '@/components/Button.vue';

import ModalHolder from '@/components/ModalHolder.vue';

import { BUTTON_TYPE, SIZES, MODAL_TYPES } from '@/types';

const props = withDefaults(
  defineProps<{
    isVisible?: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'confirm'): void;
  (event: 'close'): void;
  (event: 'cancel'): void; // Cancel needed cause close fires everytime on click outside
}>();
</script>
