<template>
  <TagsForm @submit="createTag" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import type { Tag } from '@/features/Tags/types';
import TagsForm from '@/features/Tags/components/TagsForm.vue';
import { TagsService } from '@/features/Tags/service';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import { useSimpleAction, useToast } from '@/composables';

const router = useRouter();
const toast = useToast();

const currentLanguageStore = useCurrentLanguageStore();
const currentLanguage = computed(() => currentLanguageStore.currentLanguage);
const languageId = computed(() => currentLanguage.value.id);

const { action: createTag } = useSimpleAction(async (payload: Tag) => {
  try {
    const { id: _, ...filteredPayload } = payload;
    const { id } = await TagsService.create({
      ...filteredPayload,
      isTop: false,
      active: true,
      languageId: languageId.value,
    });

    setTimeout(() => {
      toast.success({
        id: 'SUCCESS_TAG_CREATION',
        message: 'Tag was successfully created',
      });
    }, 0);

    router.push({ path: `/tags/${id}` });
  } catch {
    toast.errorTemporary({
      id: 'ERROR_TAG_CREATION',
      message: 'Unable to create tag. Try one more time.',
    });
  }
});
</script>
