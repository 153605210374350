<template>
  <div>
    <div class="relative">
      <div
        class="relative bg-imperium-bg-sub-base"
        :class="{
          'rounded-2xl': !isMobile,
        }"
      >
        <div>
          <Table
            class="max-w-full relative overflow-x-auto"
            :is-rounded="!isMobile"
            :is-sticky="!isExtraLargeDesktop"
          >
            <TableHead>
              <TableHeadRow
                :size="SIZES.MEDIUM"
                :bordered="TABLE_BORDERED.BOTTOM"
                :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
              >
                <TableHeadCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="id"
                    label="Id"
                    :value="filtersStore.state.sort.id"
                    @change-sort="updateFilters({ filterName: PERMISSIONS_FIELD_ID, value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  is-full-width
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="title"
                    label="PERMISSION SLUG"
                    :value="filtersStore.state.sort.slug"
                    @change-sort="updateFilters({ filterName: PERMISSIONS_FIELD_SLUG, value: $event })"
                  />
                </TableHeadCell>
              </TableHeadRow>
            </TableHead>

            <TableBody class="contents">
              <div
                v-if="isFetching && !isLoading"
                class="w-full h-full flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <div class="h-10 w-10">
                  <LoaderIcon />
                </div>
              </div>
              <template v-if="isLoading">
                <TableRowSkeleton
                  v-for="item in new Array(paginationStore.state.limit)"
                  :key="item"
                  :columns="3"
                />
              </template>
              <TableRow
                v-for="permission in permissions.items"
                v-else
                :key="permission.id"
                :size="SIZES.MEDIUM"
                :striped="TABLE_STRIPED.STRIPED"
                :bordered="TABLE_BORDERED.BOTTOM"
                class="cursor-pointer"
                :class="{
                  'opacity-30': isFetching,
                }"
                @click="emits('redirect', { id: permission.id, slug: permission.slug, name: permission.name })"
              >
                <TableCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <span class="text-imperium-fg-muted">{{ permission.id }}</span>
                </TableCell>

                <TableCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  class="font-semibold"
                  is-full-width
                  :is-sticky="!isExtraLargeDesktop"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  {{ permission.slug || '—' }}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div
          v-if="!paginationStore.state.total"
          class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
        >
          <div class="font-semibold text-imperium-fg-strong">No permissions found</div>
        </div>

        <div
          v-if="paginationStore.state.total"
          class="py-3 p-4 mt-1"
        >
          <Pagination
            :current-page="paginationStore.state.page"
            :is-mobile="isMobile || isTablet"
            :items-count="paginationStore.state.total"
            :limit="paginationStore.state.limit"
            @change-page="paginationStore.changePage"
            @change-limit="paginationStore.changeLimit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SIZES, SortStatus, TABLE_BORDERED, TABLE_ON_SURFACE, TABLE_SCALE, TABLE_STRIPED } from '@/types';
import { computed, inject, onMounted, watchEffect } from 'vue';
import {
  SortPermissionsInterface,
  SortPermissionsStoreValue,
  usePermissionsFilters,
} from '@/features/Permissions/stores/filters.store';
import { usePermissionsPagination } from '@/features/Permissions/stores/pagination.store';
import type { PermissionsFetchRequest, PermissionsFetchResponse } from '@/features/Permissions/types';
import SortColumnHeader from '@/components/Table/Filters/SortColumnHeader.vue';
import { useFetchPermissions } from '@/features/Permissions/queries';
import { TableRow, Table, TableHead, TableCell, TableHeadCell, TableHeadRow } from '@/components/Table/index';
import Pagination from '@/components/Table/Pagination/Pagination.vue';
import { TableBody } from '@/components/Table';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import { PERMISSIONS_FIELD_ID, PERMISSIONS_FIELD_SLUG } from '@/features/Permissions/constants.ts';
import { debounce } from 'lodash';
import TableRowSkeleton from '@/components/Skeletons/TableRowSkeleton.vue';

interface redirectFieldsInterface {
  id: number;
  slug: string;
  name: string;
}

const props = defineProps<{
  refetch: boolean;
}>();

const emits = defineEmits<{
  (event: 'redirect', payload: redirectFieldsInterface): void;
  (event: 'update-refetch', needToRefetch: boolean): void;
}>();

const isMobile = inject<boolean>('isMobile');
const isTablet = inject<boolean>('isTablet');
const isExtraLargeDesktop = inject<boolean>('isExtraLargeDesktop');

const filtersStore = usePermissionsFilters();
const paginationStore = usePermissionsPagination();

const permissionsSort = computed(() => {
  const sortFields = filtersStore.state.sort;

  if (!Object.keys(sortFields)[0]) {
    return;
  }

  if (!Object.values(sortFields)[0]) {
    return;
  }

  return {
    value: Object.keys(sortFields)[0],
    direction: Object.values(sortFields)[0],
  };
});

const permissionsPayload = computed<PermissionsFetchRequest>(() => {
  const payload = {
    page: paginationStore.state.page,
    itemsPerPage: paginationStore.state.limit,

    searchQuery: filtersStore.state.searchQuery || undefined,
    pagination: true,
  };

  if (permissionsSort.value) {
    payload[`order[${permissionsSort.value.value}]`] = permissionsSort.value.direction;
  }

  return payload;
});

const {
  data: permissions,
  isLoading,
  refetch,
  isFetching,
} = useFetchPermissions(permissionsPayload, (result: PermissionsFetchResponse) => {
  if (!result) {
    return;
  }

  paginationStore.setState({
    page: result.page,
    total: result.total,
  });
});

const updateFilters = debounce(({ filterName, value }: SortPermissionsStoreValue) => {
  const sortValue = {
    [filterName]: value || SortStatus.ASC,
  } as SortPermissionsInterface;
  filtersStore.setSorts(sortValue);
}, 200);

onMounted(() => {
  filtersStore.setSorts({
    id: SortStatus.DESC,
  });
});

watchEffect(() => {
  if (props.refetch) {
    refetch();
    emits('update-refetch', false);
  }
});
</script>
