import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { FiltersState } from '@/features/Users/types';
import { SortStatus } from '@/types';
import { PERMISSIONS_FIELD_ID, PERMISSIONS_FIELD_SLUG } from '@/features/Permissions/constants.ts';
import type { SortsSupport } from '@/features/Categories/stores/filters.store.ts';
import { usePermissionsPagination } from '@/features/Permissions/stores/pagination.store';

export type State = FiltersState;
export interface SortPermissionsInterface {
  [PERMISSIONS_FIELD_ID]?: SortStatus;
  [PERMISSIONS_FIELD_SLUG]?: SortStatus;
}

export interface SortPermissionsStoreValue {
  filterName: SortsSupport;
  value: SortStatus;
}

export const usePermissionsFilters = defineStore('permissionsPageFilters', () => {
  const paginationStore = usePermissionsPagination();

  const state = ref<Partial<State>>({
    sort: {},
    searchQuery: '',
  });

  const setSorts = (sort) => {
    state.value = { ...state.value, sort: { ...sort } } as FiltersState;
  };

  const setSearchQuery = (searchQuery: string) => {
    state.value = { ...state.value, searchQuery };
    paginationStore.setState({ page: 1 });
  };

  return {
    state,
    setSorts,
    setSearchQuery,
  };
});
