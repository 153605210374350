<template>
  <ModalHolder
    data-testid="cover-ikmage-ai-search-modal"
    :size="SIZES.LARGE"
    :type="MODAL_TYPES.POPUP"
    @close="emits('close')"
  >
    <template
      v-if="props.title"
      #title
    >
      <div class="flex items-center">
        <ViewsReversedIcon class="w-6 h-6 mr-2" />
        <h1>{{ props.title }}</h1>
      </div>
    </template>

    <div v-if="!currentImageUrl">
      <div class="relative">
        <FormTextarea
          :model-value="localValue"
          class="mt-4 mb-3"
          min-height="200px"
          name="modal-assignment"
          :max="MAX_AI_COVER_DESCRIPTION_LENGTH"
          :placeholder="t('ai-cover-search.describe-placeholder')"
          @update:model-value="(value) => (localValue = value)"
        >
          <!-- Required for max characters counter -->
          <template #label> &nbsp; </template>
        </FormTextarea>
        <div class="absolute bottom-2 right-2">
          <Button
            v-if="localValue"
            :size="SIZES.XSMALL"
            :visual-type="BUTTON_TYPE.TERTIARY"
            class="mr-2"
            @click="localValue = ''"
          >
            <template #leftIcon>
              <CloseIcon class="w-6 h-6" />
            </template>
          </Button>
          <Button
            :size="SIZES.XSMALL"
            :disabled="!isValueChanged || !localValue"
            @click="handleSearch"
          >
            <template #leftIcon>
              <SearchIcon class="w-6 h-6" />
            </template>
          </Button>
        </div>
      </div>

      <div
        v-if="props.isLoading || isQueryLoading"
        class="min-h-[265px] flex items-center justify-center"
      >
        <Spinner variant="ring" />
        <span class="sr-only">{{ t('ai-cover-search.loading') }}</span>
      </div>

      <div
        v-else-if="searchAICovers.length > 0"
        class="grid grid-cols-3 gap-3 min-h-[265px] items-start"
      >
        <button
          v-for="({ image: imageUrl, isDuplicate }, index) in paginatedCovers"
          :key="`ai-image-${uniquieId}-${index}`"
          type="button"
          class="relative button-image"
          @click="currentImageUrl = imageUrl as string"
        >
          <Badge
            v-if="isDuplicate"
            :id="`ai-image-${uniquieId}-${index}`"
            :is-disabled="false"
            :is-hoverable="false"
            class="absolute top-1 left-1"
            label="Duplicate"
            :size="SIZES.XSMALL"
            :rounded="BADGE_ROUNDED.LARGE"
          >
            <template #icon>
              <InfoIcon class="w-3 h-3 mr-1" />
            </template>
          </Badge>
          <img
            :alt="props.modelValue"
            :src="imageUrl as string"
            class="rounded"
          />
          <span class="button-image__info">{{ t('ai-cover-search.click-to-preview') }}</span>
        </button>
      </div>
      <p
        v-else-if="searchAICovers.length === 0 && hasSearched"
        class="text-center text-[#303D43] text-sm"
      >
        {{ t('ai-cover-search.no-result') }}
      </p>

      <!-- Pagination -->
      <div
        v-if="searchAICovers.length > 0"
        class="flex justify-center py-3 p-4 mt-1"
      >
        <Pagination
          :current-page="paginationStore.state.page"
          :items-count="searchAICovers.length"
          :limit="paginationStore.state.limit"
          :is-info-shown="false"
          :is-limit-visible="false"
          @change-page="(page) => paginationStore.changePage(page)"
        />
      </div>
    </div>

    <transition :name="currentImageUrl ? 'fade' : ''">
      <div v-if="currentImageUrl">
        <img
          :alt="props.modelValue"
          :src="currentImageUrl"
          class="rounded mb-8 mt-4"
        />
        <footer class="flex justify-end">
          <Button
            class="mr-2"
            :visual-type="BUTTON_TYPE.TERTIARY"
            :size="SIZES.MEDIUM"
            @click="currentImageUrl = null"
          >
            {{ t('ai-cover-search.select-another') }}
          </Button>
          <Button
            :size="SIZES.MEDIUM"
            :disabled="imageLoadingState === ImageLoadingState.LOADING"
            @click="uploadImageAction({ url: currentImageUrl })"
          >
            {{ t('ai-cover-search.set-cover') }}
          </Button>
        </footer>
      </div>
    </transition>
  </ModalHolder>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { uniqueId } from 'lodash';
import ModalHolder from '@/components/ModalHolder.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import Button from '@/components/Button.vue';
import Spinner from '@/components/Spinner.vue';
import SearchIcon from '@/assets/icons/search.svg?component';
import CloseIcon from '@/assets/icons/close.svg?component';
import ViewsReversedIcon from '@/assets/icons/views-reversed.svg?component';
import InfoIcon from '@/assets/icons/info-triangle.svg?component';
import Pagination from '@/components/Table/Pagination/Pagination.vue';
import Badge from '@/components/Badge.vue';
import { BUTTON_TYPE, SIZES, MODAL_TYPES, BADGE_ROUNDED } from '@/types';
import { MAX_AI_COVER_DESCRIPTION_LENGTH } from '@/features/ArticleLayout/constants';
import type { CoverImageSuggestion } from '@/features/SearchImageAI/types';
import { useSearchCoverAI } from '@/features/SearchImageAI/queries/useSearchCoverAI';
import { useSimpleAction, useToast } from '@/composables';
import { ImageLoadingState } from '@/features/UploadImage/types';
import { UploadImageService } from '@/features/UploadImage/service/uploadImage';
import { usePagination } from '@/features/SearchImageAI/stores/pagination.store';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    title?: string;
    isLoading?: boolean;
    modelValue?: string;
    images?: CoverImageSuggestion[];
  }>(),
  {
    title: '',
    isLoading: false,
    modelValue: '',
    images: () => [],
  },
);

const emits = defineEmits<{
  (event: 'close'): void;
  (event: 'select-image', id: number): void;
}>();

const uniquieId = uniqueId();

const toast = useToast();

const localValue = ref(props.modelValue);
const hasSearched = ref(false);
const coversAI = ref<CoverImageSuggestion[]>([]);
const isCoverSuggestionsLoading = ref(false);
const currentImageUrl = ref<string | null>(null);

const paginationStore = usePagination();

const TOP_N = 30;
const params = computed(() => ({
  description: localValue.value,
  top_n: TOP_N,
  unique: false,
}));

const { isLoading: isQueryLoading, refetch: refetchSearchCoverAI } = useSearchCoverAI(params, {
  enabled: false,
  onSuccess: (result: { data: any }) => {
    const results = result.data.results.map((item: CoverImageSuggestion) => ({
      ...item,
      isDuplicate: false,
    }));

    const duplicates = result.data.duplicates.map((item: CoverImageSuggestion) => ({
      ...item,
      isDuplicate: true,
    }));

    coversAI.value = [...results, ...duplicates];
  },
});

const handleSearch = () => {
  hasSearched.value = true;
  isCoverSuggestionsLoading.value = true;
  paginationStore.changePage(1);
  refetchSearchCoverAI();
};

const isValueChanged = computed(() => {
  return localValue.value !== props.modelValue;
});

const searchAICovers = computed(() => {
  if (coversAI.value?.length) {
    return coversAI.value.map((cover: CoverImageSuggestion) => ({
      image: cover.download_url,
      isDuplicate: cover.isDuplicate || false,
    }));
  }

  return props.images.map((image) => ({
    image,
    isDuplicate: false,
  }));
});

// Paginated covers
const paginatedCovers = computed(() => {
  const start = (paginationStore.state.page - 1) * paginationStore.state.limit;
  const end = start + paginationStore.state.limit;
  return searchAICovers.value.slice(start, end);
});

const imageLoadingState = ref<ImageLoadingState>(ImageLoadingState.CHOOSE);

const { action: uploadImageAction } = useSimpleAction(async ({ url, file }: { url?: string; file?: File }) => {
  try {
    imageLoadingState.value = ImageLoadingState.LOADING;
    const postData: { url?: string; file?: File } = {};
    if (url) postData.url = url;
    if (file) postData.file = file;

    const result = await UploadImageService.imageGalleryUpload(postData);

    emits('select-image', result.id);

    imageLoadingState.value = ImageLoadingState.SET_ATTRIBUTES;
  } catch (err: any) {
    toast.errorTemporary({
      id: 'ERROR_MEDIA_URL',
      message: err.data?.errorMessage || 'Something went wrong. Please try later',
    });
    imageLoadingState.value = ImageLoadingState.CHOOSE;
    throw err;
  } finally {
    currentImageUrl.value = null;
    emits('close');
  }
});

watch(
  () => props.modelValue,
  (newValue: string) => {
    localValue.value = newValue;
  },
);
</script>

<style lang="scss" scoped>
.button-image {
  $self: &;

  &__info {
    @apply opacity-0 transition-opacity ease-in-out duration-300 absolute bottom-0 left-0 mb-2 text-white text-center text-xs w-full;
  }

  &:before {
    @apply content-[''] absolute w-full bottom-0 left-0 h-[90px] bg-gradient-to-b from-transparent to-black opacity-0 transition-opacity duration-300;
  }

  &:hover {
    #{$self}__info,
    &:before {
      @apply opacity-100;
    }
  }
}

// TODO: Consider removing duplication same fade animation can be found in ImageSuggestions
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity ease-in-out duration-300;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
