import type { EditorsChoicePost } from '@/features/EditorsChoice/types';
import type { Post } from '@/features/Posts/types';
import { defineStore } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import { fetchEditorChoice, putEditorChoice } from '@/features/EditorsChoice/api/api.ts';
import { useToast } from '@/composables';
import * as Sentry from '@sentry/vue';
import { useLayoutAppearance } from '@/composables/useLayoutAppearance.ts';

interface EditorsChoiceStore {
  posts: EditorsChoicePost[] | Post[];
  updatedAt: string;
}

export const useEditorsChoiceStore = defineStore('useEditorsChoiceStore', () => {
  const state = ref<EditorsChoiceStore>({
    posts: [],
    updatedAt: '',
  });
  const toast = useToast();
  const currentLanguageStore = useCurrentLanguageStore();
  const languageId = computed(() => currentLanguageStore.currentLanguage.id);

  const { isEditorsChoicePage } = useLayoutAppearance();
  const fetchEditorsChoice = async () => {
    const data = await fetchEditorChoice(languageId.value);
    setStorePosts(data.items);
    state.value.updatedAt = data.updatedAt;
  };

  const setStorePosts = (posts: EditorsChoicePost | Post) => {
    state.value.posts = posts;
  };

  const removePostFromEditorsChoice = (postId: number) => {
    state.value.posts = state.value.posts.filter((post) => post.id !== postId);
  };

  const addPostToEditorsChoice = (post: Post) => {
    state.value.posts.unshift(post);
  };

  const postAlreadyAdded = (postId: number) => {
    if (!state.value.posts) return false;
    return state.value.posts.some((post) => post.id === postId);
  };

  const updateECPostsOrder = (posts: Array<Post | EditorsChoicePost>) => {
    state.value.posts = posts;
  };

  const updateEditorsChoice = async () => {
    try {
      const orderedList = state.value.posts.map((post) => post.id);

      const params = computed(() => {
        return {
          posts: orderedList,
          languageId: languageId.value,
        };
      });

      await putEditorChoice(params.value);

      toast.success({
        id: 'UPDATE_EDITORS_CHOICE',
        message: 'Editor’s Choice section was updated successfully',
      });
    } catch (e) {
      toast.errorTemporary({
        id: 'UPDATE_EDITORS_CHOICE_ERROR',
        message: e?.data?.message ?? e?.message ?? 'Unable to update editors choice',
      });
      Sentry.captureMessage(String(e));
    }
  };

  const clearState = () => {
    state.value = {
      posts: [],
      updatedAt: '',
    };
  };

  watch(
    () => languageId.value,
    async () => {
      if (isEditorsChoicePage.value) {
        await fetchEditorsChoice();
      }
    },
  );

  return {
    state,
    fetchEditorsChoice,
    setStorePosts,
    removePostFromEditorsChoice,
    addPostToEditorsChoice,
    postAlreadyAdded,
    updateEditorsChoice,
    updateECPostsOrder,
    clearState,
  };
});
