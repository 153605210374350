<template>
  <div>
    <div class="relative">
      <div
        class="relative bg-imperium-bg-sub-base"
        :class="{
          'rounded-2xl': !isMobile,
        }"
      >
        <div>
          <Table
            class="max-w-full relative overflow-x-auto"
            :is-sticky="!isExtraLargeDesktop"
            :top-rounded="!isMobile"
          >
            <TableHead>
              <TableHeadRow
                :size="SIZES.MEDIUM"
                :bordered="TABLE_BORDERED.BOTTOM"
                :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
              >
                <TableHeadCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :is-sticky="true"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="id"
                    class="pt-2.5 pb-2.5"
                    :label="t('tags.table.th-id')"
                    :value="filtersStore.state.sort?.id || SortStatus.DISABLE"
                    @change-sort="filtersStore.setSort('id', $event)"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="title"
                    class="pt-2.5 pb-2.5"
                    :label="t('tags.table.th-title')"
                    :value="filtersStore.state.sort?.title || SortStatus.DISABLE"
                    @change-sort="filtersStore.setSort('title', $event)"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="popular"
                    class="pt-2.5 pb-2.5"
                    :label="t('tags.table.th-post-count')"
                    :value="filtersStore.state.sort?.popular || SortStatus.DISABLE"
                    @change-sort="filtersStore.setSort('popular', $event)"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  {{ t('tags.table.th-controls') }}
                </TableHeadCell>
              </TableHeadRow>
            </TableHead>

            <TableBody>
              <div
                v-if="isFetching && !isLoading"
                class="w-full h-full flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <div class="h-10 w-10">
                  <LoaderIcon />
                </div>
              </div>
              <template v-if="isLoading">
                <TableRowSkeleton
                  v-for="item in new Array(paginationStore.state.limit)"
                  :key="item"
                  :columns="6"
                />
              </template>
              <TableRow
                v-for="tag in tags.items"
                v-else-if="tags?.items && tags.items.length"
                :key="tag.id"
                :size="SIZES.MEDIUM"
                :striped="TABLE_STRIPED.STRIPED"
                :bordered="TABLE_BORDERED.BOTTOM"
                class="cursor-pointer"
                :class="{
                  'opacity-30': isFetching,
                }"
              >
                <TableCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  :class="{
                    'w-full': isLoading,
                  }"
                  is-uppercase
                >
                  <span class="text-imperium-fg-muted">{{ tag.id }}</span>
                </TableCell>

                <TableCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :class="{
                    'w-full': isLoading,
                  }"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <a
                    :href="
                      domainName
                        ? `https://${domainName}/tags/${convertStringToKebabCase(tag.title)}`
                        : `https://cointelegraph.com/tags/${convertStringToKebabCase(tag.title)}`
                    "
                    target="_blank"
                    class="border-b-2 border-imperium-ds-primary-base no-underline cursor-pointer"
                  >
                    {{ tag.title || '—' }}
                  </a>
                </TableCell>

                <TableCell
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  :class="{
                    'w-full': isLoading,
                  }"
                  class="font-semibold"
                  is-uppercase
                >
                  {{ tag.postsCount || 0 }}
                </TableCell>

                <TableCell
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  :class="{
                    'w-full': isLoading,
                  }"
                  class="truncate font-semibold overflow-hidden text-ellipsis"
                  is-full-width
                  is-uppercase
                >
                  <Button
                    :disabled="!tagsPageEdit"
                    @click="redirectToTag(tag.id)"
                  >
                    <template #rightIcon>
                      <EditIcon class="w-5 h-5" />
                    </template>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div
          v-if="!isLoading && !paginationStore.state.total"
          class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
        >
          <div class="font-semibold text-imperium-fg-strong">{{ t('tags.no-tags-found') }}</div>
        </div>

        <div
          v-if="paginationStore.state.total"
          class="py-3 p-4 mt-1"
        >
          <Pagination
            :current-page="paginationStore.state.page"
            :is-mobile="isMobile || isTablet"
            :items-count="paginationStore.state.total"
            :limit="paginationStore.state.limit"
            @change-page="paginationStore.changePage"
            @change-limit="paginationStore.changeLimit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import LoaderIcon from '@/assets/icons/spinner.svg?component';
import TableRowSkeleton from '@/components/Skeletons/TableRowSkeleton.vue';

import { useUserPermissions } from '@/stores/user.store';
import { useTagsFilters } from '@/features/Tags/stores/filters.store';
import { useTagsPagination } from '@/features/Tags/stores/pagination.store';
import Pagination from '@/components/Table/Pagination/Pagination.vue';
import Button from '@/components/Button.vue';
import { useFetchTags } from '@/features/Tags/queries';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import { useWebsiteDomainStore } from '@/stores/domain.store.ts';
const languageId = computed(() => currentLanguage.value.id);
const domainName = ref<string | null>(null);
const domainStore = useWebsiteDomainStore();
import { convertStringToKebabCase } from '@/utils/url';

import { TABLE_VALIGN } from '@/types';

import type { TagsFetchRequest, TagsFetchResponse, TagsPayload } from '@/features/Tags/types';

import { TABLE_BORDERED, TABLE_ON_SURFACE, TABLE_STRIPED, TABLE_SCALE, SIZES, SortStatus } from '@/types';

import { Table, TableBody, TableHead, TableCell, TableHeadCell, TableRow, TableHeadRow } from '@/components/Table';
import SortColumnHeader from '@/components/Table/Filters/SortColumnHeader.vue';
import EditIcon from '@/assets/icons/edit-icon.svg?component';

const currentLanguageStore = useCurrentLanguageStore();
const currentLanguage = computed(() => currentLanguageStore.currentLanguage);

const { tagsPageEdit } = useUserPermissions();

const { t } = useI18n();
const router = useRouter();

const isMobile = inject<boolean>('isMobile');
const isTablet = inject<boolean>('isTablet');
const isExtraLargeDesktop = inject<boolean>('isExtraLargeDesktop');

const filtersStore = useTagsFilters();
const paginationStore = useTagsPagination();

const filters = computed(() => ({
  searchQuery: filtersStore.state.searchQuery || '',
  sort: {
    id: (filtersStore.state.sort?.id as SortStatus) || SortStatus.DISABLE,
    title: (filtersStore.state.sort?.title as SortStatus) || SortStatus.DISABLE,
    popular: (filtersStore.state.sort?.popular as SortStatus) || SortStatus.DISABLE,
  },
}));

const tagsPayload = computed<TagsFetchRequest>(() => {
  const payload: TagsPayload = {
    languageId: currentLanguage.value.id,
    page: paginationStore.state.page,
    itemsPerPage: paginationStore.state.limit,
    pagination: true,
  };

  payload['filter[search]'] ??= filters.value.searchQuery || undefined;
  payload['order[title]'] ??= filters.value.sort?.title || undefined;
  payload['order[id]'] ??= filters.value.sort?.id || undefined;
  payload['order[popular]'] ??= filters.value.sort?.popular || undefined;

  return payload as TagsFetchRequest;
});

const {
  data: tags,
  isLoading,
  isFetching,
} = useFetchTags(tagsPayload, (result: TagsFetchResponse) => {
  if (!result) {
    return;
  }

  paginationStore.setState({
    page: result.page,
    total: result.total,
  });
});

const redirectToTag = (tagId: number) => {
  router.push({
    name: 'tag',
    params: {
      id: tagId,
    },
  });
};

onMounted(async () => {
  filtersStore.initFromQuery();
  domainName.value = await domainStore.getDomain(languageId.value);
});
</script>
