<template>
  <FormInput
    :model-value="props.url"
    :name="props.name"
    type="text"
    :size="props.size"
    :attrs="props.urlAttrs"
    :is-readonly="props.isReadonly"
    :is-disabled="props.isDisabled"
    :is-required="props.isRequired"
    :is-errored="props.isErrored"
    :placeholder="props.placeholder"
    @update:model-value="onUrlChange"
  >
    <template
      v-if="props.label"
      #label
    >
      {{ props.label }}
    </template>

    <template #prefix>
      <ChainIcon class="w-5 h-5 text-imperium-fg-muted" />
    </template>

    <template #suffix>
      <EditIcon class="w-5 h-5 text-imperium-fg-muted" />
    </template>

    <template #error>
      <slot name="error" />
    </template>

    <template
      v-if="urlOrigin"
      #help
    >
      <a
        v-if="props.postUrl"
        :href="`https://${props.urlOrigin}${props.postUrl}`"
        target="_blank"
        class="border-b-2 border-imperium-ds-primary-base no-underline cursor-pointer"
      >
        {{ props.urlOrigin }}{{ props.url }}
      </a>
      <span
        v-else
        class="break-all"
      >
        {{ props.urlOrigin }}{{ props.url }}
      </span>
    </template>
  </FormInput>
</template>

<script lang="ts" setup>
import FormInput from '@/components/FormInput.vue';

import EditIcon from '@/assets/icons/edit-icon.svg?component';
import ChainIcon from '@/assets/icons/chain.svg?component';

import { convertStringToKebabCase } from '@/utils/url';
import { SIZES } from '@/types';

const props = withDefaults(
  defineProps<{
    url: string;
    name: string;
    label?: string;
    urlAttrs?: Record<string, unknown>;
    postUrl?: string;
    size: SIZES;
    isReadonly: boolean;
    isDisabled?: boolean;
    urlOrigin?: string;
    placeholder?: string;
    isRequired: boolean;
    isErrored?: boolean;
  }>(),
  {
    size: SIZES.SMALL,
    isReadonly: true,
    isDisabled: false,
    placeholder: '',
    isRequired: false,
    isErrored: false,
  },
);

const emits = defineEmits<{
  (event: 'update:model-value', value: string | null): void;
  (event: 'update:url', value: string | null): void;
}>();

const onUrlChange = (val: string) => {
  if (props.isReadonly) {
    return;
  }
  emits('update:url', convertStringToKebabCase(val) || null);
};
</script>
