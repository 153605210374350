import { extractData, http } from '@/http';
import type { TagsFetchRequest, TagsFetchResponse, TagByIdFetchRequest, Tag } from '@/features/Tags/types';

export const tags = (params: TagsFetchRequest): Promise<TagsFetchResponse> => {
  return http.get<TagsFetchResponse>('/tags', { params, useAuth: true }).then(extractData);
};

export const tagFetchById = (params: TagByIdFetchRequest): Promise<Tag> => {
  const { id, languageId } = params;
  return http.get<Tag>(`/tags/${id}/language/${languageId}`, { useAuth: true }).then(extractData);
};

export const tagCreate = (postBody: Omit<Tag, 'id'>): Promise<Tag> => {
  return http.post<Tag>('/tags', postBody, { useAuth: true }).then(extractData);
};

export const tagEdit = (id: number, languageId: number, patchBody: Tag): Promise<Tag> =>
  http.patch<Tag>(`/tags/${id}/language/${languageId}`, patchBody, { useAuth: true }).then(extractData);
