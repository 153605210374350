<script setup lang="ts">
import { BUTTON_COLOR, BUTTON_TYPE } from '@/types';
import OpenLinkIcon from '@/assets/icons/open-link.svg?component';

const props = withDefaults(
  defineProps<{
    url: string;
    target?: string;
  }>(),
  {
    target: '_blank',
  },
);
</script>

<template>
  <a
    :color="BUTTON_COLOR.BASE"
    :visual-type="BUTTON_TYPE.PLAIN"
    :target="props.target"
    :href="props.url"
    class="group"
  >
    <div class="flex gap-1 font-semibold text-imperium-ds-secondary-base items-center">
      <span class="text-sm group-hover:text-black group-hover:border-b-2 group-hover:border-b-imperium-ds-primary-base">
        Link
      </span>
      <OpenLinkIcon class="w-4 h-4" />
    </div>
  </a>
</template>

<style scoped lang="scss"></style>
