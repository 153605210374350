import { createRouter, createWebHistory } from 'vue-router';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ForgotPasswordConfirmation from '@/views/ForgotPasswordConfirmation.vue';
import CreateNewPassword from '@/views/CreateNewPassword.vue';
import UserTemporarilyBlocked from '@/views/UserTemporarilyBlocked.vue';

import Articles from '@/views/Articles.vue';
import Article from '@/views/Article.vue';
import Dashboard from '@/views/Dashboard.vue';
import Media from '@/views/Media.vue';
import Users from '@/views/Users.vue';
import Settings from '@/views/Settings.vue';
import Profile from '@/views/Profile.vue';
import Roles from '@/views/Roles.vue';
import Role from '@/views/Role.vue';
import Permissions from '@/views/Permissions.vue';
import Authors from '@/views/Authors.vue';
import Author from '@/views/Author.vue';
import Tags from '@/views/Tags.vue';
import TagsCreate from '@/features/Tags/views/TagsCreate.vue';
import TagsEdit from '@/features/Tags/views/TagsEdit.vue';

import { dynamicTitleGuard } from '@/features/DynamicTitle';
import { authGuard } from '@/features/Auth/guards';
import CreateNewPasswordInvitation from '@/features/Auth/views/CreateNewPasswordInvitation.vue';

import { useToast } from '@/composables/useToast';
import NotFound from '@/views/NotFound.vue';
import { UserPermissions } from '@/types';
import TwoFASetting from '@/features/Auth/views/TwoFASetting.vue';
import TwoFACode from '@/features/Auth/views/TwoFACode.vue';
import TwoFASuccess from '@/features/Auth/views/TwoFASuccess.vue';
import { isAccessGranted } from '@/utils/user';
import Categories from '@/features/Categories/views/Categories.vue';
import CategoriesEdit from '@/features/Categories/views/CategoriesEdit.vue';
import CategoriesCreate from '@/features/Categories/views/CategoriesCreate.vue';
import LabelsEdit from '@/features/Labels/views/LabelsEdit.vue';
import Labels from '@/features/Labels/views/Labels.vue';
import Testing from '@/views/Testing.vue';
import ConvertersList from '@/views/ConvertersList.vue';
import ConverterPage from '@/views/ConverterPage.vue';
import EditorsChoice from '@/views/EditorsChoice.vue';

export const router = createRouter({
  history: createWebHistory(),

  routes: [
    {
      name: 'Login',
      path: '/auth/login',
      component: Login,
      meta: {
        title: 'Login',
        requiresGuest: true,
      },
    },
    {
      name: 'Set2FA',
      path: '/auth/2fa',
      component: TwoFASetting,
      meta: {
        title: 'Two-factor authentication Settings',
        requiresGuest: true,
      },
    },
    {
      name: 'Authentication code',
      path: '/auth/2fa-code',
      component: TwoFACode,
      meta: {
        title: 'Two-factor Authentication code',
        requiresGuest: true,
      },
    },
    {
      name: 'Authentication success',
      path: '/auth/2fa-success',
      component: TwoFASuccess,
      meta: {
        title: 'Two-factor Authentication success',
        requiresGuest: true,
      },
    },
    {
      name: 'ForgotPassword',
      path: '/auth/forgot-password',
      component: ForgotPassword,
      meta: {
        title: 'Login',
        requiresGuest: true,
      },
    },
    {
      name: 'PasswordResetted',
      path: '/auth/forgot-password-success',
      component: ForgotPasswordConfirmation,
      meta: {
        title: 'Login',
        requiresGuest: true,
      },
    },
    {
      name: 'ResetPassword',
      path: '/auth/forgot-password/:token',
      component: CreateNewPassword,
      meta: {
        title: 'Login',
        requiresGuest: true,
      },
    },
    {
      name: 'CreatePassword',
      path: '/auth/invitation/:token?',
      component: CreateNewPasswordInvitation,
      meta: {
        title: 'Invitation',
        requiresGuest: true,
      },
    },
    {
      name: 'UserTemporarilyBlocked',
      path: '/auth/blocked',
      component: UserTemporarilyBlocked,
      meta: {
        title: 'You are blocked',
      },
    },

    {
      name: 'dashboard',
      path: '/',
      component: Dashboard,
      meta: {
        auth: true,
        title: 'Editorial Dashboard',
      },
    },
    {
      name: 'articles',
      path: '/articles',
      children: [
        {
          path: '',
          component: Articles,
          meta: {
            auth: true,
            title: 'Articles Management',
          },
        },
        {
          name: 'article',
          path: ':id',
          component: Article,
          meta: {
            auth: true,
            title: 'Articles Management',
          },
        },
      ],
    },
    {
      name: 'media',
      path: '/media',
      component: Media,
      meta: {
        auth: true,
        title: 'Media Library',
        permissionPath: UserPermissions.MEDIA_PAGE,
      },
    },
    {
      name: 'editors-choice',
      path: '/editors-choice',
      component: EditorsChoice,
      meta: {
        auth: true,
        title: "Editor's choice",
        permissionPath: UserPermissions.EDITORS_CHOICE_PAGE,
      },
    },
    {
      name: 'users',
      path: '/users',
      component: Users,
      meta: {
        auth: true,
        title: 'User Management',
        permissionPath: UserPermissions.USERS_PAGE,
      },
    },

    {
      name: 'roles',
      path: '/roles',
      children: [
        {
          path: '',
          component: Roles,
          meta: {
            auth: true,
            title: 'User Roles',
            permissionPath: UserPermissions.USERS_PAGE,
          },
        },
        {
          name: 'roles-create',
          path: 'create',
          component: Role,
          meta: {
            auth: true,
            title: 'Create role',
            permissionPath: UserPermissions.USERS_PAGE,
          },
        },
        {
          name: 'roles-edit',
          path: ':id',
          component: Role,
          meta: {
            auth: true,
            title: 'Edit role',
            permissionPath: UserPermissions.USERS_PAGE,
          },
        },
      ],
    },
    {
      name: 'permissions',
      path: '/permissions',
      children: [
        {
          path: '',
          component: Permissions,
          meta: {
            auth: true,
            title: 'User Permissions',
            permissionPath: UserPermissions.USERS_PAGE,
          },
        },
      ],
    },
    {
      name: 'categories',
      path: '/categories',
      children: [
        {
          path: '',
          component: Categories,
          meta: {
            auth: true,
            title: 'Categories',

            permissionPath: UserPermissions.CATEGORY_PAGE,
          },
        },
        {
          name: 'categories-create',
          path: 'create',
          component: CategoriesCreate,
          meta: {
            auth: true,
            title: 'Create category',

            permissionPath: UserPermissions.CATEGORY_PAGE,
          },
        },
        {
          name: 'categories-edit',
          path: ':id',
          component: CategoriesEdit,
          meta: {
            auth: true,
            title: 'Category',
            // TODO: Update permissions
            permissionPath: UserPermissions.CATEGORY_PAGE,
          },
        },
      ],
    },
    {
      name: 'labels',
      path: '/labels',
      children: [
        {
          path: '',
          component: Labels,
          meta: {
            auth: true,
            title: 'Labels',
            permissionPath: UserPermissions.LABEL_PAGE,
          },
        },
        {
          name: 'labels-create',
          path: 'create',
          component: LabelsEdit,
          meta: {
            auth: true,
            title: 'Create label',
            permissionPath: UserPermissions.LABEL_PAGE,
          },
        },
        {
          name: 'labels-edit',
          path: ':id',
          component: LabelsEdit,
          meta: {
            auth: true,
            title: 'Label',
            permissionPath: UserPermissions.LABEL_PAGE,
          },
        },
      ],
    },
    {
      name: 'converter',
      path: '/converters',
      children: [
        {
          path: '',
          component: ConvertersList,
          meta: {
            auth: true,
            title: 'Converter',
            permissionPath: UserPermissions.CONVERTER_PAGE,
          },
        },
        {
          name: 'converter-new',
          path: 'new',
          component: ConverterPage,
          meta: {
            auth: true,
            title: 'Converter New',
            permissionPath: UserPermissions.CONVERTER_PAGE,
          },
        },
        {
          name: 'converter-edit',
          path: ':id',
          component: ConverterPage,
          meta: {
            auth: true,
            title: 'Converter Edit',
            permissionPath: UserPermissions.CONVERTER_PAGE,
          },
        },
      ],
    },

    {
      name: 'authors',
      path: '/authors',
      children: [
        {
          path: '',
          component: Authors,
          meta: {
            auth: true,
            title: 'Authors Management',
            permissionPath: UserPermissions.AUTHOR_PAGE,
          },
        },
        {
          name: 'author-create',
          path: 'create',
          component: Author,
          meta: {
            auth: true,
            title: 'Create author',
            permissionPath: UserPermissions.AUTHOR_PAGE,
          },
        },
        {
          name: 'author',
          path: ':id',
          component: Author,
          meta: {
            auth: true,
            title: 'Authors Management',
            permissionPath: UserPermissions.AUTHOR_PAGE,
          },
        },
      ],
    },

    {
      name: 'tags',
      path: '/tags',
      children: [
        {
          path: '',
          component: Tags,
          meta: {
            auth: true,
            title: 'Tags Management',
            permissionPath: UserPermissions.TAGS_PAGE,
          },
        },
        {
          name: 'tag-create',
          path: 'create',
          component: TagsCreate,
          meta: {
            auth: true,
            title: 'Create tag',
            permissionPath: UserPermissions.TAGS_PAGE,
          },
        },
        {
          name: 'tag',
          path: ':id',
          component: TagsEdit,
          meta: {
            auth: true,
            title: 'Tags Management',
            permissionPath: UserPermissions.TAGS_PAGE,
          },
        },
      ],
    },

    {
      name: 'settings',
      path: '/settings',
      component: Settings,
      meta: {
        auth: true,
        title: 'Settings',
      },
    },
    {
      name: 'profile',
      path: '/profile',
      component: Profile,
      meta: {
        auth: true,
        title: 'Editorial Profile',
      },
    },
    {
      // Not important page
      name: 'testing',
      path: '/testing',
      component: Testing,
    },
    {
      name: 'notFound',
      path: '/:catchAll(.*)*',
      component: NotFound,
    },
  ],
});

// Global Resolve Guards

router.beforeEach(authGuard);
router.beforeEach(dynamicTitleGuard);

router.beforeResolve(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta.permissionPath) {
    try {
      if (to.meta.permissionPath && isAccessGranted(to.meta.permissionPath).view) {
        next();
        return;
      } else {
        next({ path: '/' });
        return;
      }
    } catch (error: any) {
      if (error) {
        throw error;
      }
    }
  } else {
    next();
    return;
  }
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const toast = useToast();
  toast.clearAll();
  next();
});
