<template>
  <ModalHolder
    :is-visible="props.isVisible"
    :size="SIZES.MEDIUM"
    data-testid="dashboard-chief-message-modal"
    @close="emits('close')"
  >
    <template #title> Invite via email new coworker</template>

    <div class="relative w-full max-h-full">
      <form @submit="onSubmit">
        <div class="px-4 pt-4 pb-3">
          <FormInput
            v-model="userName"
            :attrs="userNameAttrs"
            :is-errored="isUserNameErrored"
            class="w-full mb-2"
            is-required
            name="userName"
            placeholder="Name Surname"
            type="text"
          >
            <template #label> User's name</template>
            <template #error>
              {{ errors.userName }}
            </template>
          </FormInput>

          <FormInput
            v-model="email"
            :attrs="emailAttrs"
            :is-errored="isEmailErrored"
            class="w-full mb-2"
            is-required
            name="email"
            placeholder="Email"
            type="text"
          >
            <template #label> Email address</template>
            <template #error>
              {{ errors.email }}
            </template>
          </FormInput>

          <FormMultiDropdown
            v-model="roles"
            :attrs="rolesAttrs"
            :is-errored="isRoleErrored"
            :values="rols"
            class="mb-2"
            is-required
            name="roles"
            placeholder="Search user's roles in the list"
          >
            <template #label> Role</template>
            <template #error>
              {{ errors.roles }}
            </template>
          </FormMultiDropdown>

          <FormSelect
            v-if="usersPageShiftView"
            :attrs="shiftAttrs"
            :model-value="shift"
            class="w-full mb-4 sm:mb-1"
            name="shift"
            :values="SHIFT_OPTIONS"
            :size="SIZES.SMALL"
            :is-disabled="!usersPageShiftEdit"
            data-anchor="field-shift"
            @update:model-value="(value) => (shift = value)"
          >
            <template #label><span class="input-meta-text-md">Shift</span></template>
          </FormSelect>

          <FormMultiDropdown
            v-if="userLanguageView"
            v-model="languages"
            :attrs="languagesAttrs"
            :is-errored="isLanguageErrored"
            :values="langs"
            class="mb-2"
            :is-disabled="!userLanguageEdit"
            is-required
            name="languages"
          >
            <template #label> Language access</template>
            <template #error>
              {{ errors.language }}
            </template>
          </FormMultiDropdown>
        </div>

        <div class="flex items-center justify-center px-4 pb-2">
          <Button
            :is-loading="isLoading"
            :size="SIZES.MEDIUM"
            is-full-width
            data-testid="invite-submit"
            type="submit"
          >
            Send an invitation
          </Button>
        </div>
      </form>
    </div>
  </ModalHolder>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { toTypedSchema } from '@vee-validate/zod';

import Button from '@/components/Button.vue';
import FormInput from '@/components/FormInput.vue';
import FormMultiDropdown from '@/components/FormMultiDropdown.vue';
import FormSelect from '@/components/FormSelect.vue';
import ModalHolder from '@/components/ModalHolder.vue';

import { userInvitationFormValidate } from '@/features/Users/components/UserInvitation/validation/userInvitationForm';

import { useSimpleAction } from '@/composables';
import { useFormData } from '@/composables/useFormData';
import { UsersService } from '@/features/Users/service';
import { useToast } from '@/composables/useToast';
import UserInviteErrorToast from '@/features/Users/components/UserInvitation/toasts/UserInviteErrorToast.vue';
import { useUserPermissions } from '@/stores/user.store';

import { SIZES } from '@/types';
import type { Language } from '@/types/languages';
import { type UserInvitationPayload } from '@/features/Users/types';
import type { Role } from '@/features/Roles/types';
import { SHIFT_OPTIONS } from '@/features/Users/constants';

const { usersPageShiftView, usersPageShiftEdit, userLanguageEdit, userLanguageView } = useUserPermissions();

const DEFAULT_USER_INVITE_DATA = {
  userName: '',
  email: '',
  roles: [],
  languages: [1],
  shift: SHIFT_OPTIONS[SHIFT_OPTIONS.length - 1].id,
};

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    rolesList: Role[];
    languagesList: Language[];
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'close'): void;
  (event: 'invite'): void;
}>();

const toast = useToast();

const { defineField, errors, handleSubmit, resetForm } = useFormData({
  data: DEFAULT_USER_INVITE_DATA,
  validator: toTypedSchema(userInvitationFormValidate),
});

const [userName, userNameAttrs] = defineField('userName');
const [email, emailAttrs] = defineField('email');
const [roles, rolesAttrs] = defineField('roles');
const [languages, languagesAttrs] = defineField('languages');
const [shift, shiftAttrs] = defineField('shift');

const isUserNameErrored = computed(() => !!errors.value.userName);
const isEmailErrored = computed(() => !!errors.value.email);
const isRoleErrored = computed(() => !!errors.value.roles);
const isLanguageErrored = computed(() => !!errors.value.languages);

const rols = computed(() => {
  if (props.rolesList) {
    return (
      props.rolesList?.map((item: Role) => ({
        id: item.id,
        label: item.name,
      })) || []
    );
  }

  return [];
});

const langs = computed(() => {
  if (props.languagesList) {
    return (
      props.languagesList?.map((item: Language) => ({
        id: item.id,
        label: item.title,
      })) || []
    );
  }

  return [];
});

const { isLoading, action } = useSimpleAction(async (values: UserInvitationPayload) => {
  try {
    await UsersService.userInvitation(values);

    toast.success({ id: 'SUCCESS_INVITE', message: `${values.email} has been invited` });
    resetForm();
    emits('invite');
  } catch (err: any) {
    toast.errorTemporary({ id: 'ERROR_INVITE', message: err.data.errorMessage }, UserInviteErrorToast);
    throw err;
  }
});

const onSubmit = handleSubmit((values) => action(values));
</script>
