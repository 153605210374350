<template>
  <div>
    <div class="relative">
      <div
        class="relative bg-imperium-bg-sub-base"
        :class="{
          'rounded-2xl': !isMobile,
        }"
      >
        <div>
          <Table
            class="max-w-full relative overflow-x-auto"
            :is-sticky="!isExtraLargeDesktop"
            top-rounded
          >
            <TableHead>
              <TableHeadRow
                :size="SIZES.MEDIUM"
                :bordered="TABLE_BORDERED.BOTTOM"
                :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
              >
                <TableHeadCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :is-sticky="true"
                  is-uppercase
                >
                  <SortColumnHeader
                    :id="CATEGORY_FIELD_ID"
                    label="Id"
                    class="p-2"
                    :value="filtersStore.state.sort?.[CATEGORY_FIELD_ID]"
                    @change-sort="updateFilters({ filterName: CATEGORY_FIELD_ID, value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                  :class="{
                    'w-full': props.isLoading,
                  }"
                >
                  <SortColumnHeader
                    :id="CATEGORY_FIELD_NAME"
                    label="Category"
                    class="p-2"
                    :value="filtersStore.state.sort?.[CATEGORY_FIELD_NAME]"
                    @change-sort="updateFilters({ filterName: CATEGORY_FIELD_NAME, value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                  :class="{
                    'w-full': props.isLoading,
                  }"
                >
                  <SortColumnHeader
                    :id="CATEGORY_FIELD_SHOW_ON_WEBSITE"
                    label="Visibility on site"
                    class="p-2"
                    :value="filtersStore.state.sort?.[CATEGORY_FIELD_SHOW_ON_WEBSITE]"
                    @change-sort="updateFilters({ filterName: CATEGORY_FIELD_SHOW_ON_WEBSITE, value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                  :class="{
                    'w-full': props.isLoading,
                  }"
                >
                  <p class="p-2">Label</p>
                </TableHeadCell>

                <TableHeadCell
                  :size="SIZES.XLARGE"
                  :scale="TABLE_SCALE.TRIPLE"
                  is-uppercase
                  :class="{
                    'w-full': props.isLoading,
                  }"
                >
                  Category Description
                </TableHeadCell>
              </TableHeadRow>
            </TableHead>

            <TableBody>
              <div
                v-if="props.isFetching && !props.isLoading"
                class="w-full h-full flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <div class="h-10 w-10">
                  <LoaderIcon />
                </div>
              </div>
              <template v-if="props.isLoading">
                <TableRowSkeleton
                  v-for="item in new Array(paginationStore.state.limit)"
                  :key="item"
                  :columns="6"
                />
              </template>
              <TableRow
                v-for="category in props.categories"
                v-else
                :key="category[CATEGORY_FIELD_ID]"
                :size="SIZES.MEDIUM"
                :striped="TABLE_STRIPED.STRIPED"
                :bordered="TABLE_BORDERED.BOTTOM"
                class="cursor-pointer"
                :class="{
                  'opacity-30': props.isFetching,
                }"
                @click="emits('redirect', category[CATEGORY_FIELD_ID])"
              >
                <TableCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  :is-sticky="true"
                  is-uppercase
                >
                  <span class="text-imperium-fg-muted">{{ category[CATEGORY_FIELD_ID] }}</span>
                </TableCell>

                <TableCell
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                  class="font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap"
                >
                  {{ category[CATEGORY_FIELD_NAME] || '—' }}
                </TableCell>
                <TableCell
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <CategoriesTableVisibleBadge
                    :category-id="category[CATEGORY_FIELD_ID]"
                    :enabled="category[CATEGORY_FIELD_SHOW_ON_WEBSITE] || false"
                  />
                </TableCell>
                <TableCell
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <CategoriesTableLabelBadges
                    v-if="category[CATEGORY_FIELD_LABELS]"
                    :badges="category[CATEGORY_FIELD_LABELS]"
                  />
                </TableCell>
                <TableCell
                  :size="SIZES.LARGE"
                  :scale="TABLE_SCALE.QUADRUPLE"
                  is-uppercase
                  :vertical-align="TABLE_VALIGN.TOP"
                >
                  <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                  <div
                    :class="$style.descriptionColumn"
                    class="overflow-x-hidden whitespace-nowrap overflow-ellipsis w-full"
                    v-html="category[CATEGORY_FIELD_DESCRIPTION] || '—'"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div
          v-if="!isLoading && !props.total"
          class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
        >
          <div class="font-semibold text-imperium-fg-strong">No categories found</div>
        </div>

        <div
          v-if="paginationStore.state.total"
          class="py-3 p-4 mt-1"
        >
          <Pagination
            :current-page="paginationStore.state.page || 1"
            :is-mobile="isMobile || isTablet"
            :items-count="paginationStore.state.total || 0"
            :limit="paginationStore.state.limit"
            @change-page="paginationStore.changePage"
            @change-limit="paginationStore.changeLimit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue';

import Pagination from '@/components/Table/Pagination/Pagination.vue';

import { SIZES, SortStatus, TABLE_BORDERED, TABLE_ON_SURFACE, TABLE_SCALE, TABLE_STRIPED, TABLE_VALIGN } from '@/types';

import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableHeadRow, TableRow } from '@/components/Table';
import SortColumnHeader from '@/components/Table/Filters/SortColumnHeader.vue';
import {
  CATEGORY_API_FIELD_LABELS,
  CATEGORY_FIELD_DESCRIPTION,
  CATEGORY_FIELD_ID,
  CATEGORY_FIELD_LABELS,
  CATEGORY_FIELD_NAME,
  CATEGORY_FIELD_SHOW_ON_WEBSITE,
} from '@/features/Categories/constants';
import type { CategoryTranslate } from '@/features/Categories/types';
import {
  SortInterface,
  SortStoreValue,
  useCategoryTranslatesFilters,
} from '@/features/Categories/stores/filters.store';
import { useCategoryTranslatesPagePagination } from '@/features/Categories/stores/pagination.store';
import TableRowSkeleton from '@/components/Skeletons/TableRowSkeleton.vue';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import CategoriesTableVisibleBadge from '@/features/Categories/components/CategoriesTableVisibleBadge.vue';
import CategoriesTableLabelBadges from '@/features/Categories/components/CategoriesTableLabelBadges.vue';
import { debounce } from 'lodash';

const props = withDefaults(
  defineProps<{
    categories: Array<CategoryTranslate> | null;
    isLoading: boolean;
    isFetching: boolean;
    total: number;
  }>(),
  {
    isLoading: false,
    isFetching: false,
    categories: null,
  },
);

const emits = defineEmits<{
  (event: 'redirect', roleId: number): void;
}>();

const isMobile = inject<boolean>('isMobile');
const isTablet = inject<boolean>('isTablet');
const isExtraLargeDesktop = inject<boolean>('isExtraLargeDesktop');

const filtersStore = useCategoryTranslatesFilters();
const paginationStore = useCategoryTranslatesPagePagination();

const updateFilters = debounce(({ filterName, value }: SortStoreValue) => {
  const sortValue = {
    [filterName]: value ? value : SortStatus.ASC,
  } as SortInterface;
  filtersStore.setSorts(sortValue);
}, 500);
</script>
<style module>
.descriptionColumn {
  :global(*) {
    display: inline;
  }
}
</style>
