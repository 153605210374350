import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import type { RolesFetchRequest, RolesFetchResponse } from '../types';
import type { ComputedRef } from 'vue';
import { ROLES_RESOURCE } from '@/features/Roles/constants';
import { fetchRoles } from '@/features/Roles/api';

type OnSuccessCallback = (result: RolesFetchResponse) => void;

export const useFetchRoles = (params: ComputedRef<RolesFetchRequest>, onSuccess: OnSuccessCallback | null = null) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [ROLES_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: RolesFetchRequest | null = queryKey[1] as RolesFetchRequest | null;

      const result = await fetchRoles(payload);

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    },

    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    gcTime: 0,
    staleTime: 0,
    refetchInterval: false,
    refetchOnMount: 'always',
    placeholderData: keepPreviousData,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};
