import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m11.35 14.05 5.34 5.34a.5.5 0 0 0 .706 0l.778-.779a.5.5 0 0 0 0-.707L4.881 4.611a.5.5 0 0 0-.707 0l-.778.778a.5.5 0 0 0 0 .707l5.9 5.9L6.98 21h2.582zM13.68 5l-1.288 5.001-2.054-2.054L11.097 5H7.39l-2-2h15.394l-.5 2z"
    }, null, -1)
  ])))
}
export default { render: render }