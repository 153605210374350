<template>
  <div class="relative">
    <div
      :class="{
        'flex space-x-2 mb-0': props.labelPosition === INPUT_LABEL_POSITION.LEFT,
      }"
    >
      <label
        v-if="isLabel"
        class="w-fit mb-2"
        :class="
          labelClass({
            size: props.size,
            labelPosition: props.labelPosition,
            isDisabled: props.isDisabled,
            isErrored: props.isErrored,
            isSuccess: props.isSuccess,
          })
        "
        :style="{ 'min-width': props.labelWidth }"
      >
        <slot name="label" />

        <template v-if="props.isRequired"> *</template>
      </label>

      <div
        :class="{
          'w-full': props.labelPosition === INPUT_LABEL_POSITION.LEFT,
        }"
        class="relative"
      >
        <Dropdown
          :id="props.id || `form-select-${props.name}`"
          ref="dropdownElement"
          :classes="{
            button:
              'relative text-white focus:ring-imperium-ds-primary-strong focus:border-imperium-ds-primary-strong focus:outline-none rounded-lg text-sm inline-flex items-center w-full',
            dropdown: `hidden bg-white rounded-lg shadow w-full`,
          }"
          :class="{
            'pointer-events-none': props.isDisabled,
          }"
          :dropdown-z-index="props.dropdownZIndex"
          @open="isDropdownVisible = true"
          @close="onDropdownClose"
        >
          <template #button>
            <div
              v-show="!isDropdownVisible"
              class="pr-5"
              :class="
                inputClass({
                  size: props.size,
                  rounded: props.rounded,
                  visualType: props.visualType,
                  isErrored: props.isErrored,
                  isDisabled: props.isDisabled,
                  isSuccess: props.isSuccess,
                })
              "
            >
              <div
                v-if="isLeftIcon"
                class="absolute left-3 inset-y-0 flex items-center pointer-events-none"
              >
                <slot name="icon" />
              </div>

              <p
                v-if="!selectedValue"
                :class="{
                  'pl-8': isLeftIcon && props.size === SIZES.SMALL,
                  'pl-10': isLeftIcon && props.size === SIZES.MEDIUM,
                }"
                class="text-imperium-fg-subtle"
              >
                {{ props.placeholder }}
              </p>
              <slot
                v-else
                name="selectedValue"
                :item="selectedValue"
              >
                <p
                  :class="{
                    'pl-8': isLeftIcon && props.size === SIZES.SMALL,
                    'pl-10': isLeftIcon && props.size === SIZES.MEDIUM,
                  }"
                  class="text-imperium-fg-input truncate"
                >
                  {{ selectedValue[props.labelKey] }}
                </p>
              </slot>
            </div>

            <FormInput
              v-show="isDropdownVisible"
              ref="searchInputElement"
              :model-value="search"
              type="text"
              :name="`form-select-search-${props.name}`"
              class="w-full"
              :placeholder="props.modelValue ? 'Search...' : props.placeholder"
              :is-errored="false"
              :size="props.size"
              @update:model-value="onSearch"
            >
              <template
                v-if="isLeftIcon"
                #prefix
              >
                <slot name="icon" />
              </template>
              <template #suffix />
            </FormInput>
            <ArrowDown
              v-if="!search && !props.modelValue"
              class="w-5 h-5 ms-3 absolute right-2 top-1/2 -mt-2.5 z-10 pointer-events-none text-imperium-fg-muted"
            />
            <CloseIcon
              v-else-if="props.canBeCleared"
              class="cursor-pointer w-5 h-5 ms-3 absolute right-2 top-1/2 -mt-2.5 z-10 text-imperium-fg-muted"
              data-testid="select-reset"
              @click.stop="onResetSelect"
            />
          </template>

          <template #menu>
            <p
              v-if="props.isLoading"
              class="pt-2 px-3 pb-3 w-full flex items-center justify-center"
            >
              <LoaderIcon class="h-10 w-10" />
            </p>
            <ul
              v-if="displayableValues.length"
              class="max-h-48 h-fit pt-2 px-3 pb-3 overflow-y-auto text-gray-700 scrollbar-thin"
            >
              <li
                v-for="(value, index) in displayableValues"
                :key="value.id || `${props.name}${index}`"
                class="cursor-pointer"
                @click="() => onSelect(value)"
              >
                <div
                  class="flex items-center ps-2 rounded hover:bg-imperium-bg-4"
                  :class="{
                    ['bg-imperium-bg-3']: checkItemActive(value),
                  }"
                >
                  <slot
                    name="dropdownItem"
                    :item="value"
                  >
                    <label class="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
                      {{ value[props.labelKey] }}
                    </label>
                  </slot>
                </div>
              </li>
              <p
                v-if="props.menuAdditionalInfo"
                class="flex items-center pt-2 px-3 text-imperium-fg-subtle"
              >
                {{ props.menuAdditionalInfo }}
              </p>
            </ul>
            <p
              v-else-if="!displayableValues.length && !props.isLoading"
              class="pt-2 px-3 pb-3 overflow-y-auto dark:text-gray-300 scrollbar-thin text-sm font-medium text-gray-900"
            >
              No options
            </p>
          </template>

          <template
            v-if="props.isErrored && isError"
            #error
          >
            <div
              class="mt-1"
              :class="
                labelClass({
                  size: props.size,
                  isErrored: props.isErrored,
                })
              "
            >
              <slot name="error" />
            </div>
          </template>
        </Dropdown>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, useSlots, watch } from 'vue';

import ArrowDown from '@/assets/icons/arrow-down.svg?component';
import Dropdown from '@/components/Dropdown.vue';

import FormInput from './FormInput.vue';
import { INPUT_LABEL_POSITION, INPUT_ROUNDED, INPUT_TYPE, SIZES } from '@/types';
import { tv } from 'tailwind-variants';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import CloseIcon from '@/assets/icons/close.svg?component';

const search = ref<string>('');

export interface SelectItem {
  id: number | string;
  label: string;
  [name: string]: unknown;
  additionalInfo?: unknown;
}

const props = withDefaults(
  defineProps<{
    id?: string;
    name: string;
    modelValue: number | string | SelectItem | null;
    values?: SelectItem[];
    menuAdditionalInfo?: string;

    attrs?: Record<string, unknown>;
    placeholder?: string;

    isRequired?: boolean;
    isErrored?: boolean;
    isDisabled?: boolean;
    isSuccess?: boolean;
    isLoading?: boolean;
    isAsync?: boolean;
    canBeCleared?: boolean;

    size?: SIZES;
    rounded?: INPUT_ROUNDED;
    visualType?: INPUT_TYPE;

    selectedValue?: SelectItem;
    labelKey: string;
    labelPosition?: INPUT_LABEL_POSITION;
    labelWidth?: string;

    dropdownZIndex?: number;
  }>(),
  {
    placeholder: 'Not selected',
    values: [],

    isErrored: false,
    isRequired: false,
    isDisabled: false,
    isSuccess: false,
    isLoading: false,

    canBeCleared: false,

    size: SIZES.MEDIUM,
    rounded: INPUT_ROUNDED.DEFAULT,
    visualType: INPUT_TYPE.PRIMARY,
    labelKey: 'label',
    labelPosition: INPUT_LABEL_POSITION.TOP,
    labelWidth: 'auto',

    dropdownZIndex: 101,
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: number | string | SelectItem | null): void;
  (event: 'search', value: string): void;
}>();

const slots = useSlots();

const isDropdownItemSlot = computed(() => !!slots.dropdownItem);
const isDropdownVisible = ref<boolean>(false);

const dropdownElement = ref<typeof Dropdown | null>(null);
const searchInputElement = ref<HTMLElement | null>(null);

const isLabel = computed(() => !!slots.label);
const isLeftIcon = computed(() => !!slots.icon);
const isError = computed(() => !!slots.error);

const displayableValuesRef = ref();
const displayableValues = computed({
  get() {
    return displayableValuesRef.value ? displayableValuesRef.value : props.values;
  },
  set(newValues) {
    displayableValuesRef.value = newValues;
  },
});
const selectedValue = computed(() => {
  const result = props.selectedValue ?? props.values.find((element) => element.id === props.modelValue);

  // If modelValue equal object eg { id: number; name: string }
  // For example writer, editor
  if (!result && (props.modelValue as SelectItem)?.id && (props.modelValue as SelectItem)?.name) {
    return {
      ...(props.modelValue as SelectItem),
      label: (props.modelValue as SelectItem).name,
    };
  }

  return result;
});

const labelClass = tv({
  base: 'inline-block block input-meta-text-default',
  variants: {
    size: {
      [SIZES.SMALL]: 'input-meta-text-sm',
      [SIZES.MEDIUM]: 'input-meta-text-md',
    },
    isErrored: {
      true: 'input-meta-text-errored',
    },
    isSuccess: {
      true: 'input-meta-text-success',
    },
    isDisabled: {
      true: 'input-meta-text-disabled',
    },
    isInfo: {
      true: 'input-meta-helper-text',
    },
    labelPosition: {
      [INPUT_LABEL_POSITION.TOP]: 'mb-2',
      [INPUT_LABEL_POSITION.LEFT]: 'mt-2 text-right',
    },
  },
});

const inputClass = tv({
  base: 'block w-full text-ellipsis',
  variants: {
    size: {
      [SIZES.SMALL]: 'input-sm',
      [SIZES.MEDIUM]: 'input-md pt-[7.5px] pb-[7.5px]',
    },
    rounded: {
      [INPUT_ROUNDED.DEFAULT]: 'input-rounded-default',
      [INPUT_ROUNDED.FULL]: 'input-rounded-full',
    },
    visualType: {
      [INPUT_TYPE.PRIMARY]: 'input-primary',
      [INPUT_TYPE.PLAIN]: 'input-plain',
    },
  },

  compoundVariants: [
    {
      isDisabled: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-disabled',
    },
    {
      isDisabled: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-disabled',
    },
    {
      isErrored: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-errored',
    },
    {
      isSuccess: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-success',
    },
    {
      isErrored: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-errored',
    },
    {
      isSuccess: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-success',
    },
  ],
});

const checkItemActive = (value: SelectItem): boolean => {
  return props.modelValue === value.id;
};

const onSelect = (value: SelectItem): void => {
  search.value = value.label;
  displayableValues.value = props.values;
  emits('update:modelValue', props.isAsync ? value : value.id);
  dropdownElement.value?.close();
};

const onResetSelect = (): void => {
  search.value = '';
  emits('update:modelValue', null);
};

const onSearch = (value: string): void => {
  search.value = value;
  emits('search', search.value);
};

const onDropdownClose = () => {
  isDropdownVisible.value = false;
  search.value = '';
  emits('search', '');
};

watch(isDropdownVisible, (value: boolean) => {
  if (!value) {
    return;
  }

  nextTick(() => searchInputElement.value?.focus());
});

watch(
  () => props.values,
  (newValues: SelectItem[]) => {
    displayableValuesRef.value = newValues;
  },
  { immediate: true, deep: true },
);

watch(search, (currentSearch) => {
  if (!props.isAsync) {
    displayableValues.value = props.values.filter((element) => {
      return element[props.labelKey].toLowerCase().indexOf(currentSearch.toLowerCase()) !== -1;
    });
  }
});
</script>
