import { Plugin } from 'prosemirror-state';
import { DOMParser, Node } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { UploadImageService } from '@/features/UploadImage/service/uploadImage';
import ImagePlaceholder from '@/assets/icons/editor/image-placeholder.svg?raw';
import { useToast } from '@/composables';
const toast = useToast();

// Helper function to modify HTML and replace <img> tags with a loading spinner
function modifyHTML(html: string) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  tempDiv.querySelectorAll('img').forEach((img) => {
    const originalSrc = img.getAttribute('src');
    // Store the original src in the name attribute for later retrieval
    img.setAttribute('name', originalSrc || '');

    // Replace the src with a loading spinner
    const loadingPlaceholder = `data:image/svg+xml;base64,${btoa(ImagePlaceholder)}`;
    img.setAttribute('src', loadingPlaceholder);

    // Unwrap the <img> tag from its parent elements
    let parent = img.parentElement;
    while (parent && parent !== tempDiv && parent.tagName !== 'B') {
      const nextParent = parent.parentElement;
      parent.replaceWith(img);
      parent = nextParent;
    }
  });

  return tempDiv.innerHTML;
}

export const googleDocContentPlugin = new Plugin({
  props: {
    handlePaste(view, event, _slice) {
      const clipboardData = event.clipboardData;
      const html = clipboardData?.getData('text/html');

      // If no HTML (plain text paste), allow default paste behavior
      if (!html) {
        return false;
      }

      const modifiedHTML = modifyHTML(html);
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = modifiedHTML;

      // If no images exist, allow default paste behavior
      const images = Array.from(tempDiv.querySelectorAll('img'));
      if (images.length === 0) {
        return false; // Let ProseMirror handle normal text pasting
      }

      // Parse and insert content immediately (without waiting)
      const parser = DOMParser.fromSchema(view.state.schema);
      const doc = parser.parse(tempDiv);

      if (doc) {
        const tr = view.state.tr.replaceSelectionWith(doc);
        view.dispatch(tr);

        // Upload images asynchronously (so paste does not block)
        setTimeout(() => uploadImages(view, images), 0);

        return true; // Prevent default paste behavior since we inserted custom content
      }

      return false; // Allow default paste if parsing fails
    },
  },
});

async function uploadImages(view: EditorView, images: HTMLImageElement[]) {
  for (const img of images) {
    const originalSrc = img.getAttribute('name');
    const alt = img.getAttribute('alt');

    if (!originalSrc) continue;

    try {
      const result = await UploadImageService.imageGalleryUpload({ url: originalSrc });
      const uploadedUrl = result.url;

      let found = false;
      view.state.doc.descendants((node: Node, pos: number) => {
        if (!found && node.type.name === 'img' && node.attrs.name === originalSrc) {
          const schema = view.state.schema;
          const imageNode = schema.nodes.img.create({ src: uploadedUrl, alt });
          const figcaptionText = schema.text('Edit the caption here or remove the text', [schema.marks.em.create()]);
          const figcaptionParagraph = schema.nodes.p.create(null, figcaptionText);
          const figcaptionNode = schema.nodes.figcaption.create(null, figcaptionParagraph);
          const figureNode = schema.nodes.figure.create(null, [imageNode, figcaptionNode]);

          const newTr = view.state.tr.replaceWith(pos, pos + node.nodeSize, figureNode);
          view.dispatch(newTr);
          found = true;
        }
      });
    } catch (error) {
      console.error('Failed to upload image:', error);
      toast.errorTemporary({
        id: 'PASTE_IMAGE_PROSEMIRROR_MESSAGE_ERROR',
        message: error.data?.errorMessage || 'Image upload failed',
      });

      // Remove the placeholder image
      let found = false;
      view.state.doc.descendants((node: Node, pos: number) => {
        if (!found && node.type.name === 'img' && node.attrs.name === originalSrc) {
          const newTr = view.state.tr.delete(pos, pos + node.nodeSize);
          view.dispatch(newTr);
          found = true;
        }
      });
    }
  }
}
