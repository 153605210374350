import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M6.5 3a.5.5 0 0 0-.5.5v8.616c0 .817 0 1.375.096 1.86a5 5 0 0 0 3.928 3.928c1.195.238 2.76.237 3.952 0a5 5 0 0 0 3.928-3.928c.096-.485.096-1.043.096-1.86V3.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V12c0 .977-.005 1.32-.058 1.585a3 3 0 0 1-2.357 2.357c-.949.19-2.221.19-3.17 0a3 3 0 0 1-2.357-2.357C8.005 13.32 8 12.977 8 12V3.5a.5.5 0 0 0-.5-.5z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#000",
      d: "M5.5 20a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"
    }, null, -1)
  ])))
}
export default { render: render }